import React, { Component } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import "../CSS/3d.css";
import axios from "axios";
import Swal from "sweetalert2";

import {
  FloatingLabel,
  Stack,
  Button,
  Table,
  Modal,
  Form,
  Row,
  Col,
} from "react-bootstrap";

const WAIT_INTERVAL = 500;

export default class CheckoutTO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      registeredUserAccount: "",
      guestUserAccount: "",
      firstName: "",
      lastName: "",
      emailId: "",
      mobileNo: "",
      address: "",
      landmark: "",
      country: "",
      state: "",
      city: "",
      pinCode: "",
      paymentmode: "",
      formValidationErrorMsg: "",
      validatedAndSubmitted: "false",
      errormsg: "",
      loadSpinnerOpen: false,
      hideDifferentAddress: "true",
      deliveryAddressState: "current", //current or different
      showPayByCashModal: false,
      showPayByQrCodeModal: false,
      showPayByCardSwipingMachineModal: false,
      showGenerteBillModal: false,
      saleCancelled: false,
      billGenerated: false,
      loadSpinnerOpen: false,
    };
  }
  static PaymentMode_CashOnDelivery = 1;
  static PaymentMode_RazorPay = 2;
  static OrderStatusRecived = 1;

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "firstName":
        this.setState({ firstName: value });
        break;
      case "mobileNo":
        if (value.toString().length <= 10) {
          this.setState({ mobileNo: value });
        }
        break;
      case "emailId":
        this.setState({ emailId: value });
        break;
    }
  };
  CallRazorPay = () => {
    var ordid = "Order_" + new Date().getTime();
    var custName = "";
    var emailId = "";
    var mobileNo = "";

    var options = {
      key: this.context.storeSettings.razorpayKey,
      key_secret: this.context.storeSettings.razorpayKeySecret,
      // amount: 1 * 100,
      amount:
        Math.round(
          this.context.getTotalAmountWithTaxTable(
            this.props.myCart,
            this.props.coupon.couponValue
          ),
          0
        ) * 100,
      currency: this.context.storeSettings.razorpayCurrency,
      name: this.context.storeSettings.razorpayCompanyName,
      description: this.context.storeSettings.razorpayCompanyDescription,
      orderid: ordid,
      handler: (response) => {
        this.SuccessRazorPayResponse(response);
      },
      prefill: {
        name: custName, //customer name
        email: emailId, //customer emailId
        contact: mobileNo, //customer phoneno
      },
      notes: {
        address: this.context.storeSettings.razorpayCompanyAddress,
      },
      theme: {
        color: this.context.storeSettings.razorpayThemeColor,
      },
      modal: {
        escape: false,
        ondismiss: () => {
          //this.setState({ OrderStatus: "Dismissed" });
          // this.context.UpdateTableOrderStatus("Dismissed");
        },
      },
    };
    var pay = window.Razorpay(options);
    //var rzp1 = new Razorpay(options);
    //pay.on("payment.failed", function (response) {
    pay.on("payment.failed", (response) => {
      this.FailedInRazorPayResponse(response);
    });
    pay.open();
  };
  FailedInRazorPayResponse = (response) => {
    //TODO: store the response details in Database
    // this.context.UpdateTableOrderStatus("Failed");
    //this.setState({ OrderStatus: "Failed" });
    // alert(response.error.code);
    // alert(response.error.description);
    // alert(response.error.source);
    // alert(response.error.step);
    // alert(response.error.reason);
    // alert(response.error.metadata.order_id);
    // alert(response.error.metadata.payment_id);
  };
  SuccessRazorPayResponse = (response) => {
    this.UpdateInvoicePaid();
    //1. Store Order Details in DB
    // this.StoreOrderInDB(response.razorpay_payment_id);
    //2. Clear Local Cart
    console.log(response.razorpay_payment_id);
  };
  PerformCashOnDelivery = () => {
    //TODO: store guest user details in database.
    this.StoreOrderInDB("");
  };

  GetPosInvoiceHtml = (orderData) => {
    var res = axios
      .post(
        process.env.REACT_APP_API + "Consumers/GetPosInvoiceHtml/",
        orderData
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          //this.setState({ invoiceHtml: response.data });
          this.PrintInvoice(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("GetInvoiceHtml - There was an error! ", error);
      });
  };

  PrintInvoice = (invoiceHtml) => {
    // var printWindow = window.open("", "", "height=500,width=1000");
    var printWindow = window.open("", "_blank");
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };

  UpdateTableInvoiceNumber = (order) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateTableInvoiceNumber/" +
          this.context.selectedTable.id +
          "/" +
          order.orderId
      )
      .then((response) => {
        if (response.status === 200) {
          //this.GetRestaturantTableOrderByTableId(this.state.selectedTableId);
        } else {
          //alert("UpdateRestaurantTableOrderItem Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };
  ClearInvoiceNumberOnTable = () => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/ClearInvoiceNumberOnTable/" +
          this.context.selectedTable.id
      )
      .then((response) => {
        if (response.status === 200) {
        } else {
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };

  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };

  StoreOrderInDB = (paymentTransactionId) => {
    this.ShowLoadSpinner();
    var userid = "";
    var paymentmodeid = 0; //cashondelivery
    var paymentstatusid = 0;
    var orderstatusid = 0;
    var description = "";
    var orderWorkflowStatus =
      "<p>" +
      this.context.GetInDateFormat(new Date()) +
      "=> " +
      "POS-PaidAndClosed" +
      "</p>";
    var totalAmtPaid = 0;
    var customerOrderedItems = [];
    var customerOrderDeliveryAddresses = [
      {
        firstName: this.state.firstName,
        lastName: "",
        mobileNo: this.state.mobileNo,
        emailId: this.state.emailId,
        Address: "",
        Landmark: "",
        city: "",
        state: "",
        country: "",
        pinCode: "",
      },
    ];

    this.props.myCart.map((cartItem) => {
      var OrderedItem = {
        productId: "1",
        productName: "",
        imgFileName: "",
        price: 0,
        qty: "",
        description: "",
      };
      if (cartItem.productId.includes("Custom") == false)
        OrderedItem.productId = cartItem.productId;
      else OrderedItem.productId = undefined;

      OrderedItem.productName = cartItem.productName;
      // OrderedItem.categoryName = this.getMainCategoryById(
      //   cartItem.prod.mainCategoryId
      // );
      OrderedItem.imgFileName = cartItem.fileName;
      OrderedItem.mrp = cartItem.mrp;
      OrderedItem.discount = cartItem.discount;
      OrderedItem.qty = cartItem.qty;
      OrderedItem.description = "";
      OrderedItem.orderStatusId = 4;

      var result = customerOrderedItems.filter(
        (f) =>
          f.productId == OrderedItem.productId &&
          f.productName == OrderedItem.productName
      );
      if (result.length > 0) {
        result[0].qty += OrderedItem.qty;
      } else {
        customerOrderedItems.slice();
        customerOrderedItems.push(OrderedItem);
      }
    });

    if (this.state.paymentmode === 2) {
      paymentmodeid = 2; //RazorPay
      paymentstatusid = 1; //paid
      orderstatusid = 11; //Closed
      totalAmtPaid = this.context.getTotalAmountWithTaxTable(
        this.props.myCart,
        this.props.coupon.couponValue
      );
    } else if (this.state.paymentmode === 3) {
      paymentmodeid = 3; //Cash
      paymentstatusid = 1; //paid
      orderstatusid = 11; //Closed
      totalAmtPaid = this.context.getTotalAmountWithTaxTable(
        this.props.myCart,
        this.props.coupon.couponValue
      );
    } else if (this.state.paymentmode === 4) {
      paymentmodeid = 4; //QRCode
      paymentstatusid = 1; //paid
      orderstatusid = 11; //Closed
      totalAmtPaid = this.context.getTotalAmountWithTaxTable(
        this.props.myCart,
        this.props.coupon.couponValue
      );
    } else if (this.state.paymentmode === 5) {
      paymentmodeid = 5; //SwipeMachine
      paymentstatusid = 1; //paid
      orderstatusid = 11; //Closed
      totalAmtPaid = this.context.getTotalAmountWithTaxTable(
        this.props.myCart,
        this.props.coupon.couponValue
      );
    } else if (this.state.paymentmode === 6) {
      //pay with
      paymentmodeid = 6; //pay with invoice
      paymentstatusid = 2; //unpaid
      orderstatusid = 8; //deliverybutunpaid
    }

    var str = {
      storeId: this.context.storeId,
      customerId: this.context.userData.id,
      paymentModeId: paymentmodeid,
      paymentStatusId: paymentstatusid,
      deliveryMethodId: 1,
      paymentTransactionId: paymentTransactionId,
      orderStatus: orderstatusid,
      description: "",
      tableName:
        this.context.selectedTable.name +
        " (" +
        this.context.selectedTable.waiterName +
        ")",
      orderWorkflowStatus: orderWorkflowStatus,
      subTotalAmt: this.context.getSubTotalPriceInCartTable(this.props.myCart),
      // deliveryAmt: this.context.getDeliveryAmountTable(this.props.myCart),
      deliveryAmt: 0,
      totalAmt: this.context.getTotalAmountWithTaxTable(
        this.props.myCart,
        this.props.coupon.couponValue
      ),
      totalAmtPaid: totalAmtPaid,
      // tableName: this.props.tableName,

      couponCode: this.props.coupon.couponCode,
      couponValue: this.props.coupon.couponValue,
      totalSavings: this.context.getTotalSavingsFromCartTable(
        this.props.myCart
      ),

      taxPercentage:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.taxDetails.taxPercentage,
      taxAmount:
        this.context.taxDetails.inclusiveOfTax == true
          ? 0
          : this.context.getTaxAmountTable(
              this.props.myCart,
              this.props.coupon.couponValue
            ),

      // OrderCreated: new Date().toLocaleString(),  MOBILE Device throws 400 badrequest exception
      // OrderModified: new Date().toLocaleString(), MOBILE Device throws 400 badrequest exception
      customerOrderedItems: customerOrderedItems,
      customerOrderDeliveryAddresses: customerOrderDeliveryAddresses,
    };

    console.log(process.env.REACT_APP_API);
    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitOrder", str)
      .then((response) => {
        if (response.status === 200) {
          this.UpdateTableInvoiceNumber(response.data);
          this.props.StoreGeneratedInvoice(response.data);
          this.context.storeTableOrderInformation(response.data);
          this.SendInvoiceEmail(response.data);
          // this.context.UpdateTableOrderStatus("Success");
          this.GetPosInvoiceHtml(response.data);
          this.HidePayByCashModal();
          this.HidePayByQrcodeModal();
          this.HideLoadSpinner();
        } else {
          alert("Order Submit Failed. Please try again!");
          this.context.UpdateTableOrderStatus("Failed");
          this.HideLoadSpinner();
        }
      })
      .catch((error) => {
        console.error("StoreOrderInDB:There was an error!", error);
        this.context.UpdateTableOrderStatus("Failed");
        this.HideLoadSpinner();
      });
  };

  SendInvoiceEmail = (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(this.context.orderData),
      body: JSON.stringify(data),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/SendInvoiceEmail/" + "POS",
      requestOptions
    )
      .then((response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && response.json();

        if (response.status === 200) {
          return;
        } else {
          throw new Error(response);
        }
      })
      .catch((error) => {
        console.error("SendInvoiceEmail:There was an error!", error);
      });
  };
  ProceedCompleteOrder = () => {
    this.setState({ validatedAndSubmitted: "true" }, this.ProceedToPay());
  };
  ProceedToPay = () => {
    if (this.state.paymentmode === "RazorPay") {
      this.CallRazorPay();
    }
  };
  StoreOrderInformationInContext = () => {
    this.context.storeOrderInformation({
      name: this.state.firstName,
      mobileNo: this.state.mobileNo,
      emailId: this.state.emailId,
      address: this.state.address,
      country: this.state.country,
      state: this.state.state,
      city: this.state.city,
      pinCode: this.state.pinCode,
      paymentMode: this.state.paymentmode,
    });
  };

  CheckOrderedAreDelivered = () => {
    var items = this.props.myCart.filter((item) => item.orderStatusId != 4);
    if (items.length > 0) {
      Swal.fire({
        title:
          "Not all items are in 'Delivered' status! Do u want really want proceed ? ",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.scrollTo(0, 0);
        }
      });
      return false;
    }
    return true;
  };

  handlePayByCash = (event) => {
    // var items = this.props.myCart.filter((item) => item.orderStatusId != 4);
    // if (items.length > 0) {
    //   Swal.fire({
    //     title:
    //       "Not all items are in 'Delivered' status! Do you still want to proceed ? ",
    //     showDenyButton: true,
    //     confirmButtonText: "yes",
    //   }).then((result) => {
    //     if (result.isConfirmed) {
    //       window.scrollTo(0, 0);
    //       this.ShowPayByCashModal();
    //     }
    //   });
    // }

    // if (this.CheckOrderedAreDelivered() == true) {
    //   this.ShowPayByCashModal();
    // }
    this.ShowPayByCashModal();
  };

  handlePayViaQrcode = (event) => {
    //if (this.CheckOrderedAreDelivered())
    this.ShowPayByQrCodeModal();
  };

  handleRazorPaySubmit = (event) => {
    if (this.CheckOrderedAreDelivered() == false) return;
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      this.setState({ paymentmode: 2 }, () => {
        this.CallRazorPay();
      });
    }
    this.setState({ validated: true });
  };

  OnRazorPay = () => {
    if (this.CheckOrderedAreDelivered() == false) return;
    this.setState({ paymentmode: 2 }, () => {
      this.CallRazorPay();
    });
  };

  updateCustomerDetails = (customerDetail) => {
    if (customerDetail != undefined) {
      this.setState({ firstName: customerDetail.firstName });
      this.setState({ mobileNo: customerDetail.mobileNo });
      this.setState({ emailId: customerDetail.emailId });
      this.setState({ address: customerDetail.address });
    }
  };

  ShowGenerateBillModal = () => {
    if (this.CheckOrderedAreDelivered() == true) {
      this.setState({ showGenerteBillModal: true });
    }
  };
  HideGenerateBillModal = () => {
    this.setState({ showGenerteBillModal: false });
  };

  OnGenerateBillAndPrint = (customerDetail) => {
    this.updateCustomerDetails(customerDetail);
    // if (this.CheckOrderedAreDelivered() == true) {
    //   this.HideGenerateBillModal();
    //   this.setState({ billGenerated: true });
    //   this.setState({ paymentmode: 6 }, () => {
    //     this.StoreOrderInDB("");
    //   });
    // }
    this.HideGenerateBillModal();
    this.setState({ billGenerated: true });
    this.setState({ paymentmode: 6 }, () => {
      this.StoreOrderInDB("");
    });
  };

  UpdateInvoicePaid = () => {
    //1. UpdateTableInvoiceNumber to null
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateOrderAsPaid/" +
          this.context.storeId +
          "/" +
          this.props.generatedInvoiceOrder.id
      )
      .then((response) => {
        if (response.status === 200) {
          this.context.DeleteRestaurantTableOrder(
            this.context.selectedTable.id
          );
          this.ClearInvoiceNumberOnTable();
          Swal.fire({
            title: "Paid. Sale Done!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.props.SaleDone();
            }
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
    //2. Update
  };
  OnCashPaid = () => {
    this.setState({ paymentmode: 3 }, () => {
      //this.StoreOrderInDB("");
      this.HidePayByCashModal();
      this.UpdateInvoicePaid();
    });
  };
  OnQrcodePaid = () => {
    this.setState({ paymentmode: 4 }, () => {
      this.UpdateInvoicePaid();
      this.HidePayByQrcodeModal();
    });
  };
  OnCardSwipingMachinePaid = () => {
    this.setState({ paymentmode: 5 }, () => {
      this.UpdateInvoicePaid();
      this.HidePayByCardSwipingMachineModal();
    });
  };

  ShowPayByCashModal = () => {
    this.setState({ showPayByCashModal: true });
  };
  HidePayByCashModal = () => {
    this.setState({ showPayByCashModal: false });
  };
  ShowPayByQrCodeModal = () => {
    this.setState({ showPayByQrCodeModal: true });
  };
  HidePayByQrcodeModal = () => {
    this.setState({ showPayByQrCodeModal: false });
  };
  ShowPayCardSwipingMachineModal = () => {
    //if (this.CheckOrderedAreDelivered() == false) return;
    this.setState({ showPayByCardSwipingMachineModal: true });
  };
  HidePayByCardSwipingMachineModal = () => {
    this.setState({ showPayByCardSwipingMachineModal: false });
  };
  OnCancelSale = () => {
    Swal.fire({
      title: "Are you sure wanted to Cancel the Sale?",
      showDenyButton: true,
      confirmButtonText: "yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ClearInvoiceNumberOnTable();
        this.context.DeleteRestaurantTableOrder(this.context.selectedTable.id);
        // this.setState({ saleCancelled: true });
        this.props.updateCartView();
        window.scrollTo(0, 0);
      }
    });
  };

  componentDidMount() {
    if (this.props.generatedInvoiceOrder != "") {
      this.setState({ billGenerated: true });
    }
    window.scrollTo(0, 0);
  }

  render() {
    if (this.context.tableOrderStatus !== "") {
      return <Navigate to="/CompleteOrderViewTable" />;
    }

    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <>
            <LoadSpinner open="true"></LoadSpinner>
          </>
        ) : (
          <></>
        )}
        {this.state.showGenerteBillModal == true ? (
          <>
            <GenerateBillModal
              onHide={this.HideGenerateBillModal}
              show={this.state.showGenerteBillModal}
              OnGenerateBillAndPrint={this.OnGenerateBillAndPrint}
            ></GenerateBillModal>
          </>
        ) : (
          <></>
        )}

        {this.state.showPayByCashModal == true ? (
          <PaymentTypeModalTO
            OnCashPaid={this.OnCashPaid}
            onHide={this.HidePayByCashModal}
            myCart={this.props.myCart}
            coupon={this.props.coupon}
            show={this.state.showPayByCashModal}
            paymentType="CASH"
          ></PaymentTypeModalTO>
        ) : (
          ""
        )}
        {this.state.showPayByQrCodeModal == true ? (
          <PaymentTypeModalTO
            OnQrcodePaid={this.OnQrcodePaid}
            onHide={this.HidePayByQrcodeModal}
            myCart={this.props.myCart}
            coupon={this.props.coupon}
            show={this.state.showPayByQrCodeModal}
            paymentType="QRCODE"
          ></PaymentTypeModalTO>
        ) : (
          ""
        )}
        {this.state.showPayByCardSwipingMachineModal == true ? (
          <PaymentTypeModalTO
            OnCardSwipingMachineModalPaid={this.OnCardSwipingMachinePaid}
            onHide={this.HidePayByCardSwipingMachineModal}
            myCart={this.props.myCart}
            coupon={this.props.coupon}
            show={this.state.showPayByCardSwipingMachineModal}
            paymentType="CARD"
          ></PaymentTypeModalTO>
        ) : (
          ""
        )}
        <div className="border-0 w-100 m-0 p-0">
          {window.innerWidth > 1100 ? (
            <>
              <div className="w-100 m-0 p-0 border-0">
                <div
                  className="w-100 m-0 p-0 border-0"
                  style={{ backgroundColor: this.context.themeBorderColor }}
                >
                  <Row className=" m-0 p-0 ">
                    <Col className="m-0 p-0 border-0 alignverticalmiddle">
                      <table className=" m-0 p-0 w-100">
                        <tr className="mb-3">
                          {this.context.tableOrderStatus == "Success" ? (
                            ""
                          ) : (
                            <>
                              {this.context.tableOrderStatus != "Success" &&
                              this.context.getTotalQtyInCartTable(
                                this.props.myCart
                              ) > 0 ? (
                                <>
                                  {this.state.billGenerated == false ? (
                                    <>
                                      <td className="text-center ">
                                        <Button
                                          variant="primary"
                                          className="text-dark w-100 cardimage-round btn3d-green"
                                          style={{ height: 50 }}
                                          onClick={(e) => {
                                            var items =
                                              this.props.myCart.filter(
                                                (item) =>
                                                  item.orderStatusId != 4
                                              );
                                            if (items.length > 0) {
                                              Swal.fire({
                                                title:
                                                  "All Item's are not 'Delivered'! Do u really want to Generate Bill ? ",
                                                showDenyButton: true,
                                                confirmButtonText: "yes",
                                              }).then((result) => {
                                                if (result.isConfirmed) {
                                                  window.scrollTo(0, 0);
                                                  this.setState({
                                                    showGenerteBillModal: true,
                                                  });
                                                }
                                              });
                                            } else {
                                              this.ShowGenerateBillModal();
                                            }
                                          }}
                                        >
                                          <h5>
                                            <b>Generate Bill & Print </b>
                                          </h5>
                                        </Button>
                                      </td>
                                    </>
                                  ) : (
                                    <>
                                      <td className="text-center">
                                        <Stack direction="horizontal">
                                          <Button
                                            className=" w-100  border-0  text-white"
                                            style={{
                                              height: 50,
                                              backgroundColor: "inherit",
                                            }}
                                            onClick={(e) =>
                                              this.GetPosInvoiceHtml(
                                                this.props.generatedInvoiceOrder
                                              )
                                            }
                                          >
                                            <span className="blink_me">
                                              Generated Invoice No.
                                              {
                                                this.props.generatedInvoiceOrder
                                                  .orderId
                                              }
                                              &nbsp;
                                            </span>
                                            <u>Print!</u>
                                          </Button>
                                        </Stack>
                                      </td>
                                    </>
                                  )}

                                  {this.state.billGenerated == true ? (
                                    <>
                                      <td width="60%">
                                        <Stack direction="horizontal">
                                          <Button
                                            variant="primary"
                                            className="text-dark w-100 cardimage-round btn3d-green"
                                            style={{ height: 50 }}
                                            onClick={this.handlePayByCash}
                                          >
                                            <i class="bi bi-cash"></i>&nbsp;
                                            <b>CASH</b>
                                          </Button>

                                          <Button
                                            variant="primary"
                                            className=" text-dark  w-100 cardimage-round btn3d-green"
                                            style={{ height: 50 }}
                                            onClick={this.handlePayViaQrcode}
                                          >
                                            <i class="bi bi-qr-code"></i>&nbsp;
                                            <b>QR</b>
                                          </Button>

                                          <Button
                                            variant="primary"
                                            className="text-dark w-100 cardimage-round btn3d-green"
                                            style={{ height: 50 }}
                                            onClick={
                                              this
                                                .ShowPayCardSwipingMachineModal
                                            }
                                          >
                                            <i class="bi bi-credit-card"></i>
                                            &nbsp;
                                            <b>Card</b>
                                          </Button>
                                        </Stack>
                                      </td>
                                      {/* <Button
                                      variant="primary"
                                      className=" w-100 cardimage-round btn3d-primary"
                                    
                                      style={{ height: 100 }}
                                      onClick={this.OnRazorPay}
                                    >
                                      <h2>
                                        <i class="bi bi-card-list"></i>
                                      </h2>
                                      <h5>
                                        <b>RAZORPAY</b>
                                      </h5>
                                    </Button> */}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                          <td className="text-end ">
                            <Button
                              className="text-white btn3d-danger"
                              type="submit"
                              size="sm"
                              onClick={this.OnCancelSale}
                            >
                              Cancel Sale
                            </Button>
                          </td>
                        </tr>
                      </table>
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          ) : (
            <>
              {this.context.tableOrderStatus == "Success" ? (
                ""
              ) : (
                <>
                  {this.context.tableOrderStatus != "Success" &&
                  this.context.getTotalQtyInCartTable(this.props.myCart) > 0 ? (
                    <>
                      {this.state.billGenerated == false ? (
                        <>
                          <Row className="w-100">
                            <Col xs={10}>
                              <Button
                                variant="primary"
                                className="  text-white w-100 cardimage-round btn3d-primary"
                                onClick={(e) => {
                                  var items = this.props.myCart.filter(
                                    (item) => item.orderStatusId != 4
                                  );
                                  if (items.length > 0) {
                                    Swal.fire({
                                      title:
                                        "All Item's are not 'Delivered'! Do u really want to Generate Bill ? ",
                                      showDenyButton: true,
                                      confirmButtonText: "yes",
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        window.scrollTo(0, 0);
                                        this.setState({
                                          showGenerteBillModal: true,
                                        });
                                      }
                                    });
                                  } else {
                                    this.ShowGenerateBillModal();
                                  }
                                }}
                              >
                                <h5>
                                  <b>Generate Bill & Print </b>
                                </h5>
                              </Button>
                            </Col>
                            <Col xs={2}>
                              <Button
                                className="text-white btn3d-danger"
                                type="submit"
                                size="sm"
                                onClick={this.OnCancelSale}
                              >
                                Cancel
                              </Button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Table className="p-0 m-0 border-0">
                            <tbody className="p-0 m-0 border-0">
                              <tr className="p-0 m-0 alignverticalmiddle border-0">
                                <td className="text-white blink_me border-0">
                                  Generated Invoice No:{" "}
                                  {this.props.generatedInvoiceOrder.orderId}
                                </td>
                                <td className="col-md-2 text-end text-success p-0 m-0">
                                  <Button
                                    className=" border-0  text-white"
                                    style={{
                                      backgroundColor: "inherit",
                                    }}
                                    onClick={(e) =>
                                      this.GetPosInvoiceHtml(
                                        this.props.generatedInvoiceOrder
                                      )
                                    }
                                  >
                                    <u>Print again!</u>
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </>
                      )}
                      {this.state.billGenerated == true ? (
                        <Table className="w-100">
                          <tbody>
                            <tr>
                              <td className="text-center  p-0 m-0 border-0">
                                <Button
                                  variant="primary"
                                  className="  text-white w-100 cardimage-round btn3d-primary"
                                  onClick={this.handlePayByCash}
                                >
                                  {/* <h2>
                                    <i class="bi bi-cash"></i>
                                  </h2> */}

                                  <b>CASH</b>
                                </Button>
                              </td>
                              <td className="text-center  p-0 m-0 border-0">
                                <Button
                                  variant="primary"
                                  className=" text-white  w-100 cardimage-round btn3d-primary"
                                  onClick={this.handlePayViaQrcode}
                                >
                                  {/* <h2>
                                    <i class="bi bi-qr-code"></i>
                                  </h2> */}

                                  <b>QrCode</b>
                                </Button>
                              </td>
                              <td className="text-center  p-0 m-0 border-0">
                                <Button
                                  variant="primary"
                                  className="  text-white w-100 cardimage-round btn3d-primary"
                                  onClick={this.ShowPayCardSwipingMachineModal}
                                >
                                  {/* <h2>
                                    <i class="bi bi-credit-card"></i>
                                  </h2> */}
                                  <b>Card</b>
                                </Button>
                              </td>
                              {/* <td
                                className="text-center p-1"
                                colSpan={2}
                                width="50%"
                              >
                                <Button
                                  variant="primary"
                                  className=" w-100 cardimage-round btn3d-primary"
                                  type="submit"
                                  onClick={this.handleRazorPaySubmit}
                                >
                                  <h2>
                                    <i class="bi bi-card-list"></i>
                                  </h2>
                                  <h6>
                                    <b>RAZORPAY</b>
                                  </h6>
                                </Button>
                              </td> */}
                              <td className="text-end  p-0 m-0 border-0">
                                <Button
                                  className="btn3d-danger"
                                  type="submit"
                                  onClick={this.OnCancelSale}
                                >
                                  Cancel
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export class PaymentTypeModalTO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      customerDetail: {
        firstName: "",
        emailId: "",
        mobileNo: "",
        address: "",
      },
      amountReceived: "",
      amountToReturn: "",
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "amountReceived":
        this.setState({ amountReceived: value });
        this.setState({
          amountToReturn:
            this.context.getTotalAmountWithTaxTable(
              this.props.myCart,
              this.props.coupon.couponValue
            ) - value,
        });
        break;
      case "firstName":
        this.state.customerDetail.firstName = value;
        break;
      case "mobileNo":
        this.state.customerDetail.mobileNo = value;
        break;
      case "emailId":
        this.state.customerDetail.emailId = value;
        break;
      case "address":
        this.state.customerDetail.address = value;
        break;
    }
    this.setState({ customerDetail: this.state.customerDetail });
  };

  ValidateCustomerDetails = () => {
    if (
      this.state.customerDetail.firstName == "" ||
      this.state.customerDetail.mobileNo == ""
    ) {
      Swal.fire({
        title: "warning!",
        text: "Customer Name and Mobile Number are mandatory!",
        // confirmButtonText: "yes",
        timer: 1500,
      }).then((result) => {});
      return false;
    }
    return true;
  };

  ProcessPayment = () => {
    if (this.props.paymentType == "COD") {
      if (this.ValidateCustomerDetails() == true)
        this.props.OnCodPaid(this.state.customerDetail);
    }
    if (this.props.paymentType == "CASH")
      this.props.OnCashPaid(this.state.customerDetail);
    if (this.props.paymentType == "QRCODE")
      this.props.OnQrcodePaid(this.state.customerDetail);
    if (this.props.paymentType == "CARD")
      this.props.OnCardSwipingMachineModalPaid(this.state.customerDetail);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.ProcessPayment();
  };

  _handleNVEvent = (event) => {
    if (event.key == "Enter") {
      event.stopPropagation();
      this.ProcessPayment();
    }
  };
  AmtToReturn = () => {
    if (this.state.amountReceived == 0) return 0;
    var res = Math.round(this.state.amountToReturn * -1, 0);
    return res;
  };

  componentDidMount = () => {
    document.addEventListener("keydown", this._handleNVEvent, false);
    var ctrl = document.getElementById("amountReceived");
    if (ctrl) ctrl.focus();
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="payByCash"
        >
          <Modal.Header closeButton className="bg-warning">
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.paymentType == "COD" ? <>COD</> : <></>}
              {this.props.paymentType == "CASH" ? <>Pay by Cash</> : <></>}
              {this.props.paymentType == "QRCODE" ? <>Pay by QRCODE</> : <></>}
              {this.props.paymentType == "CARD" ? <>Pay by CARD</> : <></>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            {this.props.paymentType == "CASH" ? (
              <>
                <div className="border p-3 bg-light">
                  <tr>
                    <td>
                      <h4>Bill Amount:</h4>
                    </td>
                    <td>
                      <Form.Label>
                        <h4>
                          &nbsp;
                          {Math.round(
                            this.context.getTotalAmountWithTaxTable(
                              this.props.myCart,
                              this.props.coupon.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </h4>
                      </Form.Label>
                    </td>
                    >
                  </tr>
                  <tr>
                    <td>
                      <h4>Received:</h4>
                    </td>
                    <td>
                      <Form.Control
                        type="number"
                        size="lg"
                        id="amountReceived"
                        name="amountReceived"
                        value={this.state.amountReceived}
                        placeholder=""
                        autoComplete="new-password"
                        keyboard={true}
                        onChange={(e) => this.handleInputChange(e)}
                        style={{ fontSize: "26px" }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h4>Amount to Return:</h4>
                    </td>
                    <td>
                      <Form.Label className="verticalaligncentertd">
                        <h4>
                          &nbsp;
                          {this.AmtToReturn().toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </h4>
                      </Form.Label>
                    </td>
                  </tr>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.paymentType == "QRCODE" ? (
              <>
                <div className="border p-3 bg-light">
                  <table className="w-100">
                    <tr className="text-center align-middle">
                      <td className="col-md-12 text-center">
                        <h4>
                          <b>
                            Bill Total Amount: &nbsp;
                            {Math.round(
                              this.context.getTotalAmountWithTaxTable(
                                this.props.myCart,
                                this.props.coupon.couponValue
                              ),
                              0
                            )}
                          </b>
                        </h4>
                      </td>
                    </tr>
                    <tr className="text-center align-middle">
                      <td className="col-md-12">
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/qrcode.png" +
                            this.context.store.storageSasToken
                          }
                          className=" mx-auto"
                          alt="..."
                          style={{ width: "5cm" }}
                        />
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
            {this.props.paymentType == "CARD" ? (
              <>
                <div className="border p-3 bg-light">
                  <table className="w-100">
                    <tr className="text-center align-middle">
                      <td className="col-md-12 text-center">
                        <h4>
                          <b>
                            Bill Total Amount: &nbsp;
                            {Math.round(
                              this.context.getTotalAmountWithTaxTable(
                                this.props.myCart,
                                this.props.coupon.couponValue
                              ),
                              0
                            )}
                          </b>
                        </h4>
                      </td>
                    </tr>
                  </table>
                </div>
              </>
            ) : (
              <></>
            )}
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <Button variant="primary" size="lg" onClick={this.handleSubmit}>
              {this.props.paymentType == "COD" ? <>Ok</> : <>Paid</>}
            </Button>

            <Button variant="danger" size="lg" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export class GenerateBillModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      customerDetail: {
        firstName: "",
        emailId: "",
        mobileNo: "",
        address: "",
      },
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "firstName":
        this.state.customerDetail.firstName = value;
        break;
      case "mobileNo":
        if (isNaN(Number(value)) == false)
          this.state.customerDetail.mobileNo = value;
        break;
      case "emailId":
        this.state.customerDetail.emailId = value;
        break;
      case "address":
        this.state.customerDetail.address = value;
        break;
    }
    this.setState({ customerDetail: this.state.customerDetail });
  };

  ValidateCustomerDetails = () => {
    if (
      this.state.customerDetail.firstName == "" ||
      this.state.customerDetail.mobileNo == ""
    ) {
      Swal.fire({
        title: "warning!",
        text: "Customer Name and Mobile Number are mandatory!",
        timer: 1500,
      }).then((result) => {});
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.OnGenerateBillAndPrint(this.state.customerDetail);
  };

  componentDidMount = () => {
    // var ctrl = document.getElementById("amountReceived");
    // if (ctrl) ctrl.focus();
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="payByCash"
        >
          <Modal.Header closeButton className="bg-warning">
            <Modal.Title id="contained-modal-title-vcenter">
              Generate Invoice
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            <div className="border p-3">
              <h4>Customer Details</h4>
              <Table className="w-100  m-0 border-0 bg-white text-dark">
                <tbody className="w-100 border-0 p-0 m-0">
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Mobile Number">
                        <Form.Control
                          type="text"
                          className="w-100"
                          name="mobileNo"
                          size="lg"
                          maxLength={10}
                          value={this.state.customerDetail.mobileNo}
                          required
                          placeholder="Mobile Number"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "12px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Name">
                        <Form.Control
                          type="text"
                          className="w-100"
                          name="firstName"
                          value={this.state.customerDetail.firstName}
                          required
                          placeholder="Name"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "12px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>

                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Emaild-Id">
                        <Form.Control
                          type="email"
                          className="w-100"
                          name="emailId"
                          value={this.state.customerDetail.emailId}
                          required
                          placeholder="Email-id"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "12px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                  <tr className="w-100 border-0 p-0 m-0">
                    <td className="w-100 border-0 ">
                      <FloatingLabel controlId="" label="Address">
                        <Form.Control
                          type="text"
                          className="w-100"
                          name="address"
                          value={this.state.customerDetail.address}
                          // required
                          placeholder="Address"
                          autoComplete="new-password"
                          onChange={(e) => this.handleInputChange(e)}
                          style={{ fontSize: "12px" }}
                        />
                      </FloatingLabel>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <Button variant="primary" size="lg" onClick={this.handleSubmit}>
              Generate Bill
            </Button>
            <Button variant="danger" size="lg" onClick={this.props.onHide}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
