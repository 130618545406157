// import { Component, useContext, useState } from "react";
// import { Button, Table, Tooltip, OverlayTrigger, Nav } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import CartContext from "./CartContext";
// import IncDecCounter from "./IncDecCounterControl";
// import { Navigate } from "react-router-dom";

// export default class CartView extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = { forUpdateValue: 1 };
//   }

//   //static forceUpdate = useForceUpdate();
//   //create your forceUpdate hook
//   forceUpdate = () => {
//     this.setState({ forUpdateValue: 0 }, () => {
//       this.setState({ forUpdateValue: 1 });
//     });
//   };

//   isMainCategoryHasItems = (maincategory) => {
//     var result = false;

//     this.context.myCart.filter(
//       (item) => item.prod.mainCategoryId === maincategory.id
//     ).length > 0
//       ? (result = true)
//       : (result = false);

//     return result;
//   };

//   ValidateProductInstockAvailablity = () => {
//     var cart = this.context.myCart;
//     cart.map((p, i) => {
//       var prd = this.context.prodsAll.filter((f) => f.id == p.prod.id);
//       if (prd.length == 1 && prd[0].inStockQty >= Number(p.qty)) {
//         //ok
//         console.log("prod in-stock" + prd[0].name);
//       } else {
//         //delete prod from cart
//         this.context.deleteItemOnMyCart(p.prod.id);
//       }
//     });
//   };

//   CloseCartOffCanvas = () => {
//     this.props.CloseCartOffCanvas();
//   };

//   componentDidMount() {
//     window.scrollTo(0, 0);
//     this.ValidateProductInstockAvailablity();
//     this.context.UpdateOrderStatus("");
//   }

//   render() {
//     if (this.context.storeId == "") return <Navigate to="/home" />;
//     if (this.state.forUpdateValue == 0) return "";
//     return (
//       <>
//         {/* <br></br>
//         <CartViewHeading></CartViewHeading>
//         <br></br> */}
//         <CartTotal
//           myCart={this.context.myCart}
//           CloseCartOffCanvas={this.CloseCartOffCanvas}
//         />
//         {this.context.mainCategories.map((maincategory) => (
//           <>
//             {this.isMainCategoryHasItems(maincategory) == true ? (
//               <div className="mx-3 px-3">
//                 <div className="row mx-5 px-3 ">
//                   <h5>&nbsp;</h5>
//                   <h4 className="text-danger">
//                     <b>
//                       <strong>{maincategory.name}</strong>
//                     </b>
//                   </h4>
//                   {/* <h5>&nbsp;</h5> */}
//                   <div className="px-3">
//                     <Table
//                       border
//                       className="cartviewtable px-5"
//                       responsive="md"
//                     >
//                       <thead>
//                         <tr className="bg-light text-center text-secondary">
//                           <th className="" width="10%"></th>
//                           <th className="text-start" width="30%">
//                             Product Name & Description
//                           </th>
//                           <th width="10%">Price</th>
//                           <th width="10%">Discount</th>
//                           <th width="20%">Qty</th>
//                           <th width="10%">Sub.Total</th>
//                           <th width="10%"></th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         {this.context.myCart
//                           .filter(
//                             (item) =>
//                               item.prod.mainCategoryId === maincategory.id
//                           )
//                           .map((cartitem) => (
//                             <>
//                               <tr className="text-center align-middle text-black">
//                                 <td className="col-md-1">
//                                   <img
//                                     src={
//                                       this.context.store.storageBlobUrl +
//                                       this.context.store
//                                         .storageBlobContainerName +
//                                       "/images/" +
//                                       cartitem.prod.imgFileName +
//                                       this.context.store.storageSasToken
//                                     }
//                                     className="cardprodimg-on-cartview mx-auto"
//                                     alt="..."
//                                   />
//                                 </td>
//                                 <td className="col-md-2 text-black text-start">
//                                   <strong>{cartitem.prod.name}</strong>
//                                   <br></br>
//                                 </td>
//                                 <td>{cartitem.prod.mrp}</td>
//                                 <td>{cartitem.prod.discount}%</td>
//                                 <td className="col-md-2 ">
//                                   <IncDecCounter
//                                     intvalue={cartitem.qty}
//                                     valueChangeCallback={(e) => {
//                                       cartitem.qty = e;
//                                       this.context.SetStateOfMyCart(
//                                         this.context.myCart
//                                       );
//                                       this.context.forceUpdateCartView();
//                                       this.forceUpdate(); //to rerender this function component
//                                     }}
//                                   ></IncDecCounter>
//                                 </td>
//                                 <td className="col-md-2 text-black">
//                                   <strong>
//                                     {this.context.GetDefaultCurrency()}
//                                     {/* &nbsp; */}
//                                     {Math.round(
//                                       Number(
//                                         cartitem.qty *
//                                           (cartitem.prod.mrp -
//                                             (cartitem.prod.mrp *
//                                               cartitem.prod.discount) /
//                                               100)
//                                       ),
//                                       0
//                                     )}
//                                   </strong>
//                                   <br></br>
//                                   Savings:
//                                   {this.context.GetDefaultCurrency()}
//                                   &nbsp;
//                                   {Math.round(
//                                     Number(
//                                       cartitem.qty *
//                                         ((cartitem.prod.mrp *
//                                           cartitem.prod.discount) /
//                                           100)
//                                     ),
//                                     0
//                                   )}
//                                 </td>
//                                 <td className="col-md-1">
//                                   <OverlayTrigger
//                                     overlay={<Tooltip>Delete!</Tooltip>}
//                                   >
//                                     <Button
//                                       variant="outline-danger"
//                                       onClick={(e) => {
//                                         if (
//                                           window.confirm(
//                                             "Do you want to Delete?"
//                                           )
//                                         ) {
//                                           this.context.deleteItemOnMyCart(
//                                             cartitem.prod.id
//                                           );
//                                           this.forceUpdate();
//                                           this.context.forceUpdateCartView();
//                                         }
//                                       }}
//                                     >
//                                       <svg
//                                         xmlns="http://www.w3.org/2000/svg"
//                                         width="16"
//                                         height="16"
//                                         fill="currentColor"
//                                         class="bi bi-trash"
//                                         viewBox="0 0 16 16"
//                                       >
//                                         <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
//                                         <path
//                                           fill-rule="evenodd"
//                                           d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
//                                         />
//                                       </svg>
//                                     </Button>
//                                   </OverlayTrigger>
//                                 </td>
//                               </tr>
//                             </>
//                           ))}
//                       </tbody>
//                     </Table>
//                   </div>
//                 </div>
//               </div>
//             ) : null}
//           </>
//         ))}
//         <br></br>
//         <CartTotal myCart={this.context.myCart} />
//         <br></br>
//       </>
//     );
//   }
// }

// export function CartViewHeading() {
//   return (
//     <>
//       <h2 className="text-success mx-5 producttitlefontandsizeDesktop">
//         <b>My Cart</b>
//         <img
//           src="./images/smiley.jpg"
//           className="mx-2"
//           alt="..."
//           width="40"
//           height="40"
//         />{" "}
//       </h2>
//     </>
//   );
// }

// export class CartTotal extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = { value: "", proceedToCheckOut: false };
//   }

//   CheckOutClick = () => {
//     if (this.context.userData.loginStatus === "inactive") {
//       this.context.storePreviousPage("/Checkout");
//       this.context.ShowUserLoginNew(true);
//     } else {
//       this.setState({ proceedToCheckOut: true });
//     }
//   };

//   render() {
//     if (
//       this.context.userData.loginStatus === "active" &&
//       this.state.proceedToCheckOut == true
//     ) {
//       this.props.CloseCartOffCanvas();
//       return <Navigate to="/Checkout" />;
//     }

//     return (
//       <>
//         <div className="Container-fluid mx-5 px-1 tablenopadding">
//           <div className="row mx-5 px-0 tablenopadding">
//             <table className="cartviewbarcolor border text-secondary tablenopadding">
//               <tr className="tablenopadding">
//                 <td className="text-start py-3 " width="25%">
//                   <h5 className="">
//                     &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b>Total</b>
//                     <small>
//                       ({this.context.getTotalQtyInCart(this.context.myCart)}{" "}
//                       Items)
//                     </small>
//                     : {this.context.GetDefaultCurrency()}&nbsp;
//                     <b>
//                       {this.context.getSubTotalPriceInCart(this.context.myCart)}
//                     </b>
//                     &nbsp;&nbsp;
//                     <small>
//                       [Total Savings: {this.context.GetDefaultCurrency()}&nbsp;
//                       {this.context.getTotalSavingsFromCart(
//                         this.context.myCart
//                       )}{" "}
//                       ]
//                     </small>
//                   </h5>
//                 </td>
//                 <td align="right" className="tablenopadding" width="20%">
//                   <Button onClick={this.CheckOutClick}>
//                     <strong className="text-white ">CheckOut...</strong>
//                   </Button>
//                   {/* <Nav.Link
//                     as={Link}
//                     className=" text-white w-50 text-center mx-4 primarythemecolor"
//                     to={
//                       this.context.getTotalQtyInCart(this.props.myCart) > 0
//                         ? "/Checkout"
//                         : ""
//                     }
//                   >
//                     <h5>
//                       <strong className="text-white ">CheckOut...</strong>
//                     </h5>
//                   </Nav.Link> */}
//                 </td>
//               </tr>
//             </table>
//           </div>
//         </div>
//       </>
//     );
//   }
// }
