import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import {
  BsFillHeartFill,
  BsHeart,
  BsCart2,
  BsBag,
  BsStar,
  BsFillStarFill,
} from "react-icons/bs";
import { ProductSingleProductView } from "./ProductSingleProductView";
import axios from "axios";
import IncDecCounter from "./IncDecCounterControl";
import Swal from "sweetalert2";

import {
  Form,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
  Card,
} from "react-bootstrap";

import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export default class ProductCard extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      qty: "1",
      showSingleView: false,
    };
  }

  componentDidMount() {}

  addToCartSubmit = (_) => {
    if (this.context.modeOfSale == "TableOrder") {
      this.props.AddRestaurantTableOrderItem({
        prod: this.props.prod,
        qty: this.state.qty,
      });
      return;
    }
    var result = this.context.addToPOSCart(this.context.selectedPOS, {
      prod: this.props.prod,
      qty: this.state.qty,
    });
    this.context.forceUpdateCartView();
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };

  getFullWidthProductName = (name) => {
    var len = name.length;
    var whitespacerequired = 60 - len;
    for (let i = 0; i < whitespacerequired; i++) {
      name += "\u00A0";
    }

    return name;
  };

  printwhitespace = () => {
    return "";
  };

  OpenSingleView = () => {
    this.setState({ showSingleView: true });
  };

  editModalClose = () => {
    this.setState({ showSingleView: false }, () => {
      this.context.forceUpdateCartView();
    });
  };

  IsAlreadyInFavList = (prodId) => {
    var result = this.context.wishListProductIds.filter(
      (f) => f.prodId == prodId
    );
    if (result.length > 0) return true;
    else return false;
  };

  render() {
    return (
      <div className="m-0 p-0 border-0">
        {this.state.showSingleView == true ? (
          <ProductSingleProductView
            show={this.state.showSingleView}
            onHide={this.editModalClose}
            prod={this.props.prod}
            AddRestaurantTableOrderItem={this.props.AddRestaurantTableOrderItem}
          />
        ) : (
          ""
        )}

        <Form className="m-0 p-0 border-0">
          <Form.Group className="m-0 p-0 " controlId="formBasicEmail">
            <>
              {window.innerWidth >= 1100 ? (
                <>
                  {this.props.productView == "grid" ? (
                    <>
                      <div>
                        <Card style={{ width: "100%" }} className="border-0">
                          {this.props.prod.imgFileName == undefined ||
                          this.props.prod.imgFileName == null ||
                          this.props.prod.imgFileName == "" ? (
                            <></>
                          ) : (
                            <>
                              <Card.Img
                                variant="top"
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  (this.props.prod.imgFileName == undefined
                                    ? this.props.prod.productImages !=
                                        undefined &&
                                      this.props.prod.productImages.length > 0
                                      ? this.props.prod.productImages[0]
                                          .fileName
                                      : "noimage.jpg"
                                    : this.props.prod.imgFileName) +
                                  this.context.store.storageSasToken
                                }
                                className="card-img-top border-0 text-center align-top align-content-start "
                                alt="..."
                                onClick={(e) => this.OpenSingleView(e)}
                                style={{
                                  cursor: "pointer",
                                  width: "100%",
                                }}
                              />
                            </>
                          )}
                          <Card.Body className="p-2 m-0 ">
                            {/* <Card.Title>Card Title</Card.Title> */}
                            <Card.Text className="text-center p-0 m-0">
                              <h6>{this.props.prod.name}</h6>
                              {/* <br /> */}
                              {this.props.prod.discount > 0 ? (
                                <>
                                  <span className="costfont">
                                    <h5 className="" style={{}}>
                                      &nbsp;
                                      <b>
                                        {Math.round(
                                          Number(
                                            this.props.prod.mrp -
                                              (this.props.prod.mrp *
                                                this.props.prod.discount) /
                                                100
                                          ),
                                          0
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 1,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </b>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      >
                                        {this.props.prod.discount}% off
                                      </span>
                                    </h5>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h5 className="">
                                    <b>
                                      {Number(
                                        this.props.prod.mrp
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>
                                  </h5>
                                </>
                              )}
                            </Card.Text>
                            <Button
                              // variant="primary"
                              className="text-center w-100 CornersRounded"
                              style={{
                                backgroundColor: "cyan",
                                color: "black",
                              }}
                              onClick={this.addToCartSubmit}
                            >
                              Add
                            </Button>
                          </Card.Body>
                        </Card>
                      </div>
                    </>
                  ) : (
                    <>
                      <Table
                        className="m-0 p-0 "
                        size="sm"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          <tr className="m-0 p-0 ">
                            {this.props.prod.imgFileName == undefined ||
                            this.props.prod.imgFileName == null ||
                            this.props.prod.imgFileName == "" ? (
                              <></>
                            ) : (
                              <>
                                <td
                                  width="5%"
                                  className=" m-0 p-0 verticalaligncentertd text-center"
                                >
                                  <img
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      (this.props.prod.imgFileName == undefined
                                        ? this.props.prod.productImages !=
                                            undefined &&
                                          this.props.prod.productImages.length >
                                            0
                                          ? this.props.prod.productImages[0]
                                              .fileName
                                          : "noimage.jpg"
                                        : this.props.prod.imgFileName) +
                                      this.context.store.storageSasToken
                                    }
                                    className="card-img-top border-0 text-center align-top align-content-start rounded-circle"
                                    alt="..."
                                    onClick={(e) => this.OpenSingleView(e)}
                                    style={{
                                      cursor: "pointer",
                                      width: "1cm",
                                    }}
                                  ></img>
                                </td>
                              </>
                            )}

                            <td width="45%" className="text-start  m-0 p-0">
                              <label
                                className="productNamewrap "
                                style={{
                                  cursor: "pointer",

                                  textAlign: "start",
                                }}
                                onClick={(e) => this.OpenSingleView(e)}
                              >
                                <div>
                                  <b>{this.props.prod.name}</b>
                                  <br />
                                  <span className="text-primary">
                                    {" "}
                                    SKU : {this.props.prod.sku}{" "}
                                  </span>
                                  <small className="text-danger">
                                    &nbsp;[Stock: {this.props.prod.inStockQty}]
                                  </small>
                                </div>
                              </label>
                            </td>

                            <td
                              width="20%"
                              className="text-center p-0 m-0  m-0 p-0 verticalaligncentertd"
                            >
                              {this.props.prod.discount > 0 ? (
                                <>
                                  <span className="costfont">
                                    <h5 className="" style={{}}>
                                      &nbsp;
                                      <b>
                                        {Math.round(
                                          Number(
                                            this.props.prod.mrp -
                                              (this.props.prod.mrp *
                                                this.props.prod.discount) /
                                                100
                                          ),
                                          0
                                        ).toLocaleString(
                                          this.context.storeSettings
                                            .defaultLocale,
                                          {
                                            minimumFractionDigits: 0,
                                            maximumFractionDigits: 1,
                                            style: "currency",
                                            currency:
                                              this.context.storeSettings
                                                .defaultCurrency,
                                          }
                                        )}
                                      </b>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      >
                                        {this.props.prod.discount}% off
                                      </span>
                                    </h5>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h5 className="">
                                    <b>
                                      {Number(
                                        this.props.prod.mrp
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>
                                  </h5>
                                </>
                              )}
                            </td>
                            <td
                              width="20%"
                              className=" m-0 p-0 verticalaligncentertd"
                            >
                              <Button
                                className="align-content-center w-100 CornersRounded"
                                onClick={this.addToCartSubmit}
                                style={{
                                  // backgroundColor: "#6EC531"
                                  backgroundColor: "cyan",
                                  color: "black",
                                }}
                              >
                                <b>+ ADD</b>
                              </Button>
                            </td>
                            <td width="10%">
                              <Button
                                size="lg"
                                className="align-content-center w-100 bg-white text-success border-0"
                                onClick={(e) =>
                                  this.context.AddToWishList(
                                    this.props.prod,
                                    this.context.wishListProductIds.length + 1
                                  )
                                }
                              >
                                {this.IsAlreadyInFavList(this.props.prod.id) ==
                                false ? (
                                  <>
                                    <BsStar></BsStar>
                                  </>
                                ) : (
                                  <>
                                    <BsFillStarFill
                                      size="20"
                                      style={{
                                        backgroundColor:
                                          "transparent !important",
                                        color: "red",
                                      }}
                                    ></BsFillStarFill>
                                  </>
                                )}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Table
                    className="m-0 p-0 "
                    size="sm"
                    style={{ tableLayout: "fixed" }}
                  >
                    <tbody>
                      <tr>
                        {this.props.prod.imgFileName == undefined ||
                        this.props.prod.imgFileName == null ||
                        this.props.prod.imgFileName == "" ? (
                          <></>
                        ) : (
                          <>
                            <td width="15%">
                              <img
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  (this.props.prod.imgFileName == undefined
                                    ? this.props.prod.productImages !=
                                        undefined &&
                                      this.props.prod.productImages.length > 0
                                      ? this.props.prod.productImages[0]
                                          .fileName
                                      : "noimage.jpg"
                                    : this.props.prod.imgFileName) +
                                  this.context.store.storageSasToken
                                }
                                className="card-img-top border-0 text-center align-top align-content-start rounded-circle"
                                alt="..."
                                onClick={(e) => this.OpenSingleView(e)}
                                style={{
                                  cursor: "pointer",
                                  width: "1cm",
                                }}
                              ></img>
                            </td>
                          </>
                        )}
                        <td width="40%" className="text-start ">
                          <label
                            className="productNamewrap mx-1"
                            style={{
                              cursor: "pointer",
                              fontFamily: "Helvetica",

                              textAlign: "start",
                              color: "black",
                              fontSize: "12px",
                            }}
                            onClick={(e) => this.OpenSingleView(e)}
                          >
                            <div>
                              &nbsp;{this.props.prod.name}
                              <br></br>
                              SKU : {this.props.prod.sku}{" "}
                            </div>
                          </label>
                        </td>
                        <td width="25%">
                          <Form.Label
                            className="text-center text-dark "
                            style={{
                              width: "100%",
                              color: "black",
                            }}
                          >
                            {this.props.prod.discount > 0 ? (
                              <>
                                <div>
                                  <span className="costfont">
                                    <b
                                      className=""
                                      style={{
                                        fontSize: "12px",
                                        color: "black",
                                      }}
                                    >
                                      &nbsp;
                                      {Math.round(
                                        Number(
                                          this.props.prod.mrp -
                                            (this.props.prod.mrp *
                                              this.props.prod.discount) /
                                              100
                                        ),
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div>
                                  <span
                                    className=" text-center"
                                    style={{
                                      fontSize: "12px",
                                      color: "black",
                                    }}
                                  >
                                    <b>
                                      {Math.round(
                                        this.props.prod.mrp,
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                    </b>
                                  </span>
                                </div>
                              </>
                            )}
                          </Form.Label>
                        </td>
                        <td width="20%">
                          <div className="p-0">
                            <Button
                              className="align-content-center w-100 "
                              onClick={this.addToCartSubmit}
                              variant="primary"
                            >
                              Add
                            </Button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </>
              )}
            </>
          </Form.Group>
        </Form>
      </div>
    );
  }
}
