import React, { Component } from "react";
import CartContext from "./CartContext";

import { Stack, Button, Table, Form, Row, Col } from "react-bootstrap";

export class OrderSummaryPOS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      couponCode: "",
      couponValue: 0,
      couponData: "",
    };
  }

  componentDidMount() {}

  StoreCouponDetails = (data) => {
    this.setState({ couponData: data }, () => {
      if (this.state.couponData.couponValueType == 1) {
        if (
          this.state.couponData.amount >
          this.context.getSubTotalPriceInCart(
            this.context.getPOSCartByName(this.context.selectedPOS)
          )
        ) {
          alert(
            "Coupon Value is " +
              this.state.couponData.amount +
              " which is greater than items in Cart. Add more items to Cart to use this Coupon!"
          );
          this.setState({ couponCode: "" });
          return;
        }
        this.setState(
          { couponValue: Math.round(this.state.couponData.amount, 0) },
          () => {
            this.props.StoreCouponDetails(
              this.state.couponCode,
              this.state.couponValue
            );
            // this.props.StoreCouponDetails(
            //   this.state.couponCode,
            //   this.state.couponValue,
            //   this.state.couponData
            // );
          }
        );
      } else if (this.state.couponData.couponValueType == 2) {
        this.setState(
          {
            couponValue: Math.round(
              (this.context.getSubTotalPriceInCart(
                this.context.getPOSCartByName(this.context.selectedPOS)
              ) *
                this.state.couponData.discount) /
                100,
              0
            ),
          },
          () => {
            this.props.StoreCouponDetails(
              this.state.couponCode,
              this.state.couponValue
            );
            // this.props.StoreCouponDetails(
            //   this.state.couponCode,
            //   this.state.couponValue,
            //   this.state.couponData
            // );
          }
        );
      }
    });
  };
  OnClickCouponApply = () => {
    if (this.state.couponCode == "" || this.state.couponCode == undefined)
      return;
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetCoupon/" +
        this.context.storeId +
        "/" +
        this.state.couponCode
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          alert("invalid coupon");
          this.setState({ couponCode: "" });
          return;
        }
        this.StoreCouponDetails(data);
      })
      .catch((error) => {
        console.error("OnClickCouponApply error!", error);
        alert("OnClickCouponApply err:" + error);
      });
  };

  onCouponCodeChange = (e) => {
    this.setState({ couponCode: e.target.value }, () => {
      this.setState({ couponValue: 0 });
      // this.props.StoreCouponDetails(this.state.couponCode, 0, "");
      this.props.StoreCouponDetails(e.target.value, 0);
    });
  };

  render() {
    if (this.context.prods.length == 0) return <></>;
    return (
      <>
        <div className=" w-100  p-0 border-0  bg-light">
          {window.innerWidth >= 1100 ? (
            <>
              <div
                className="p-0 m-0 border-0"
                style={{ backgroundColor: this.context.themeBorderColor }}
              >
                {/* <Table className="px-0 bg-white m-0 p-0 w-100" size="sm">
                  <tbody className="m-0 p-0"> */}
                <Row className="text-white alignverticalmiddle1">
                  <Col className="text-center border-0 border-secondary">
                    S.Tot <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getSubTotalPriceInCart(
                            this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )
                          )
                        ).toLocaleString(
                          this.context.storeSettings == ""
                            ? "en-IN"
                            : this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 1,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings == ""
                                ? "INR"
                                : this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </h4>
                  </Col>
                  <Col className="text-center border-0 border-secondary">
                    T.Qty
                    <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getTotalQtyInCart(this.props.myCart)
                        ).toFixed(0)}{" "}
                      </b>
                    </h4>
                  </Col>

                  {this.context.storeSettings.hideCouponCodeField == true ? (
                    ""
                  ) : (
                    <>
                      <Col className="text-center border-0 border-secondary">
                        Coupon:
                        <br />
                        <h4>
                          <Stack direction="horizontal">
                            <Form.Control
                              type="text"
                              disabled={this.context.isOfflineMode}
                              placeholder=""
                              className="bg-white text-center"
                              value={this.state.couponCode}
                              onChange={(e) => this.onCouponCodeChange(e)}
                            />
                            &nbsp;
                            <Button
                              className="bg-success text-white"
                              size="sm"
                              disabled={this.context.isOfflineMode}
                              onClick={(e) => this.OnClickCouponApply(e)}
                            >
                              Apply
                            </Button>
                          </Stack>
                        </h4>
                      </Col>
                      {this.state.couponValue > 0 ? (
                        <>
                          <Col className="text-center border-0 border-secondary">
                            C.Value <br />
                            <h4>
                              <b>
                                {Math.round(
                                  this.state.couponValue,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                              </b>
                            </h4>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}

                  {this.context.taxDetails.inclusiveOfTax == true ? (
                    ""
                  ) : (
                    <>
                      <Col className="text-center border-0 border-secondary">
                        Tax ({this.context.taxDetails.taxPercentage}
                        %)
                        <br />
                        <h4>
                          <b>
                            {Number(
                              this.context.getTaxAmount(
                                this.context.getPOSCartByName(
                                  this.context.selectedPOS
                                ),
                                this.state.couponValue
                              )
                            ).toLocaleString(
                              this.context.storeSettings == ""
                                ? "en-IN"
                                : this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings == ""
                                    ? "INR"
                                    : this.context.storeSettings
                                        .defaultCurrency,
                              }
                            )}
                          </b>
                        </h4>
                      </Col>
                    </>
                  )}
                  <Col className="text-center border-0 border-secondary">
                    Savings <br />
                    <h4>
                      <b>
                        {Number(
                          this.context.getTotalSavingsFromCart(
                            this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )
                          )
                        ).toLocaleString(
                          this.context.storeSettings == ""
                            ? "en-IN"
                            : this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings == ""
                                ? "INR"
                                : this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </h4>
                  </Col>
                  <Col className="text-center border-0 border-secondary">
                    Del.Cost <br />
                    <h4>
                      {Number(
                        this.context.getDeliveryAmount(
                          this.context.getPOSCartByName(
                            this.context.selectedPOS
                          )
                        )
                      ).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </h4>
                  </Col>
                  <Col className="text-center border border-secondary bg-success text-white">
                    <Stack direction="vertical">
                      {/* <span style={{ fontSize: "10px" }}>Total</span> */}
                      <span style={{ fontSize: "40px" }}>
                        <b>
                          {Math.round(
                            this.context.getTotalAmountWithTax(
                              this.context.getPOSCartByName(
                                this.context.selectedPOS
                              ),
                              this.state.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings == ""
                              ? "en-IN"
                              : this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings == ""
                                  ? "INR"
                                  : this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </span>
                    </Stack>
                  </Col>
                </Row>
              </div>
            </>
          ) : (
            <>
              <Table className="border m-0 p-0 text-center" size="sm">
                <tbody>
                  <Row className="m-0 p-0 text-center bg-dark text-white">
                    <Col className="  border">
                      T.Qty
                      <br />
                      <b>
                        {Number(
                          this.context.getTotalQtyInCart(this.props.myCart)
                        ).toFixed(0)}{" "}
                      </b>
                    </Col>
                    <Col className="  border">
                      S.Tot
                      <h6>
                        <b>
                          {Number(
                            this.context.getSubTotalPriceInCart(
                              this.context.getPOSCartByName(
                                this.context.selectedPOS
                              )
                            )
                          ).toLocaleString(
                            this.context.storeSettings == ""
                              ? "en-IN"
                              : this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: "currency",
                              currency:
                                this.context.storeSettings == ""
                                  ? "INR"
                                  : this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </h6>
                    </Col>
                    {this.context.taxDetails.inclusiveOfTax == true ? (
                      ""
                    ) : (
                      <>
                        <Col className=" border">
                          Tax({this.context.taxDetails.taxPercentage}
                          %)
                          <br />
                          <b>
                            {Number(
                              this.context.getTaxAmount(
                                this.context.getPOSCartByName(
                                  this.context.selectedPOS
                                ),
                                this.state.couponValue
                              )
                            ).toLocaleString(
                              this.context.storeSettings == ""
                                ? "en-IN"
                                : this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings == ""
                                    ? "INR"
                                    : this.context.storeSettings
                                        .defaultCurrency,
                              }
                            )}
                          </b>
                        </Col>
                      </>
                    )}
                    <Col className=" ">
                      Savi
                      <br />
                      <b>
                        {Number(
                          this.context.getTotalSavingsFromCart(
                            this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )
                          )
                        ).toLocaleString(
                          this.context.storeSettings == ""
                            ? "en-IN"
                            : this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                            style: "currency",
                            currency:
                              this.context.storeSettings == ""
                                ? "INR"
                                : this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </b>
                    </Col>
                    <Col className="border">
                      Deliv <br />
                      {Number(
                        this.context.getDeliveryAmount(
                          this.context.getPOSCartByName(
                            this.context.selectedPOS
                          )
                        )
                      ).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 0,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </Col>
                    <Col className="border  text-white">
                      <span style={{ fontSize: "24px" }}>
                        <b>
                          {Math.round(
                            this.context.getTotalAmountWithTax(
                              this.context.getPOSCartByName(
                                this.context.selectedPOS
                              ),
                              this.state.couponValue
                            ),
                            0
                          ).toLocaleString(
                            this.context.storeSettings == ""
                              ? "en-IN"
                              : this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: "currency",
                              currency:
                                this.context.storeSettings == ""
                                  ? "INR"
                                  : this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </span>
                    </Col>
                  </Row>
                </tbody>
              </Table>
              {/* <div className="p-3">
                <Row border-dark>
                  <h4 className="text-center ">
                    <strong>SUMMARY</strong>
                  </h4>
                </Row>
                <div className="m-0 p-0 " style={{ fontSize: "12px" }}>
                  <Table className="border m-0 p-0" size="sm">
                    <tbody>
                      <Row className="m-0 p-0 ">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            <b>
                              Subtotal [
                              {this.context.getTotalQtyInCart(
                                this.props.myCart
                              )}{" "}
                              Items]:
                            </b>
                          </Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Form.Label size="" className="">
                            <h5>
                              <b>
                                {Number(
                                  this.context.getSubTotalPriceInCart(
                                    this.context.getPOSCartByName(
                                      this.context.selectedPOS
                                    )
                                  )
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                              </b>
                            </h5>
                          </Form.Label>
                        </Col>
                        <Col></Col>
                      </Row>
                      {this.context.storeSettings.hideCouponCodeField ==
                      true ? (
                        ""
                      ) : (
                        <>
                          <Row className="m-0 p-0 ">
                            <Col className=" text-start">
                              <Form.Label size="" className="">
                                <b>Coupon Code (if any):</b>
                              </Form.Label>
                            </Col>
                            <Col className="text-end">
                              <Form.Control
                                type="text"
                                placeholder=""
                                className="bg-white text-center"
                                value={this.state.couponCode}
                                onChange={(e) => this.onCouponCodeChange(e)}
                              />
                            </Col>
                            <Col>
                              <Button
                                className="bg-primary text-white"
                                size=""
                                onClick={(e) => this.OnClickCouponApply(e)}
                              >
                                Apply
                              </Button>
                            </Col>
                          </Row>
                          {this.state.couponValue > 0 ? (
                            <>
                              <Row className="m-0 p-0">
                                <Col className=" text-start">
                                  <Form.Label size="" className="">
                                    <b>Coupon Code value :</b>
                                  </Form.Label>
                                </Col>
                                <Col className="text-end">
                                  <Form.Label size="" className="">
                                    <h5>
                                      {Math.round(
                                        this.state.couponValue,
                                        0
                                      ).toLocaleString(
                                        this.context.storeSettings == ""
                                          ? "en-IN"
                                          : this.context.storeSettings
                                              .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings == ""
                                              ? "INR"
                                              : this.context.storeSettings
                                                  .defaultCurrency,
                                        }
                                      )}
                                    </h5>
                                  </Form.Label>
                                </Col>
                                <Col></Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      )}

                      {this.context.taxDetails.inclusiveOfTax == true ? (
                        ""
                      ) : (
                        <>
                          <Row className="m-0 p-0">
                            <Col className=" text-start">
                              <Form.Label size="" className="">
                                <b>
                                  Tax&nbsp; (
                                  {this.context.taxDetails.taxPercentage}
                                  %) :
                                </b>
                              </Form.Label>
                            </Col>
                            <Col className="text-end">
                              <Form.Label size="" className="">
                                <h5>
                                  <b>
                                    {Number(
                                      this.context.getTaxAmount(
                                        this.context.getPOSCartByName(
                                          this.context.selectedPOS
                                        ),
                                        this.state.couponValue
                                      )
                                    ).toLocaleString(
                                      this.context.storeSettings == ""
                                        ? "en-IN"
                                        : this.context.storeSettings
                                            .defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings == ""
                                            ? "INR"
                                            : this.context.storeSettings
                                                .defaultCurrency,
                                      }
                                    )}
                                  </b>
                                </h5>
                              </Form.Label>
                            </Col>
                            <Col></Col>
                          </Row>
                        </>
                      )}
                      <Row className="border m-0 p-0 w-100">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            <h5 className="my-1">
                              {" "}
                              <b>Total :</b>
                            </h5>
                          </Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Form.Label size="" className="my-1">
                            <h3>
                              <b>
                                {Math.round(
                                  this.context.getTotalAmountWithTax(
                                    this.context.getPOSCartByName(
                                      this.context.selectedPOS
                                    ),
                                    this.state.couponValue
                                  ),
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                              </b>
                            </h3>
                          </Form.Label>
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row className="border mx-0">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            Total Savings:
                          </Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Form.Label size="" className="my-1">
                            <h5>
                              {Number(
                                this.context.getTotalSavingsFromCart(
                                  this.context.getPOSCartByName(
                                    this.context.selectedPOS
                                  )
                                )
                              ).toLocaleString(
                                this.context.storeSettings == ""
                                  ? "en-IN"
                                  : this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings == ""
                                      ? "INR"
                                      : this.context.storeSettings
                                          .defaultCurrency,
                                }
                              )}
                            </h5>
                          </Form.Label>
                        </Col>
                        <Col></Col>
                      </Row>
                    </tbody>
                  </Table>
                </div>
              </div> */}
            </>
          )}
        </div>
      </>
    );
  }
}
