import React, {
  Component,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import CartContext from "./CartContext";
import ProductCard from "./ProductCard.js";
import CheckoutPOS from "./CheckoutPOS.js";
import { Navigate } from "react-router-dom";
import { FaRegStar } from "react-icons/fa6";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import ReactDOM from "react-dom";
import axios from "axios";
import "../CSS/3d.css";
import Swal from "sweetalert2";
import FavList from "./FavList";
import { useZxing } from "react-zxing";
import Select from "react-select";
import { FixedSizeList as List } from "react-window";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import BarcodeReader from "react-barcode-reader";
import IncDecCounter from "./IncDecCounterControl";

import {
  Navbar,
  Nav,
  Button,
  FormControl,
  Link,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Form,
  Row,
  Col,
  Stack,
  Tab,
} from "react-bootstrap";
import {
  ControlledMenu,
  useHover,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Fuse from "fuse.js";

import { ProductsList } from "./ProductsList";
import { type } from "jquery";

const WAIT_INTERVAL = 500;
export default class SearchViewPOS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      show: false,
      searchData: [],
      searchquery: "",
      searchsku: "",
      searchbuttoncolor: "black",
      loadSpinnerOpen: false,
      showProductListByCategory: false,
      voicetxt: "",
      showWeightVariantProductModal: false,
      selectedWeightVariantProduct: "",
      myClientIpAddress: "",
      onscan: false,
      prodsSelect: [],
      prodSelectRef: "",
      autoScan: true,
      showNumberKeys: true,
      productView: "list",
    };
  }

  handleAutoScan = (event) => {
    this.setState({ autoScan: !this.state.autoScan });
    localStorage.setItem("autoScan", !this.state.autoScan);
  };
  handleShowNumberKeys = (event) => {
    this.setState({ showNumberKeys: !this.state.showNumberKeys });
    localStorage.setItem("showNumberKeys", !this.state.showNumberKeys);
  };
  handleProductView = (event) => {
    this.setState({ productView: event.target.value });
    localStorage.setItem("productView", event.target.value);
  };

  LoadProducts = () => {
    {
      var p = [];

      this.context.prodsAll
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((r) =>
          p.push({
            value: r.id,
            label: r.name,
          })
        );
      this.setState({ prodsSelect: p });
    }
  };
  OnProductSelectChange = (e, i) => {
    var result = this.context.prodsAll.filter((m) => m.id == e.value);

    if (result[0].weightVariant == true) {
      this.setState({ selectedWeightVariantProduct: result[0] });
      this.setState({ showWeightVariantProductModal: true });
    } else {
      var result = this.context.addToPOSCart(this.context.selectedPOS, {
        prod: result[0],
        qty: 1,
      });
      if (e.keyCode == 4) {
        var ctrl = document.getElementById("prodSelect");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }
    }

    // var name = "fileName";
    // const list = [...this.state.prodsSelect];
    // list[index][name] = e.value;
    // this.setState({ productImages: list });
  };

  GetBarCode = (value) => {
    this.setState({ searchsku: value }, () => {
      this.ShowSearchResultsSku("");
    });
    // this.state.prod.sku = value;
    // this.setState({ prod: this.state.prod });
    if (value != "") this.setState({ onscan: false });
  };
  OnScanner = () => {
    this.setState({ onscan: true });
  };
  OnScannerClose = () => {
    this.setState({ onscan: false });
  };

  getIPAddress1 = () => {
    fetch("https://geolocation-db.com/json/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ myClientIpAddress: data.IPv4 });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getIPAddress = () => {
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        this.setState({ myClientIpAddress: data.ip });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  readTextFromMicroPhone = (voicetxt) => {
    // this.setState({ searchquery: voicetxt });
    this.searchItem(voicetxt);
  };
  readBarCode = (txt) => {
    this.setState({ isBarCodeSearch: true }, () => {
      //this.searchItem(txt);
      this.searchBySku(txt);
    });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.setState({ searchData: [] });
  };

  HideWeightVariantProducModal = () => {
    this.setState({ showWeightVariantProductModal: false });
    this.setState({ searchsku: "" });
    var ctrl = document.getElementById("scanSku");
    if (ctrl) ctrl.focus();
  };

  searchBySku = (sku) => {
    this.setState({ searchData: [] });
    this.setState({ searchquery: "" });
    this.setState({ showProductListByCategory: false });

    this.setState({ searchsku: sku }, (r) => {
      if (this.state.searchsku.length > 0) {
        clearTimeout(this.timer);
        // this.ShowLoadSpinner();
        if (this.state.autoScan == true)
          this.timer = setTimeout(this.triggerChangeSku, WAIT_INTERVAL);
      }
    });
    if (!sku) {
      this.setState({ searchData: [] });
      return;
    }
  };
  triggerChangeSku = () => {
    this.ShowSearchResultsSku("");
  };
  ShowSearchResultsSku = (searchFrom) => {
    var result = this.context.prodsAll.filter(
      (m) => m.sku == this.state.searchsku
    );
    if (result.length == 1) {
      if (result[0].weightVariant == true) {
        this.setState({ selectedWeightVariantProduct: result[0] });
        this.setState({ showWeightVariantProductModal: true });
      } else {
        var result = this.context.addToPOSCart(this.context.selectedPOS, {
          prod: result[0],
          qty: 1,
        });
        if (result == false)
          Swal.fire({
            title: "Information",
            text: "This item is already in Cart!",
            timer: 2000,
          }).then((result) => {
            this.setState({ searchsku: "" });
          });
        else
          Swal.fire({
            title: "Added Successfully",
            // confirmButtonText: "yes",
            timer: 500,
          }).then((result) => {
            this.setState({ searchsku: "" });
          });
      }
    }
  };

  searchItem = (query) => {
    this.setState({ showProductListByCategory: false });
    this.setState({ searchquery: query }, (r) => {
      if (this.state.searchquery.length > 2) {
        clearTimeout(this.timer);
        this.ShowLoadSpinner();
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
      }
    });
    if (!query) {
      this.setState({ searchData: [] });
      return;
    }
  };

  triggerChange = () => {
    this.ShowSearchResults("");
  };
  ShowSearchResults = (searchFrom) => {
    const fuse = new Fuse(this.context.prodsAll, {
      keys: ["name", "sku", "subCategoryName"],

      //option-1
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      distance: 120,

      //option-2
      // findAllMatches: true,
      // threshold: 0.3, //number of words
      // location: 0,
      // distance: 120,
    });
    const result = fuse.search(this.state.searchquery);
    const finalResult = [];

    if (result.length) {
      result.forEach((item) => {
        if (result.length == 1 && item.item.sku == this.state.searchquery) {
          this.context.addToPOSCart(this.context.selectedPOS, {
            prod: item.item,
            qty: 1,
          });
          Swal.fire({
            title: "Added Successfully",
            // confirmButtonText: "yes",
            timer: 500,
          }).then((result) => {
            this.setState({ searchquery: "" });
          });

          return;
        }
        finalResult.push(item.item);
      });
      this.setState({ searchData: finalResult.slice(0, 15) }, () => {
        this.HideLoadSpinner();
      });
    } else {
      this.setState({ searchData: [] }, () => {
        this.HideLoadSpinner();
      });
    }
  };

  isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  hasSearchResults = () => {
    if (this.state.searchData.length > 0) return true;

    return false;
  };

  onClear = () => {
    this.setState({ searchquery: "" });
    this.setState({ searchData: [] });
    this.setState({ showProductListByCategory: false });
  };

  SubCategoryClicked = () => {
    this.setState({ searchquery: "" }, () => {
      this.setState({ searchData: [] });
    });
    this.setState({ showProductListByCategory: true });
  };

  componentDidMount() {
    var autoscan = localStorage.getItem("autoScan");
    if (autoscan == "true") this.setState({ autoScan: true });
    else if (autoscan == "false") {
      this.setState({ autoScan: false });
    }

    var showNumberKeys = localStorage.getItem("showNumberKeys");
    if (showNumberKeys == "true") this.setState({ showNumberKeys: true });
    else if (showNumberKeys == "false") {
      this.setState({ showNumberKeys: false });
    }

    var productView = localStorage.getItem("productView");
    this.setState({ productView: productView });

    this.LoadProducts();

    if (this.context.isOfflineMode == false) this.getIPAddress();

    document.getElementById("search").focus();

    document.addEventListener("keydown", (e) => {
      //CTRL+1
      if (e.ctrlKey && e.keyCode == 112) {
        var ctrl = document.getElementById("scanSku");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }

      //CTRL + 2
      if (e.ctrlKey && e.keyCode == 113) {
        if (this.state.prodSelectRef) {
          this.state.prodSelectRef.focus();
          return;
        }
      }

      //F2 to focus on prodSelect drop down
      if (e.key == "F2" && e.keyCode == 113) {
        var ctrl = document.getElementById("search");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }
    });
  }

  render() {
    const colourStyles = {
      menuPortal: (base) => ({
        ...base,
        fontSize: "14px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "green" : null,
          color: isFocused ? "white" : "#333333",
        };
      },
    };
    return (
      <>
        {this.state.showWeightVariantProductModal == true ? (
          <WeightVariantProducModal
            prod={this.state.selectedWeightVariantProduct}
            onHide={this.HideWeightVariantProducModal}
            myClientIpAddress={this.state.myClientIpAddress}
          ></WeightVariantProducModal>
        ) : (
          ""
        )}
        {window.innerWidth >= 1100 ? (
          <>
            <div className="m-0 p-0 bg-light" style={{ fontSize: "12px" }}>
              <Table className="my-0 p-1 bg-light">
                <tr>
                  <td
                    width="10%"
                    className=" m-0 p-0 border "
                    style={{
                      verticalAlign: "top",
                      alignItems: "center",
                      // backgroundColor: this.context.themeBorderColor,
                      //backgroundColor: "lightgreen",
                      backgroundColor: "#006F86",
                    }}
                  >
                    <div className="text-center text-white w-100">
                      <h5>Categories</h5>
                    </div>
                    <MainCategoryStrip
                      SubCategoryClicked={this.SubCategoryClicked}
                    ></MainCategoryStrip>
                  </td>
                  <td
                    width="90%"
                    className=" m-0 p-0"
                    style={{ verticalAlign: "top", alignItems: "center" }}
                  >
                    <div className="w-100 p-0 m-0">
                      <div
                        className="text-center  w-100 p-1"
                        // style={{ backgroundColor: this.context.themeBorderColor, color: "white" }}
                        style={{ backgroundColor: "#006F86", color: "white" }}
                      >
                        <table className="w-100">
                          <tr className="">
                            <td width="10%" className="text-start">
                              <h5>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  fill="currentColor"
                                  class="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>{" "}
                                {/* &nbsp;
                                <b>Search</b> */}
                              </h5>
                            </td>
                            <td width="20%" className="text-end">
                              <Stack direction="horizontal">
                                <Form.Check
                                  className=""
                                  type="checkbox"
                                  checked={this.state.autoScan}
                                  name="autoScan"
                                  label={""}
                                  onChange={this.handleAutoScan}
                                  className="text-start"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                ></Form.Check>
                                Auto Scan
                              </Stack>
                            </td>
                            <td width="20%" className="text-end">
                              <Stack direction="horizontal">
                                <Form.Check
                                  className=""
                                  type="checkbox"
                                  checked={this.state.showNumberKeys}
                                  name="showNumberKeys"
                                  label={""}
                                  onChange={this.handleShowNumberKeys}
                                  className="text-start"
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold",
                                  }}
                                ></Form.Check>
                                N-Keys
                              </Stack>
                            </td>
                            <td width="20%" className="text-end">
                              <Stack direction="horizontal">
                                <Form.Select
                                  className=""
                                  type="text"
                                  value={this.state.productView}
                                  name="productView"
                                  label={""}
                                  onChange={this.handleProductView}
                                >
                                  <option value="grid">Grid</option>
                                  <option value="list">List</option>
                                </Form.Select>
                              </Stack>
                            </td>
                            {this.context.hideFav == true ? (
                              <>
                                <td width="20%">
                                  <Button
                                    variant="warning"
                                    className=" border-0 CornersRounded text-center"
                                    onClick={(e) =>
                                      this.context.SetHideFav(
                                        !this.context.hideFav
                                      )
                                    }
                                  >
                                    &nbsp;&nbsp;
                                    {this.context.hideFav == true ? (
                                      <>
                                        <FaRegStar size={20}> </FaRegStar>
                                        &nbsp;Favorites
                                      </>
                                    ) : (
                                      <>&#8592;Home</>
                                    )}
                                    &nbsp;&nbsp;
                                  </Button>
                                </td>
                              </>
                            ) : (
                              <></>
                            )}

                            <td width="10%" className="text-end">
                              <Dictaphone1
                                sendVoiceTxt={this.readTextFromMicroPhone}
                              ></Dictaphone1>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <table className="w-100 my-1 border-0">
                        <tbody>
                          <tr className="w-100 m-0 p-0 ">
                            <td
                              className="col-md-12  m-0 p-0 "
                              style={{
                                verticalAlign: "top",
                                alignItems: "center",
                              }}
                            >
                              <tr>
                                <td width="44%">
                                  <FormControl
                                    id="scanSku"
                                    type="number"
                                    // size="lg"
                                    style={{
                                      fontWeight: "bold",
                                      fontSize: "20px",
                                    }}
                                    placeholder="SKU (Ctrl+F1)"
                                    className="text-dark"
                                    width="100%"
                                    aria-label="Search"
                                    autoComplete="new-password"
                                    value={this.state.searchsku}
                                    onChange={(e) =>
                                      this.searchBySku(e.target.value)
                                    }
                                    onKeyDown={(e) => {
                                      if (e.keyCode == 39) {
                                        // var ctrl =
                                        //   document.getElementById("search");
                                        // if (ctrl) {
                                        //   ctrl.focus();
                                        //   ctrl.select();
                                        // }
                                        if (this.state.prodSelectRef) {
                                          this.state.prodSelectRef.focus();
                                        }
                                      }
                                      //ENTER KEY
                                      if (e.keyCode == 13) {
                                        e.stopPropagation();
                                        this.ShowSearchResultsSku("");
                                      }
                                    }}
                                  />
                                </td>
                                <td width="56%">
                                  <Select
                                    id="prodSelect"
                                    menuPosition="fixed"
                                    maxMenuHeight={400}
                                    components={
                                      this.state.prodsSelect.length > 1000
                                        ? { MenuList }
                                        : ""
                                    }
                                    styles={colourStyles}
                                    ref={(ref) => {
                                      this.state.prodSelectRef = ref;
                                    }}
                                    value={{
                                      value: "",
                                      label: "Select Product (Ctrl+F2)",
                                    }}
                                    onChange={(e) =>
                                      this.OnProductSelectChange(e)
                                    }
                                    options={this.state.prodsSelect}
                                    onKeyDown={(e) => {
                                      if (
                                        e.keyCode == 37 &&
                                        e.target.selectionStart == 0
                                      ) {
                                        var ctrl =
                                          document.getElementById("scanSku");
                                        if (ctrl) {
                                          ctrl.focus();
                                          ctrl.select();
                                        }
                                      }
                                      if (
                                        (e.keyCode >= 48 && e.keyCode <= 57) ||
                                        (e.keyCode >= 96 && e.keyCode <= 105)
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                  ></Select>
                                </td>
                                <td
                                  width="10%"
                                  className="verticalaligncentertd "
                                >
                                  <Button
                                    className="rounded-circle"
                                    variant="danger"
                                    size="lg"
                                    onClick={(e) => this.onClear()}
                                  >
                                    X
                                  </Button>
                                </td>
                              </tr>
                            </td>
                          </tr>
                          {this.state.showNumberKeys == true ? (
                            <>
                              <Table className="p-5 ">
                                <tr>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",
                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "1",
                                        });
                                      }}
                                    >
                                      1
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      s
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "2",
                                        });
                                      }}
                                    >
                                      2
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "3",
                                        });
                                      }}
                                    >
                                      3
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "4",
                                        });
                                      }}
                                    >
                                      4
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "5",
                                        });
                                      }}
                                    >
                                      5
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "6",
                                        });
                                      }}
                                    >
                                      6
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "7",
                                        });
                                      }}
                                    >
                                      7
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "8",
                                        });
                                      }}
                                    >
                                      8
                                    </Button>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",
                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "9",
                                        });
                                      }}
                                    >
                                      9
                                    </Button>
                                  </td>
                                  <td>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku + "0",
                                        });
                                      }}
                                    >
                                      0
                                    </Button>
                                  </td>
                                  <td colSpan={2}>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",

                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: this.state.searchsku.slice(
                                            0,
                                            -1
                                          ),
                                        });
                                      }}
                                    >
                                      <i class="material-icons">&#xe14a;</i>
                                    </Button>
                                  </td>
                                  <td colSpan={2}>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",
                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.setState({
                                          searchsku: "",
                                        });
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  </td>
                                  <td colSpan={2}>
                                    <Button
                                      className="w-100"
                                      style={{
                                        backgroundColor: "#00FFFF",
                                        color: "black",
                                        height: "50px",
                                        fontSize: "26px",
                                      }}
                                      onClick={(e) => {
                                        this.ShowSearchResultsSku("");
                                      }}
                                    >
                                      Enter
                                    </Button>
                                  </td>
                                </tr>
                              </Table>
                            </>
                          ) : (
                            <></>
                          )}

                          <tr>
                            <td colSpan={3} className="px-5 bg-light">
                              <Stack
                                direction="horizontal"
                                gap={3}
                                className="w-100 border-0"
                              >
                                <BarCodeReader
                                  sendBarCode={this.readBarCode}
                                ></BarCodeReader>
                                <b>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-search"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                  </svg>
                                </b>
                                <FormControl
                                  id="search"
                                  type="search"
                                  size="lg"
                                  placeholder="Search Products (F2)"
                                  className="text-dark bg-light text-black"
                                  width="100%"
                                  aria-label="Search"
                                  value={this.state.searchquery}
                                  onChange={(e) =>
                                    this.searchItem(e.target.value)
                                  }
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                />
                              </Stack>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      {this.state.loadSpinnerOpen === true ? (
                        <h4 className="text-center w-100 text-danger">
                          <b>Loading...pls wait</b>
                        </h4>
                      ) : (
                        ""
                      )}
                      {this.state.addedToCartMsg === true ? (
                        <h4 className="text-center w-100 text-danger">
                          <b>Added</b>
                        </h4>
                      ) : (
                        ""
                      )}

                      <Table className="p-5">
                        <tr>
                          <td width="100%">
                            <div
                              className="m-0 p-2  fixedTableHightOrderQuque"
                              style={{
                                // minHeight: window.innerHeight - 150 + "px",
                                // maxHeight: window.innerHeight - 150 + "px",
                                minHeight:
                                  this.state.showNumberKeys == true
                                    ? window.innerHeight - 250 + "px"
                                    : window.innerHeight - 150 + "px",
                                maxHeight:
                                  this.state.showNumberKeys == true
                                    ? window.innerHeight - 250 + "px"
                                    : window.innerHeight - 150 + "px",
                              }}
                            >
                              {this.state.showProductListByCategory == true ? (
                                <>
                                  <ProductsList
                                    productView={this.state.productView}
                                  ></ProductsList>
                                </>
                              ) : (
                                ""
                              )}
                              {this.hasSearchResults() ? (
                                <>
                                  <div className="row justify-content-around py-2 mx-1">
                                    {this.state.searchData.map((prod) => (
                                      <div
                                        className="card mb-2 m-0 p-0"
                                        style={{
                                          width:
                                            this.state.productView == "grid"
                                              ? "3.5cm"
                                              : "100%",
                                        }}
                                      >
                                        <div className="m-0 p-0 ">
                                          <ProductCard
                                            prod={prod}
                                            productView={this.state.productView}
                                          ></ProductCard>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <br></br>
                              <br></br>
                              <br></br>
                              <br></br>
                              <br></br>
                              <br></br>
                            </div>
                          </td>
                        </tr>
                      </Table>
                    </div>
                  </td>
                </tr>
              </Table>
              <br />
              <br />
              <br />
            </div>
          </>
        ) : (
          <>
            <div>
              {this.state.onscan == true ? (
                <>
                  <BarCodeScannerModal
                    barcode={this.GetBarCode}
                    Close={this.OnScannerClose}
                  ></BarCodeScannerModal>
                </>
              ) : (
                <></>
              )}
              <Table className="w-100 m-0 p-0 " size="sm">
                <tr className="w-100 m-0 p-0 ">
                  <td width="10%">
                    <ShopByCategoryPOS
                      SubCategoryClicked={this.SubCategoryClicked}
                    ></ShopByCategoryPOS>
                  </td>
                  <td width="25%">
                    <FormControl
                      type="text"
                      id="scanSku"
                      size="lg"
                      placeholder="SKU"
                      className="text-dark fw-bold"
                      // autoComplete="new-password"
                      autoComplete="off"
                      role="presentation"
                      value={this.state.searchsku}
                      onChange={(e) => this.searchBySku(e.target.value)}
                    />
                  </td>
                  <td width="15%" className=" text-center">
                    <div className="px-3">
                      <Form.Check
                        className="text-center"
                        type="checkbox"
                        checked={this.state.showNumberKeys}
                        name="showNumberKeys"
                        label={"N"}
                        onChange={this.handleShowNumberKeys}
                        style={{ width: "30px" }}
                      ></Form.Check>
                    </div>
                  </td>
                  <td width="50%">
                    <Stack direction="horizontal" gap={3}>
                      <FormControl
                        id="search"
                        type="search"
                        size="lg"
                        placeholder="Search"
                        className=" text-dark text-black fw-bold"
                        aria-label="Search"
                        value={this.state.searchquery}
                        autoComplete="off"
                        role="presentation"
                        onChange={(e) => this.searchItem(e.target.value)}
                      />
                      <b>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          fill="currentColor"
                          class="bi bi-search"
                          viewBox="0 0 16 16"
                          onClick={(e) => this.OnScanner()}
                        >
                          <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                        </svg>
                      </b>

                      {/* <Dictaphone1
                          sendVoiceTxt={this.readTextFromMicroPhone}
                        ></Dictaphone1>
                        <BarCodeReader
                          sendBarCode={this.readBarCode}
                        ></BarCodeReader> */}
                    </Stack>
                  </td>
                </tr>
                <tr className="">
                  <td colSpan={4}>
                    {this.state.showNumberKeys == true ? (
                      <>
                        <Table className="p-5 ">
                          <tr>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",
                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "1",
                                  });
                                }}
                              >
                                1
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                s
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "2",
                                  });
                                }}
                              >
                                2
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "3",
                                  });
                                }}
                              >
                                3
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "4",
                                  });
                                }}
                              >
                                4
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "5",
                                  });
                                }}
                              >
                                5
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "6",
                                  });
                                }}
                              >
                                6
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "7",
                                  });
                                }}
                              >
                                7
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "8",
                                  });
                                }}
                              >
                                8
                              </Button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",
                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "9",
                                  });
                                }}
                              >
                                9
                              </Button>
                            </td>
                            <td>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku + "0",
                                  });
                                }}
                              >
                                0
                              </Button>
                            </td>
                            <td colSpan={2}>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",

                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: this.state.searchsku.slice(
                                      0,
                                      -1
                                    ),
                                  });
                                }}
                              >
                                <i class="material-icons">&#xe14a;</i>
                              </Button>
                            </td>
                            <td colSpan={2}>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",
                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.setState({
                                    searchsku: "",
                                  });
                                }}
                              >
                                Clear
                              </Button>
                            </td>
                            <td colSpan={2}>
                              <Button
                                className="w-100"
                                style={{
                                  backgroundColor: "#00FFFF",
                                  color: "black",
                                  height: "50px",
                                  fontSize: "26px",
                                }}
                                onClick={(e) => {
                                  this.ShowSearchResultsSku("");
                                }}
                              >
                                Enter
                              </Button>
                            </td>
                          </tr>
                        </Table>
                      </>
                    ) : (
                      <></>
                    )}
                  </td>
                </tr>
              </Table>
              {this.state.loadSpinnerOpen === true ? (
                <h4 className="text-center w-100 text-danger">
                  <b>Loading...pls wait</b>
                </h4>
              ) : (
                ""
              )}

              {this.state.showProductListByCategory == true ? (
                <>
                  <div
                    className="fixedTableHightOrderQuque"
                    style={{
                      //maxHeight: window.innerHeight / 2 + "px",
                      maxHeight: window.innerHeight - 300 + "px",
                    }}
                  >
                    <>
                      <table className="w-100 m-0 p-0 ">
                        <tbody>
                          <tr className="w-100">
                            <td className="text-center"></td>
                            <td className="text-end ">
                              <Button
                                variant="danger"
                                onClick={(e) => this.onClear()}
                              >
                                Clear
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                    <ProductsList></ProductsList>
                  </div>
                </>
              ) : (
                ""
              )}

              {this.hasSearchResults() ? (
                <>
                  <table className="w-100 m-0 p-0 ">
                    <tbody>
                      <tr className="w-100">
                        <td className="text-center">
                          <h4 className="text-danger text-center">
                            <b>Search results of "{this.state.searchquery}"</b>
                          </h4>
                        </td>
                        <td className="text-end ">
                          <Button
                            variant="danger"
                            onClick={(e) => this.onClear()}
                          >
                            Clear
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </>
              ) : (
                ""
              )}
              <div
                className="col-md-12 fixedTableHightOrderQuque m-0 p-0"
                style={{
                  // minHeight: window.innerHeight / 2 + "px",
                  //maxHeight: window.innerHeight / 2 + "px",
                  //maxHeight: "250px",
                  maxHeight: window.innerHeight - 300 + "px",
                }}
              >
                <Table
                  className="border m-0 p-0"
                  style={{ tableLayout: "fixed" }}
                >
                  <tbody>
                    {this.state.searchData.map((prod) => (
                      <tr>
                        <td>
                          <div
                            className="card m-0 p-0 "
                            style={{
                              width: "100%",
                              // height: "2cm",
                            }}
                          >
                            <ProductCard prod={prod}></ProductCard>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export class WeightVariantProducModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      price: "",
      discount: "",
      qty: "0",
      sellingPrice: "",
      firstload: false,
      intervalId: "",
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    //validate for minus values not to allow
    switch (name.trim()) {
      case "productName":
        this.setState({ productName: value });
        break;
      case "price":
        this.setState({ price: value });
        break;
      case "discount":
        this.setState({ discount: value });
        break;
      case "qty":
        this.setState({ qty: value });
        break;
    }
  };
  getWeight = () => {
    if (this.props.myClientIpAddress == "") return;
    console.log("start");
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetWeightByIPAddress/" +
          this.props.myClientIpAddress
        //"122.167.146.141"
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data != null && response.data != "") {
            this.setState({ qty: Number(response.data.scaleValue).toFixed(3) });
            console.log(response.data.scaleValue);
          }
        }
      });
  };
  setWeightToZero = () => {
    if (this.props.myClientIpAddress == "") return;
    var res = axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/SetWeightOfIPAddressToZero/" +
          this.props.myClientIpAddress
        //"122.167.146.141"
      )
      .then((response) => {
        if (response.status == 200) {
        }
      });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    // if (form.checkValidity() === false) {
    //   event.stopPropagation();
    //   return;
    // }

    var result = this.context.prodsAll.filter(
      (m) => m.name == this.state.productName
    );
    if (result.length > 0) {
      this.context.addToPOSCart(this.context.selectedPOS, {
        prod: result[0],
        qty: this.state.qty,
      });
    }

    this.setWeightToZero();

    clearInterval(this.state.intervalId);
    this.props.onHide();
  };

  RefreshWeigh = () => {
    this.state.intervalId = setInterval(() => {
      this.getWeight();
    }, 1000); //refresh once in 1mins
  };

  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      //this.setWeightToZero();
      if (this.context.isOfflineMode == false) this.RefreshWeigh();
      this.setState({ productName: this.props.prod.name });
      this.setState({ price: this.props.prod.mrp });
      this.setState({ discount: this.props.prod.discount });
      this.setState({ qty: 1 }, () => {
        var ctrl = document.getElementById("qty");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      });
    }

    //close the modal when ESC Press
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.CloseModal();
      }
    });
  };
  CloseModal = () => {
    clearInterval(this.state.intervalId);
    this.props.onHide();
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
          onHide={this.CloseModal}
        >
          <Form
          // onSubmit={this.handleSubmit}
          >
            <Modal.Header className="bg-warning" closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row>
                <Col>
                  <h2>Product Name:</h2>

                  <Form.Control
                    type="text"
                    size="lg"
                    name="productName"
                    id="productName"
                    value={this.state.productName}
                    required
                    disabled={true}
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Price:</h2>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    size="lg"
                    name="price"
                    value={this.state.price}
                    required
                    disabled={true}
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Discount(%):</h2>
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    size="lg"
                    name="discount"
                    disabled={true}
                    value={this.state.discount}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Quantity:</h2>
                </Col>
                <Col>
                  <Stack direction="horizontal">
                    <Form.Control
                      type="number"
                      size="lg"
                      name="qty"
                      id="qty"
                      value={this.state.qty}
                      required
                      placeholder=""
                      autoComplete="new-password"
                      onChange={(e) => this.handleInputChange(e)}
                      style={{ fontSize: "26px" }}
                      onKeyDown={(e) => {
                        //ENTER KEY
                        if (e.keyCode == 13) {
                          this.handleSubmit(e);
                        }
                      }}
                    />
                  </Stack>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2>Selling Price:</h2>
                </Col>
                <Col>
                  <h2>
                    {Math.round(
                      this.state.price * this.state.qty -
                        (this.state.price *
                          this.state.qty *
                          this.state.discount) /
                          100,
                      0
                    )}
                  </h2>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  disabled={this.state.qty == 0}
                  // type="submit"
                  onClick={this.handleSubmit}
                >
                  Add
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.CloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

const Dictaphone = (props) => {
  const [message, setMessage] = useState("");
  const commands = [
    {
      command: "*",
      callback: (txt) => {
        console.log(txt);
        props.sendVoiceTxt(txt);
      },
      matchInterim: true,
    },
    {
      command: "clear",
      callback: (txt) => {
        props.sendVoiceTxt("");
      },
      matchInterim: true,
    },
  ];
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
  }
  SpeechRecognition = function () {
    console.info("voice recognition ended, restarting...");
    SpeechRecognition.start();
  };

  return (
    <div>
      <p>Microphone: {listening ? "on" : "off"}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      {/* <p>{transcript}</p> */}
    </div>
  );
};

class BarCodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "barcode",
    };

    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    this.setState({
      result: data,
    });
    this.props.sendBarCode(data);
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    return (
      <div className="m-0 p-0">
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        {/* <p>{this.state.result}</p> */}
      </div>
    );
  }
}
const Dictaphone1 = (props) => {
  const [message, setMessage] = useState("");
  const commands = [
    // {
    //   command: "reset",
    //   callback: () => resetTranscript(),
    // },
    // {
    //   command: "shut up",
    //   callback: () => setMessage("I wasn't talking."),
    // },
    // {
    //   command: "Hello",
    //   callback: () => setMessage("Hi there!"),
    // },
    {
      command: "*",
      callback: (txt) => {
        console.log(txt);
        props.sendVoiceTxt(txt);
      },
      matchInterim: true,
    },
    {
      command: "clear",
      callback: (txt) => {
        props.sendVoiceTxt("");
      },
      matchInterim: true,
    },
  ];
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
  }
  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-GB",
    });
  };
  return (
    <>
      {listening ? (
        <>
          <Button
            variant="warning"
            size="sm"
            onClick={SpeechRecognition.stopListening}
          >
            <BsFillMicFill size={18}></BsFillMicFill>
            {/* <i class="bi bi-mic-fill"></i> */}
            {/* Stop */}
          </Button>
        </>
      ) : (
        <>
          <Button variant="warning" size="sm" onClick={listenContinuously}>
            {/* <i class="bi bi-mic-mute-fill"></i> */}
            <BsFillMicMuteFill size={18}></BsFillMicMuteFill>
            {/* Listen */}
          </Button>
        </>
      )}

      {/* <div>
        <span>{transcript}</span>
      </div> */}
    </>
    // <div>
    //   <div>
    //     <span>listening: {listening ? "on" : "off"}</span>
    //     <div>
    //       <button type="button" onClick={resetTranscript}>
    //         Reset
    //       </button>
    //       <button type="button" onClick={listenContinuously}>
    //         Listen
    //       </button>
    //       <button type="button" onClick={SpeechRecognition.stopListening}>
    //         Stop
    //       </button>
    //     </div>
    //   </div>
    //   <div>{message}</div>
    //   <div>
    //     <span>{transcript}</span>
    //   </div>
    // </div>
  );
};
export class MainCategoryStrip extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onMainCategoryClickEvent = (id) => {
    this.context.UpdateReloadProductListView("true");
    this.context.GetProductsByMainCategoryId(id, null, null);
    this.props.SubCategoryClicked();
  };
  render() {
    return (
      <>
        <div className="">
          {this.context.mainCategories
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((maincategory) => (
              <>
                <div>
                  <Button
                    variant="light"
                    // size="lg"
                    className="my-2 w-100 text-center card-round"
                    onClick={(e) =>
                      this.onMainCategoryClickEvent(maincategory.id)
                    }
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                      fontSize: "14px",
                    }}
                  >
                    <b>&nbsp;{maincategory.name}&nbsp;</b>
                  </Button>
                </div>
              </>
            ))}
        </div>
      </>
    );
  }
}
export const ShopByCategoryPOS = (props) => {
  const {
    theme,
    mainCategories,
    subCategories,
    GetProductsByMainCategoryId,
    GetProductsBySubCategoryId,
    UpdateReloadProductListView,
  } = useContext(CartContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const onMainCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsByMainCategoryId(event.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };

  const OnSubCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsBySubCategoryId(
      event.syntheticEvent.currentTarget.id,
      null,
      null
    );
    props.SubCategoryClicked();
  };
  return (
    <>
      <div ref={ref} {...anchorProps} m-0 p-0>
        <Button
          className={"dropdown-toggle my-0 border-0"}
          style={{
            backgroundColor: "white",
            color: "black",
          }}
        >
          <img
            src={"/images/menu1.png"}
            className=" mx-auto"
            alt="..."
            style={{ width: ".5cm" }}
          />
        </Button>
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        {mainCategories
          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          .map((maincategory) => (
            <>
              <div
                id={maincategory.id}
                onClick={(e) => onMainCategoryClickEvent(e)}
              >
                <SubMenu
                  label={maincategory.name}
                  className="my-2 fw-bold"
                  type="action"
                >
                  {subCategories
                    .filter(
                      (subcategory) =>
                        subcategory.mainCategoryId == maincategory.id
                    )
                    .map((subcategory) => (
                      <>
                        <MenuItem
                          id={subcategory.id}
                          onClick={(e) => {
                            OnSubCategoryClickEvent(e);
                          }}
                          style={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {subcategory.name}
                        </MenuItem>
                      </>
                    ))}
                </SubMenu>
              </div>
            </>
          ))}
      </ControlledMenu>
    </>
  );
};

class BarCodeScannerModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          backdrop="static"
          keyboard={false}
          dialogClassName="BrandModal"
          onHide={this.props.Close}
        >
          <Modal.Header closeButton className="bg-warning">
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            <BarcodeScanner barcode={this.props.barcode}></BarcodeScanner>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export const BarcodeScanner = (props) => {
  const [result, setResult] = useState("");
  const { ref } = useZxing({
    onDecodeResult(result) {
      setResult(result.getText());
      props.barcode(result.getText());
    },
  });

  return (
    <>
      <video ref={ref} />
      <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p>
    </>
  );
};

export class MenuList extends Component {
  render() {
    const { options, children, maxHeight, getValue } = this.props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * 35;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={35}
        initialScrollOffset={initialOffset}
        style={{ lightingColor: "black" }}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
  }
}
