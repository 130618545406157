import React, { Component } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";

import {
  Container,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Modal,
  Alert,
  ModalBody,
} from "react-bootstrap";

export default class UserLoginNew extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  LoginValid = () => {
    this.props.onHide();
  };
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-1 ">
          <Modal
            {...this.props}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter "
            centered
            backdrop="static"
            keyboard={false}
            className="spinnermodal-backdrop"
            dialogClassName="ProductModal"
          >
            <Modal.Header
              style={{
                backgroundColor: this.context.theme.mainNavBarBackgroundColor,
                color: this.context.theme.mainNavBarTextColor,
              }}
            >
              <Modal.Title>
                {this.context.storeSettings.loginMethod == "SMS NOT SUPPORTED"
                  ? "Login/Signup - Phone Number Verification"
                  : "Login/Signup - Email Verification"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <div className="row mt-2 mx-1 px-0 ">
                <div className="col-md-12 ">
                  <LoginNew LoginValid={this.LoginValid}></LoginNew>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

class LoginNew extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      mobileno: "",
      emailId: "",
      otpsent: "",
      otpentered: "",
      otpValidationSuccess: false,
      isValidLogin: "false",
      ValidationStatus: "",
      otpValidationStatus: "",
      loginResultText: "",
      userData: null,
      showTermsAndConditions: false,
    };
  }

  handleChangeeMobileNo = (event) => {
    this.setState({ mobileno: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
    this.setState({ otpsent: false });
    this.setState({ otpentered: "" });
  };
  handleEmailIdChange = (event) => {
    this.setState({ emailId: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
    this.setState({ otpsent: false });
    this.setState({ otpentered: "" });
  };

  handleChangeOtp = (event) => {
    this.setState({ otpentered: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
  };

  validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  SendEmailOtp = (resend) => {
    this.setState({ otpentered: "" });
    if (this.state.emailId.length == "") {
      this.setState({
        ValidationStatus: "Invalid Email-id! Please try again!",
      });
      return;
    }
    var emailvalid = this.validateEmail(this.state.emailId);
    if (emailvalid == false) {
      this.setState({
        ValidationStatus: "Invalid Email-id! Please try again!",
      });
      return;
    }

    var otpValue = Math.floor(1000 + Math.random() * 9000);

    if (this.context.storeSettings.liveMode == false) {
      this.setState({ otpentered: otpValue });
      this.setState({ otpsent: otpValue });
      this.setState({
        ValidationStatus:
          "OTP has been sent to your EmailID. \r Check your Inbox or Junk/Spam folders!  ",
      });
    } else {
      this.setState({ otpsent: otpValue });

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        process.env.REACT_APP_API +
          "Consumers/SendEmailOtp/" +
          this.context.storeId +
          "," +
          this.state.emailId +
          "," +
          otpValue,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            var ctrl = document.getElementById("OTP");
            if (ctrl) ctrl.focus();
            this.setState({
              ValidationStatus:
                "OTP has been sent to your EmailID. \r Check your Inbox or Junk/Spam folders!  ",
            });
            if (resend == true)
              alert(
                "We have resent OTP! Please check your Inbox or Junk/Spam folders!"
              );
          } else {
            this.setState({
              ValidationStatus: "We Unable to send OTP. Please try later!",
            });
          }
        })
        .catch((error) => {
          console.error("There was an error! ", error);
          alert("err SendSmsOtp:" + error);
        });
    }
  };

  SendSmsOtp = () => {
    this.setState({ otpentered: "" });
    if (this.state.mobileno.length != 10) {
      this.setState({
        ValidationStatus: "Invalid Phone Number! Please try again!",
      });
      return;
    }

    var otpValue = Math.floor(1000 + Math.random() * 9000);

    if (this.context.storeSettings.liveMode == false) {
      this.setState({ otpentered: otpValue });
      this.setState({ otpsent: otpValue });
    } else {
      this.setState({ otpsent: otpValue });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        process.env.REACT_APP_API +
          "Consumers/SendAgaramSMS/" +
          "Customer" +
          "," +
          this.state.mobileno +
          "," +
          otpValue,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 404 || data.status == 400) {
            this.setState({
              ValidationStatus: "We Unable to send OTP. Please try later!",
            });
            return;
          }
          this.setState({
            ValidationStatus:
              "Please check!  OTP has been sent to you Phone Number.",
          });
        })
        .catch((error) => {
          console.error("There was an error!", error);
          alert("err:" + error);
        });
    }
  };

  ValidateOtp = () => {
    if (this.state.otpsent == this.state.otpentered) {
      this.setState({ otpValidationSuccess: true });
      this.setState({ isValidLogin: true });

      this.ValidateLogin();
    } else {
      this.setState({
        otpValidationStatus: "Invalid OTP. Please enter valid OTP!",
      });
    }
  };

  ValidateLogin = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: this.context.storeId,
        mobileNo: this.state.mobileno,
        emailId: this.state.emailId,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/LoginPosByEmailId",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          this.setState({
            loginResultText: "Unable to Signup! Pleae try later.",
          });
          alert("Invalid User Account. Contact your Admin Manager! ");
          return;
        }
        // this.setState({ isValidLogin: true });
        // this.setState({ loginResultText: "Login success!" });
        this.setState({ userData: data });
        this.context.storeUserData(data);
        this.props.LoginValid();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("err:" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    //this.ValidateLogin(this.state.emailId, this.state.password);
  };
  componentDidMount() {}
  componentDidUpdate() {}

  ShowTermsAndConditionsClick = () => {
    this.setState({ showTermsAndConditions: true });
  };
  HideTermsAndConditions = () => {
    this.setState({ showTermsAndConditions: false });
  };

  ShowPrivacyPolicyClick = () => {
    this.setState({ showPrivacyPolicy: true });
  };
  HidePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  render() {
    if (this.state.isValidLogin == true) {
      if (this.context.prevPage != "") {
        var prevPage = this.context.prevPage;
        this.context.storePreviousPage("");
        return <Navigate to={prevPage} />;
      }

      return <Navigate to="/home" />;
    }
    return (
      <>
        <br></br>
        <div className="mx-0 px-3 p-2 text-dark ">
          <Form onSubmit={this.handleSubmit}>
            <div className="text-center">
              <Row>
                <Form.Label className="text-black ">
                  <h5 className="text-center">
                    {/* Phone Number verfication to Login/Signup */}
                  </h5>
                </Form.Label>
              </Row>
              <Row>
                <Col xs={1}></Col>
                {this.context.storeSettings.loginMethod ==
                "SMS NOT SUPPORTED" ? (
                  <>
                    <div className="text-start">Enter your Mobile Number:</div>
                    <Col xs={2} className="m-0 p-0">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder={
                          "+" + this.context.storeSettings.defaultCountryCode
                        }
                        className="text-center p-3 w-100 m-0"
                        value={
                          "+" + this.context.storeSettings.defaultCountryCode
                        }
                        disabled
                      />
                    </Col>
                    <Col xs={8} className="m-0 p-0">
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Enter your Mobile Number "
                        className="text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp"
                        value={this.state.mobileno}
                        // disabled={this.state.otpsent != "" ? true : false}
                        maxLength={10}
                        required
                        // autoComplete="new-password"
                        onChange={this.handleChangeeMobileNo}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs={10} className="m-0 p-0">
                      <h3 className="text-start">Enter your Emaild-id :</h3>

                      <Form.Control
                        size="lg"
                        type="email"
                        placeholder="Enter your Email-Id "
                        className="text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp"
                        value={this.state.emailId}
                        // disabled={this.state.otpsent != "" ? true : false}
                        required
                        // autoComplete="new-password"
                        onChange={this.handleEmailIdChange}
                        style={{ fontSize: "20px" }}
                      />
                    </Col>
                  </>
                )}

                <Col xs={1}></Col>
              </Row>
            </div>
            {/* Login Submit */}
            <div className="text-center text-danger">
              <br></br>
              <h5 className="blink_me">{this.state.ValidationStatus}</h5>
            </div>
            <br></br>

            {this.state.otpsent == "" ? (
              <>
                <div className="text-center py-3">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-50 "
                    size="lg"
                    onClick={(e) =>
                      this.context.storeSettings.loginMethod ==
                      "SMS NOT SUPPORTED"
                        ? this.SendSmsOtp(false)
                        : this.SendEmailOtp(false)
                    }
                  >
                    <b>Send OTP</b>
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}

            {this.state.otpsent != "" ? (
              <>
                <div>
                  <Row>
                    <br />
                  </Row>
                  <Row>
                    <Col xs={3}></Col>
                    <Col xs={6}>
                      <Row>
                        <Form.Control
                          size="lg"
                          type="text"
                          maxLength={6}
                          id="OTP"
                          placeholder="enter OTP"
                          value={this.state.otpentered}
                          className="text-center signupmobilenoandotp"
                          onChange={this.handleChangeOtp}
                        />
                      </Row>
                    </Col>
                    <Col xs={3}></Col>
                  </Row>
                </div>

                {/* Login Submit */}
                <div className="text-center text-danger">
                  <h5 className="blink_me">{this.state.otpValidationStatus}</h5>
                </div>
                <br></br>
                <div className="text-center">
                  <Button
                    variant="primary"
                    type="submit"
                    className="w-50 "
                    size="lg"
                    onClick={this.ValidateOtp}
                  >
                    <b>Proceed</b>
                  </Button>
                </div>
                <br></br>
                <div className="text-center">
                  did not receive OTP?
                  <Button
                    as={Link}
                    className=" mx-1 bg-white text-black border-0"
                    // to="\"
                    onClick={(e) =>
                      this.context.storeSettings.loginMethod ==
                      "SMS NOT SUPPORTED"
                        ? this.SendSmsOtp(true)
                        : this.SendEmailOtp(true)
                    }
                  >
                    <h5 className="text-primary">
                      <u>Resend OTP</u>
                    </h5>
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
          </Form>
        </div>
      </>
    );
  }
}
