// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Gelasio&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
}

/* body,
html {
  overflow-x: hidden;
} */

.bar {
  /* position: absolute;
  top: 0;
  left: 0; */

  width: 100%;
  padding: 1px 0;

  /* background-color: #e74c3c; */
  background-color: white;
  color: black;

  font-family: Gelasio;
  font-size: 14px;
}

.bar_content {
  display: block; /* Important to give the content a width */

  width: 100%;
  transform: translateX(100%); /* Animation start out of the screen */

  /* Add the animation */
  animation: move 30s linear infinite
    /* infinite make reapeat the animation indefinitely */;
}

/* Create the animation */
@keyframes move {
  to {
    transform: translateX(-100%);
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/CSS/textanimation.css"],"names":[],"mappings":"AAEA;EACE,sBAAsB;AACxB;;AAEA;;;GAGG;;AAEH;EACE;;YAEU;;EAEV,WAAW;EACX,cAAc;;EAEd,+BAA+B;EAC/B,uBAAuB;EACvB,YAAY;;EAEZ,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,cAAc,EAAE,0CAA0C;;EAE1D,WAAW;EACX,2BAA2B,EAAE,sCAAsC;;EAEnE,sBAAsB;EACtB;0DACwD;AAC1D;;AAEA,yBAAyB;AACzB;EACE;IACE,4BAA4B;EAC9B;AACF","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Gelasio&display=swap\");\r\n\r\n* {\r\n  box-sizing: border-box;\r\n}\r\n\r\n/* body,\r\nhtml {\r\n  overflow-x: hidden;\r\n} */\r\n\r\n.bar {\r\n  /* position: absolute;\r\n  top: 0;\r\n  left: 0; */\r\n\r\n  width: 100%;\r\n  padding: 1px 0;\r\n\r\n  /* background-color: #e74c3c; */\r\n  background-color: white;\r\n  color: black;\r\n\r\n  font-family: Gelasio;\r\n  font-size: 14px;\r\n}\r\n\r\n.bar_content {\r\n  display: block; /* Important to give the content a width */\r\n\r\n  width: 100%;\r\n  transform: translateX(100%); /* Animation start out of the screen */\r\n\r\n  /* Add the animation */\r\n  animation: move 30s linear infinite\r\n    /* infinite make reapeat the animation indefinitely */;\r\n}\r\n\r\n/* Create the animation */\r\n@keyframes move {\r\n  to {\r\n    transform: translateX(-100%);\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
