import React, { useState, useEffect, useContext } from "react";
import CartContext from "./CartContext";

//refresh bearer token every minute if user is logged on. RefreshBearerToken embedded on Navigation
const RefreshBearerToken = () => {
  const { userData, RefreshBearerTokenWithEmailId } = useContext(CartContext);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        console.log("timer called");
        if (userData.loginStatus === "active") {
          // RefreshBearerToken(userData.emailId, userData.password, userData);
          RefreshBearerTokenWithEmailId(userData);
        }

        setCounter((counter) => counter + 1);
      }, 60000); //refresh once in 1mins
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, counter]);

  return <div className="container"></div>;
};

export default RefreshBearerToken;
