import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import React, { createContext, useContext, useEffect, useState } from "react";
import Navigation from "./Components/Consumer/Navigation";
import Home from "./Components/Consumer/Home";
import { Layout } from "./Components/Layout";
import { ProductsList } from "./Components/Consumer/ProductsList";
import CartView from "./Components/Consumer/CartView";
import CompleteOrderView from "./Components/Consumer/CompleteOrderView";
import UserLogin from "./Components/Consumer/UserLogin";
import UserOrders from "./Components/Consumer/UserOrders";
import RefreshBearer from "./Components/Consumer/RefreshBearerToken.js";
import TableOrders from "./Components/Consumer/TableOrders";
import TableOrder from "./Components/Consumer/TableOrder";
import CompleteOrderViewTable from "./Components/Consumer/CompleteOrderViewTable";

import {
  ProductListViewByProductGroup,
  ProductListViewByMainCategory,
  ProductListViewBySubCategory,
} from "./Components/Consumer/ProductsList";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { CartProvider } from "./Components/Consumer/CartContext";
import LicensePage from "./Components/Consumer/LicensePage.js";

function App() {
  return (
    <Container fluid className="m-0 p-0 w-100">
      {window.innerWidth <= 1100 ? (
        <>
          <CartProvider>
            <BrowserRouter className="m-0 p-0">
              <Navigation />
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  {/* <Route path="AllCategory" element={<AllCategory />} /> */}
                  <Route
                    path="ProductsList"
                    element={<ProductsList reload={true} />}
                  />
                  <Route
                    path="ProductListViewByProductGroup"
                    element={<ProductListViewByProductGroup />}
                  />
                  <Route
                    path="ProductListViewByMainCategory"
                    element={<ProductListViewByMainCategory />}
                  />
                  <Route
                    path="ProductListViewBySubCategory"
                    element={<ProductListViewBySubCategory />}
                  />
                  <Route path="Cart" element={<CartView />} />
                  <Route path="CompleteOrder" element={<CompleteOrderView />} />
                  <Route
                    path="CompleteOrderViewTable"
                    element={<CompleteOrderViewTable />}
                  />
                  <Route path="UserLogin" element={<UserLogin />} />
                  <Route path="UserOrders" element={<UserOrders />} />
                  <Route path="RefreshBearer" element={<RefreshBearer />} />
                  <Route path="TableOrders" element={<TableOrders />} />
                  <Route path="TableOrder" element={<TableOrder />} />
                  <Route path="*" element={<Home />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </CartProvider>
        </>
      ) : (
        <>
          <CartProvider>
            <BrowserRouter className="m-0 p-0">
              <Navigation />
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<Home />} />
                  {/* <Route path="AllCategory" element={<AllCategory />} /> */}
                  <Route
                    path="ProductsList"
                    element={<ProductsList reload={true} />}
                  />
                  <Route
                    path="ProductListViewByProductGroup"
                    element={<ProductListViewByProductGroup />}
                  />
                  <Route
                    path="ProductListViewByMainCategory"
                    element={<ProductListViewByMainCategory />}
                  />
                  <Route
                    path="ProductListViewBySubCategory"
                    element={<ProductListViewBySubCategory />}
                  />
                  <Route path="Cart" element={<CartView />} />
                  <Route path="CompleteOrder" element={<CompleteOrderView />} />
                  <Route
                    path="CompleteOrderViewTable"
                    element={<CompleteOrderViewTable />}
                  />
                  <Route path="UserLogin" element={<UserLogin />} />
                  <Route path="UserOrders" element={<UserOrders />} />
                  <Route path="RefreshBearer" element={<RefreshBearer />} />
                  <Route path="TableOrders" element={<TableOrders />} />
                  <Route path="TableOrder" element={<TableOrder />} />
                  <Route path="LicensePage" element={<LicensePage />} />

                  <Route path="*" element={<Home />} />
                </Route>
              </Routes>
            </BrowserRouter>
          </CartProvider>
        </>
      )}
    </Container>
  );
}

export default App;
