import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Table } from "react-bootstrap";
import { LoadSpinner } from "./LoadSpinner";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import axios from "axios";
import { useRef } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";

export default class UserOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: false,
      userOrders: [],
      loadSpinnerOpen: false,
      selectedOrderRow: "",
    };
  }
  OnOrderRowSelect = (e, order) => {
    this.setState({ selectedOrderRow: "" }, () => {
      this.setState({ selectedOrderRow: order });
    });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetUserOrders = () => {
    this.ShowLoadSpinner();
    console.log(`Bearer ${this.context.userData.bearertoken}`);
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetUserOrders/" +
        this.context.storeId +
        "," +
        this.context.userData.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.context.userData.bearertoken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          this.HideLoadSpinner();
          return;
        }
        this.setState({ userOrders: data });
        this.HideLoadSpinner();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        this.HideLoadSpinner();
      });
  };

  componentDidMount() {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetUserOrders();
    }
  }

  GetOrderStatus = (orderStatusId) => {
    var list = this.context.orderStatusTypes.filter(
      (e) => e.id == orderStatusId
    );
    if (list.length > 0) return list[0].statusName;
    return "";
  };
  Reload = () => {
    this.setState({ userOrders: [] }, () => {
      this.GetUserOrders();
    });
  };
  OnCancelOrder = (e, order) => {
    order.orderStatus = this.context.OrderStates.CANCELANDCLOSE;
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateOrderStatus/",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.Reload();
          alert("not deleted");
          return;
        }
        this.Reload();
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("OnDelete Failed" + error);
      });
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    return (
      <>
        <>
          {this.state.loadSpinnerOpen === true ? (
            <>
              <LoadSpinner open="true"></LoadSpinner>
            </>
          ) : (
            <></>
          )}
          <div className="Container-fluid mx-0 px-1 ">
            <div className="row mt-2 mx-1 px-1">
              <div className="col-md-12  ">
                <>
                  <br></br>
                  <h1 className="producttitlefontandsizeDesktop">
                    <b>My Orders</b>
                  </h1>
                  <br></br>
                  {window.innerWidth > 1100 ? (
                    <>
                      <Table>
                        <tbody>
                          <tr>
                            <td width="60%" className="border  bg-light">
                              <Table
                                border
                                className="cartviewtable px-5 table-striped"
                                responsive="md"
                              >
                                <thead>
                                  <tr className="bg-light text-center text-secondary">
                                    <th width="10%">Order Placed</th>
                                    <th width="10%">Order No</th>
                                    <th width="10%">Total Items</th>
                                    <th width="10%">Total Amount</th>
                                    <th width="20%">Order Status</th>
                                    <th width="20%">Delivery Address</th>
                                    <th width="20%">Invoice</th>
                                    <th width="20%">options</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.userOrders
                                    // .sort((a, b) => (a.orderId < b.orderId ? 1 : -1))
                                    .sort((a, b) =>
                                      new Date(a.orderCreated) <
                                      new Date(b.orderCreated)
                                        ? 1
                                        : -1
                                    )
                                    .map((p) => (
                                      <>
                                        <tr
                                          className="text-center text-secondary "
                                          onClick={(e) =>
                                            this.OnOrderRowSelect(e, p)
                                          }
                                          onDoubleClick={(e) =>
                                            this.OnOrderRowClick(e, p)
                                          }
                                        >
                                          <td>
                                            {this.context.GetInDateFormat(
                                              new Date(p.orderCreated)
                                            ) +
                                              " " +
                                              new Date(
                                                p.orderCreated
                                              ).toLocaleString("en-US", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })}
                                          </td>
                                          <td>
                                            <b>{p.orderId}</b>
                                          </td>
                                          <td>
                                            {p.customerOrderedItems.length}
                                          </td>
                                          <td>
                                            {Math.round(Number(p.totalAmt), 0)}
                                          </td>
                                          <td>
                                            <b>
                                              {this.GetOrderStatus(
                                                p.orderStatus
                                              )}
                                            </b>
                                          </td>
                                          <td>
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .firstName
                                            }
                                            &nbsp;{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .lastName
                                            }
                                            , PhoneNo :{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .mobileNo
                                            }
                                            ,{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .address
                                            }
                                            ,{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .city
                                            }
                                            ,{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .state
                                            }
                                            ,{" "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .country
                                            }
                                            {" - "}
                                            {
                                              p
                                                .customerOrderDeliveryAddresses[0]
                                                .pinCode
                                            }
                                          </td>
                                          <td>
                                            <a
                                              href={
                                                this.context.store
                                                  .storageBlobUrl +
                                                this.context.store
                                                  .storageBlobContainerName +
                                                "/invoice/" +
                                                p.orderId +
                                                ".html" +
                                                this.context.store
                                                  .storageSasToken +
                                                "&xyz=" +
                                                new Date().toLocaleString()

                                                // this.context.store
                                                //   .invoiceFileShareAccountUrl +
                                                // this.context.store
                                                //   .invoiceFileShareName +
                                                // "/" +
                                                // p.orderId +
                                                // ".html" +
                                                // this.context.store.storageSasToken +
                                                // "&xyz=" +
                                                // new Date().toLocaleString()
                                              }
                                              target="_blank"
                                            >
                                              download
                                            </a>
                                          </td>
                                          <td>
                                            <OverlayTrigger
                                              overlay={
                                                <Tooltip>Cancel Order!</Tooltip>
                                              }
                                              popperConfig={{
                                                modifiers: {
                                                  preventOverflow: {
                                                    enabled: false,
                                                  },
                                                },
                                              }}
                                            >
                                              <Button
                                                className="mainthemecolor"
                                                disabled={
                                                  this.context.storeSettings
                                                    .hideCancelOrderinPos ==
                                                  true
                                                }
                                                onClick={(e) => {
                                                  if (
                                                    window.confirm(
                                                      "Warning! Are you sure you want to Cancel this Order?"
                                                    )
                                                  ) {
                                                    this.OnCancelOrder(e, p);
                                                  }
                                                }}
                                              >
                                                CancelOrder
                                              </Button>
                                            </OverlayTrigger>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </Table>
                            </td>
                            <td
                              width="40% "
                              className=" m-0 p-0"
                              style={{
                                backgroundColor: "black",
                                color: "yellow",
                              }}
                            >
                              <div
                                className="fixedTableHightOrder m-0 p-0 ordertablecustom"
                                style={{
                                  height: window.innerHeight - 165 + "px",
                                }}
                              >
                                {this.state.selectedOrderRow != "" ? (
                                  <>
                                    <div
                                      style={{
                                        backgroundColor: "black",
                                        color: "yellow",
                                      }}
                                    >
                                      <div className="text-center  m-0 p-0">
                                        <h4>
                                          Order No.&nbsp;{"  "}
                                          {this.state.selectedOrderRow.orderId}
                                        </h4>
                                        <PrintMe
                                          orderId={
                                            this.state.selectedOrderRow.id
                                          }
                                        ></PrintMe>
                                      </div>

                                      {/* <OrderedProductItems
                                      orderDetail={this.state.selectedOrderRow}
                                      OrderState={this.props.OrderState}
                                    ></OrderedProductItems> */}
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <>
                      <small>
                        <Table
                          border
                          className="px-0 table-striped "
                          // responsive="md"
                        >
                          <thead>
                            <tr className="bg-light text-center text-secondary">
                              <th>Order No</th>
                              <th>Total Amount</th>
                              <th>Order Status</th>
                              <th>Invoice</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.userOrders
                              // .sort((a, b) => (a.orderId < b.orderId ? 1 : -1))
                              .sort((a, b) =>
                                new Date(a.orderCreated) <
                                new Date(b.orderCreated)
                                  ? 1
                                  : -1
                              )
                              .map((p) => (
                                <>
                                  <tr className="text-center text-secondary ">
                                    <td>
                                      <h5>
                                        <b>{p.orderId}</b>
                                      </h5>

                                      {this.context.GetInDateFormat(
                                        new Date(p.orderCreated)
                                      ) +
                                        " " +
                                        new Date(p.orderCreated).toLocaleString(
                                          "en-US",
                                          {
                                            hour: "numeric",
                                            minute: "numeric",
                                            hour12: true,
                                          }
                                        )}
                                    </td>
                                    <td>{Math.round(Number(p.totalAmt), 0)}</td>
                                    <td>
                                      <b>
                                        {this.GetOrderStatus(p.orderStatus)}
                                      </b>
                                    </td>
                                    <td>
                                      <a
                                        href={
                                          this.context.store.storageBlobUrl +
                                          this.context.store
                                            .storageBlobContainerName +
                                          "/invoice/" +
                                          p.orderId +
                                          ".html" +
                                          this.context.store.storageSasToken +
                                          "&xyz=" +
                                          new Date().toLocaleString()

                                          // this.context.store
                                          //   .invoiceFileShareAccountUrl +
                                          // this.context.store
                                          //   .invoiceFileShareName +
                                          // "/" +
                                          // p.orderId +
                                          // ".html" +
                                          // this.context.store.storageSasToken +
                                          // "&xyz=" +
                                          // new Date().toLocaleString()
                                        }
                                        // target="_blank"
                                        target=""
                                      >
                                        download
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </Table>
                      </small>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </>
      </>
    );
  }
}

export function PrintMe(props) {
  const { storeId } = useContext(CartContext);

  const myref = useRef();
  const [htmltext, sethtmltext] = useState("");
  const pageStyle = `
    @page {size:30mm 20mm};
    @media all {
    .pageBreak{display:none}
  };
  @media print{
  .pageBreak{ page-Break-before:always;}
  }
    }
    `;

  const GetInvoiceHtmlFromFileAndPrint = () => {
    if (props.orderId) {
      var res = axios
        .get(
          process.env.REACT_APP_API +
            "StoreManager/GetInvoiceHtml/" +
            storeId +
            "/" +
            props.orderId
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.length > 0) {
              sethtmltext(response.data);
            }
          }
        })
        .catch((error) => {
          console.error("GetOrderStatusTypes - There was an error! ", error);
        });
    }
  };
  return (
    <>
      <div>
        <ReactToPrint
          trigger={() => (
            <Button size="lg" variant="danger" className="w-100">
              PRINT
            </Button>
          )}
          content={() => myref.current}
          // pageStyle={pageStyle}
        ></ReactToPrint>
      </div>
      <div id="divcontents" ref={myref} className="w-100">
        {GetInvoiceHtmlFromFileAndPrint()}
        <div
          className="my-0 bg-white text-dark w-100"
          dangerouslySetInnerHTML={{
            __html: htmltext,
          }}
        />
      </div>
    </>
  );
}
