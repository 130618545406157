import React, { Component } from "react";

import { Modal } from "react-bootstrap";

export default class ElasticCartAd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
          // backdrop="static"
          // keyboard={false}
          show={true}
          className="spinnermodal-backdrop m-0 p-0 bg-white  border-0"
          // dialogClassName="payByCash"
        >
          <Modal.Body
            className="text-center m-0 p-0 bg-white border-0"
            style={{ backgroundColor: "transparant" }}
          >
            <div className="text-center m-0 p-0 border-0">
              <img
                src="./elasticcartad.png"
                className="w-100 border-0 "
                alt="..."
                width="100%"
                // height="500"
                style={{ objectFit: "contain" }}
              />
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
