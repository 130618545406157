import React, {
  Component,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import CartContext from "./CartContext";
import ProductCard from "./ProductCard.js";
import { OrderSummaryPOS } from "./OrderSummaryPOS";
import CheckoutPOS from "./CheckoutPOS.js";
import SearchViewPOS from "./SearchViewPOS.js";
import { Navigate } from "react-router-dom";
import ElasticCartAd from "./ElasticCartAd.js";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import ReactDOM from "react-dom";
import axios from "axios";
import "../CSS/3d.css";
import Swal from "sweetalert2";
import FavList from "./FavList";
import Select, { createFilter } from "react-select";
import { MenuList } from "./SearchViewPOS.js";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import BarcodeReader from "react-barcode-reader";
import IncDecCounter from "./IncDecCounterControl";
import { TableDashboard1 } from "./TableOrders.js";
import TableOrder from "./TableOrder.js";

import {
  Navbar,
  Nav,
  Button,
  FormControl,
  Link,
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Form,
  Row,
  Col,
  Stack,
  Tab,
} from "react-bootstrap";
import {
  ControlledMenu,
  useHover,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

const WAIT_INTERVAL = 500;

export default class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showProductListByCategory: false,
      updateCartView: "true",
      havePermissions: false,
      coupon: { couponCode: "", couponValue: 0 },
      quickPayEventSent: false,
      quickPayEventKey: "",
      reloadCheckoutPos: false,
      orderTypeOrderDelivered: true,
      orderTypeTakeAway: false,
      orderTypeHomeDelivery: false,
      showElasticCart: true,
      myClientIpAddress: "",
      refresh: false,
      selectedTableId: "",
    };
  }
  LoadTableData = (e, tableData) => {
    this.context.SetModeOfSale("TableOrder");
    this.context.SetSelectedTable(tableData);
    this.setState({ selectedTableId: tableData.id });
  };
  getIPAddress = () => {
    //fetch("https://geolocation-db.com/json/")
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        // console.log(data);
        this.setState({ myClientIpAddress: data.ip });
      })
      .catch((error) => console.log(error));
  };
  StoreCouponDetails = (couponCode, couponValue) => {
    this.setState({
      coupon: { couponCode: couponCode, couponValue: couponValue },
    });
  };

  checkPermissions = () => {
    const permissions = navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
    });
    permissions
      .then((stream) => {
        alert("accepted the permissions");
        this.setState((prevState) => {
          this.setState({ havePermissions: !prevState.havePermissions });
        });
      })
      .catch((err) => {
        this.setState((prevState) => {
          this.setState({ havePermissions: false });
        });
        console.log(`${err.name} : ${err.message}`);
      });
  };

  UpdateCartView = () => {
    this.setState({ updateCartView: false }, () => {
      this.setState({ updateCartView: true });
    });
  };

  SetQuickPayEventFalse = () => {
    this.setState({ quickPayEventSent: false });
  };

  POSButtonClick = (e) => {
    this.setState({ selectedTableId: "" });
    this.SetOrderTypeState(e.target.id);

    this.setState({ reloadCheckoutPos: true }, () => {
      this.setState({ reloadCheckoutPos: false });
    });
  };

  SetOrderTypeState = (pos) => {
    var selectedPos = this.context.posCarts.filter((c) => c.name == pos)[0];
    if (selectedPos.orderType == "InStore") {
      this.setState({ orderTypeOrderDelivered: true });
      this.setState({ orderTypeTakeAway: false });
      this.setState({ orderTypeHomeDelivery: false });
    } else if (selectedPos.orderType == "TakeAway") {
      this.setState({ orderTypeOrderDelivered: false });
      this.setState({ orderTypeTakeAway: true });
      this.setState({ orderTypeHomeDelivery: false });
    } else if (selectedPos.orderType == "HomeDelivery") {
      this.setState({ orderTypeOrderDelivered: false });
      this.setState({ orderTypeTakeAway: false });
      this.setState({ orderTypeHomeDelivery: true });
    }
    this.context.SetSelectedPOS(pos);
  };

  handleChangeOrderType = (ordertype) => {
    this.setState({ orderTypeOrderDelivered: false });
    this.setState({ orderTypeTakeAway: false });
    this.setState({ orderTypeHomeDelivery: false });

    switch (ordertype) {
      case "InStore":
        this.setState({ orderTypeOrderDelivered: true }, () => {
          this.context.UpdatePOSCartWithNewPrice(this.context.selectedPOS);
          this.setState({ reloadCheckoutPos: true }, () => {
            this.setState({ reloadCheckoutPos: false });
          });
        });
        break;
      case "TakeAway":
        this.setState({ orderTypeTakeAway: true }, () => {
          this.context.UpdatePOSCartWithNewPrice(this.context.selectedPOS);
          this.setState({ reloadCheckoutPos: true }, () => {
            this.setState({ reloadCheckoutPos: false });
          });
        });
        break;

      case "HomeDelivery":
        this.setState({ orderTypeHomeDelivery: true }, () => {
          this.context.UpdatePOSCartWithNewPrice(this.context.selectedPOS);
          this.setState({ reloadCheckoutPos: true }, () => {
            this.setState({ reloadCheckoutPos: false });
          });
        });
        break;
    }
  };

  _handleNVEvent = (event) => {
    if (event.key == undefined) return;
    if (event.key == "F8" && this.state.quickPayEventSent == false) {
      event.preventDefault();
      this.state.quickPayEventSent = true;
      this.setState({ quickPayEventKey: "F8" }, () => {
        this.setState({ quickPayEventSent: true }, () => {
          this.setState({ reloadCheckoutPos: true }, () => {
            console.log("f8 on Home");
            this.setState({ reloadCheckoutPos: false });
          });
        });
      });
    }
    if (event.key == "F9" && this.state.quickPayEventSent == false) {
      event.preventDefault();
      this.state.quickPayEventSent = true;
      this.setState({ quickPayEventKey: "F9" });
      this.setState({ quickPayEventSent: true });
      this.setState({ reloadCheckoutPos: true }, () => {
        this.setState({ reloadCheckoutPos: false });
      });
    }
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };

  handleResize = () => {
    this.setState({ refresh: true });
    // console.log("resize called");
  };

  componentDidMount = () => {
    this.context.forceUpdateCartView();
    if (this.context.isOfflineMode == false) this.getIPAddress();

    document.addEventListener("keydown", this._handleNVEvent, false);
    if (this.context.prods.length == 0) {
      this.fakeRequest().then(() => {
        this.setState({ showElasticCart: false }); // showing the app
        this.SetOrderTypeState(this.context.selectedPOS);
      });
    } else {
      this.setState({ showElasticCart: false }); // showing the app
    }

    window.addEventListener("resize", this.handleResize);
    return () => {
      //window.removeEventListener("resize", handleResize);
      console.log("resized");
      this.setState({ refresh: false });
    };
  };
  componentDidUpdate = () => {
    this.context.SetQtyInCartModified(false);
    if (this.context.forceUpdatePosCart == true) {
      this.context.SetForceUpdatePosCart(false);
      this.setState({ reloadCheckoutPos: true }, () => {
        this.setState({ reloadCheckoutPos: false });
      });
    }
  };

  render() {
    if (this.state.showElasticCart == true) {
      return (
        <>
          <ElasticCartAd></ElasticCartAd>
        </>
      );
    }
    if (
      this.context.prodsAll == undefined ||
      this.context.prodsAll.length == 0
    ) {
      return (
        <>
          <ElasticCartAd></ElasticCartAd>
        </>
      );
    }
    if (
      this.context.storeSettings.posLicenseKey != null &&
      this.context.storeSettings.posLicenseKey !=
        this.context.GetLicenseKey() &&
      window.innerWidth > 1100
    )
      return <></>;
    return (
      <>
        {/* POS */}
        {window.innerWidth >= 1100 ? (
          <>
            <div className="Container-fluid m-0 p-0 ">
              <Table width="100%">
                <div className="row m-0 p-0 ">
                  {/* POS */}
                  <div
                    className="fw-bold m-0 p-0 justify-content-center"
                    style={{
                      width: "5%",
                      minHeight: window.innerHeight - 10 + "px",
                      maxHeight: window.innerHeight - 10 + "px",
                      // backgroundColor: this.context.themeBorderColor,
                      backgroundColor: this.context.themeBorderColor,
                    }}
                  >
                    <div
                      width="100% "
                      className="text-center "
                      style={{ fontFamily: "Tahoma" }}
                    >
                      <Button
                        id="POS1"
                        className="rounded-circle "
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                        variant={
                          this.context.selectedPOS == "POS1"
                            ? "warning"
                            : "success"
                        }
                        onClick={this.POSButtonClick}
                      >
                        POS1
                      </Button>
                    </div>
                    <div width="100% " className="text-center ">
                      <Button
                        id="POS2"
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        variant={
                          this.context.selectedPOS == "POS2"
                            ? "warning"
                            : "success"
                        }
                        onClick={this.POSButtonClick}
                      >
                        POS2
                      </Button>
                    </div>
                    <div width="100% " className="text-center ">
                      <Button
                        id="POS3"
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        variant={
                          this.context.selectedPOS == "POS3"
                            ? "warning"
                            : "success"
                        }
                        onClick={this.POSButtonClick}
                      >
                        POS3
                      </Button>
                    </div>
                    <div width="100% " className="text-center ">
                      <Button
                        id="POS4"
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        variant={
                          this.context.selectedPOS == "POS4"
                            ? "warning"
                            : "success"
                        }
                        onClick={this.POSButtonClick}
                      >
                        POS4
                      </Button>
                    </div>
                    <div width="100% " className="text-center ">
                      <Button
                        id="POS5"
                        className="rounded-circle"
                        style={{
                          width: "100%",
                          height: "50px",
                          fontSize: "14px",
                          fontWeight: "bold",
                        }}
                        variant={
                          this.context.selectedPOS == "POS5"
                            ? "warning"
                            : "success"
                        }
                        onClick={this.POSButtonClick}
                      >
                        POS5
                      </Button>
                    </div>
                    {/* <TableDashboard1
                      LoadTableData={this.LoadTableData}
                    ></TableDashboard1> */}
                  </div>
                  {this.state.selectedTableId == "" ? (
                    <>
                      {/* SEARCH */}
                      {this.context.hideFav == true ? (
                        <>
                          <div
                            className="m-0 p-0 bg-light  "
                            style={{
                              //width: this.context.hideFav == true ? "50%" : "32%",
                              width: "56%",
                            }}
                          >
                            <SearchViewPOS></SearchViewPOS>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* FAV */}
                      {this.context.hideFav == false ? (
                        <>
                          <div
                            className="m-0 p-2 bg-light  "
                            hidden={this.context.hideFav}
                            style={{
                              width: "56%",
                            }}
                          >
                            <FavList></FavList>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* CART */}
                      <div
                        className="m-0 p-1 border-0 border-dark fixedTableHightOrderQuque"
                        style={{
                          width: "39%",
                          minHeight: window.innerHeight - 100 + "px",
                          maxHeight: window.innerHeight - 100 + "px",
                          //backgroundColor: "#ECEFE3",
                          backgroundColor: "white",
                        }}
                      >
                        <div className="m-0 p-0 ">
                          {this.state.reloadCheckoutPos == false ? (
                            <>
                              <OrderType
                                handleChangeOrderType={
                                  this.handleChangeOrderType
                                }
                              ></OrderType>
                              <CartViewPOS
                                updateCartView={this.UpdateCartView}
                                myClientIpAddress={this.state.myClientIpAddress}
                              ></CartViewPOS>
                              <br />
                              <br />
                              <br />
                              <br />
                            </>
                          ) : (
                            <></>
                          )}

                          {this.context.getTotalQtyInCart(
                            this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )
                          ) > 0 ? (
                            <>
                              {/* <OrderSummaryPOS
                            myCart={this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )}
                            StoreCouponDetails={this.StoreCouponDetails}
                          ></OrderSummaryPOS> */}
                              {this.state.reloadCheckoutPos == false ? (
                                <>
                                  <BottomSummary
                                    StoreCouponDetails={this.StoreCouponDetails}
                                    coupon={this.state.coupon}
                                    quickPayEventKey={
                                      this.state.quickPayEventKey
                                    }
                                    quickPayEventSent={
                                      this.state.quickPayEventSent
                                    }
                                    SetQuickPayEventFalse={
                                      this.SetQuickPayEventFalse
                                    }
                                    orderTypeOrderDelivered={
                                      this.state.orderTypeOrderDelivered
                                    }
                                    orderTypeTakeAway={
                                      this.state.orderTypeTakeAway
                                    }
                                    orderTypeHomeDelivery={
                                      this.state.orderTypeHomeDelivery
                                    }
                                  ></BottomSummary>
                                  {/* <CheckoutPOS
                                coupon={this.state.coupon}
                                quickPayEventSent={this.state.quickPayEventSent}
                                SetQuickPayEventFalse={
                                  this.SetQuickPayEventFalse
                                }
                                orderTypeOrderDelivered={
                                  this.state.orderTypeOrderDelivered
                                }
                                orderTypeTakeAway={this.state.orderTypeTakeAway}
                                orderTypeHomeDelivery={
                                  this.state.orderTypeHomeDelivery
                                }
                              ></CheckoutPOS> */}
                                </>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="fw-bold m-0 p-0 justify-content-center"
                        style={{
                          width: "94%",
                          minHeight: window.innerHeight - 10 + "px",
                          maxHeight: window.innerHeight - 10 + "px",
                          backgroundColor: this.context.themeBorderColor,
                        }}
                      >
                        <TableOrder></TableOrder>
                      </div>
                    </>
                  )}
                </div>
              </Table>
            </div>
          </>
        ) : (
          <>
            <div className="m-0 p-0 w-100">
              <div
                className="text-center w-100 bg-dark"
                style={{ fontFamily: "Tahoma" }}
              >
                <Button
                  id="POS1"
                  className="mx-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  variant={
                    this.context.selectedPOS == "POS1" ? "warning" : "primary"
                  }
                  onClick={this.POSButtonClick}
                >
                  POS1
                </Button>

                <Button
                  id="POS2"
                  className="mx-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  variant={
                    this.context.selectedPOS == "POS2" ? "warning" : "primary"
                  }
                  onClick={this.POSButtonClick}
                >
                  POS2
                </Button>

                <Button
                  id="POS3"
                  className="mx-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  variant={
                    this.context.selectedPOS == "POS3" ? "warning" : "primary"
                  }
                  onClick={this.POSButtonClick}
                >
                  POS3
                </Button>

                <Button
                  id="POS4"
                  className="mx-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  variant={
                    this.context.selectedPOS == "POS4" ? "warning" : "primary"
                  }
                  onClick={this.POSButtonClick}
                >
                  POS4
                </Button>

                <Button
                  id="POS5"
                  className="mx-1"
                  style={{
                    fontSize: "12px",
                    fontWeight: "bold",
                  }}
                  variant={
                    this.context.selectedPOS == "POS5" ? "warning" : "primary"
                  }
                  onClick={this.POSButtonClick}
                >
                  POS5
                </Button>
              </div>
              <Table width="100%">
                <tbody class>
                  {this.state.reloadCheckoutPos == false ? (
                    <>
                      <tr className="bg-dark">
                        <OrderType
                          handleChangeOrderType={this.handleChangeOrderType}
                        ></OrderType>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  <tr>
                    <div>
                      <SearchViewPOS></SearchViewPOS>
                    </div>
                  </tr>
                  <tr>
                    {this.state.reloadCheckoutPos == false ? (
                      <>
                        <div className=" m-0 p-0 ">
                          <CartViewPOS
                            updateCartView={this.UpdateCartView}
                            myClientIpAddress={this.state.myClientIpAddress}
                          ></CartViewPOS>

                          {this.context.getTotalQtyInCart(
                            this.context.getPOSCartByName(
                              this.context.selectedPOS
                            )
                          ) > 0 ? (
                            <>
                              {this.state.reloadCheckoutPos == false ? (
                                <>
                                  <BottomSummary
                                    StoreCouponDetails={this.StoreCouponDetails}
                                    coupon={this.state.coupon}
                                    quickPayEventKey={
                                      this.state.quickPayEventKey
                                    }
                                    quickPayEventSent={
                                      this.state.quickPayEventSent
                                    }
                                    SetQuickPayEventFalse={
                                      this.SetQuickPayEventFalse
                                    }
                                    orderTypeOrderDelivered={
                                      this.state.orderTypeOrderDelivered
                                    }
                                    orderTypeTakeAway={
                                      this.state.orderTypeTakeAway
                                    }
                                    orderTypeHomeDelivery={
                                      this.state.orderTypeHomeDelivery
                                    }
                                  ></BottomSummary>
                                </>
                              ) : (
                                <></>
                              )}
                              {/* <OrderSummaryPOS
                                myCart={this.context.getPOSCartByName(
                                  this.context.selectedPOS
                                )}
                                StoreCouponDetails={this.StoreCouponDetails}
                              ></OrderSummaryPOS>
                              {this.state.reloadCheckoutPos == false ? (
                                <>
                                  <CheckoutPOS
                                    coupon={this.state.coupon}
                                    quickPayEventSent={
                                      this.state.quickPayEventSent
                                    }
                                    SetQuickPayEventFalse={
                                      this.SetQuickPayEventFalse
                                    }
                                    orderTypeOrderDelivered={
                                      this.state.orderTypeOrderDelivered
                                    }
                                    orderTypeTakeAway={
                                      this.state.orderTypeTakeAway
                                    }
                                    orderTypeHomeDelivery={
                                      this.state.orderTypeHomeDelivery
                                    }
                                  ></CheckoutPOS>
                                </>
                              ) : (
                                <></>
                              )} */}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}

class CartViewPOS extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { forUpdateValue: 1, showAddCustomItemModal: false };
  }

  //static forceUpdate = useForceUpdate();
  //create your forceUpdate hook
  forceUpdate = () => {
    this.setState({ forUpdateValue: 0 }, () => {
      this.setState({ forUpdateValue: 1 });
    });
  };

  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.context
      .getPOSCartByName(this.context.selectedPOS)
      .filter((item) => item.prod.mainCategoryId === maincategory.id).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };

  ValidateProductInstockAvailablity = () => {
    var cart = this.context.getPOSCartByName(this.context.selectedPOS);
    cart.map((p, i) => {
      var prd = this.context.prodsAll.filter((f) => f.id == p.prod.id);
      if (prd.length == 1 && prd[0].inStockQty >= Number(p.qty)) {
        //ok
        console.log("prod in-stock" + prd[0].name);
      } else {
        //delete prod from cart
        if (p.prod.id.includes("Custom") === false)
          this.context.deleteItemOnMyCart(p.prod.id);
      }
    });
  };

  ShowAddCustomItemModal = () => {
    this.setState({ showAddCustomItemModal: true });
  };
  HideAddCustomItemModal = () => {
    this.setState({ showAddCustomItemModal: false });
  };

  OnAddCustomItem = () => {
    this.ShowAddCustomItemModal();
  };

  CloseCartOffCanvas = () => {
    this.props.CloseCartOffCanvas();
  };

  _handleNVEvent = (event) => {
    if (event.key == undefined) return;
    if (event.key == "F4") {
      event.preventDefault();
      this.OnAddCustomItem();
    }
  };
  componentDidMount() {
    document.addEventListener("keydown", this._handleNVEvent, false);
    window.scrollTo(0, 0);
    //this is not required for POS
    //this.ValidateProductInstockAvailablity();
    this.context.UpdateOrderStatus("");

    document.addEventListener("keydown", (e) => {
      if (e.ctrlKey && e.keyCode == 17) {
        var ctrl = document.getElementById("carttable");
        if (ctrl) {
          ctrl.focus();
          // ctrl.select();
        }
      }
    });
  }

  handleInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "qty":
        this.state.carousels[i].imgFileName = value;
        break;
    }
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.forUpdateValue == 0) return "";
    if (
      (this.context.userData != undefined &&
        this.context.userData.posRoleId == 1) ||
      this.context.userData.posRoleId == 2 ||
      this.context.userData.posRoleId == null ||
      this.context.userData.posRoleId == undefined
    ) {
    } else if (
      this.context.userData != undefined &&
      this.context.userData.posRoleId == 3
    ) {
      return <Navigate to="/TableOrders" />;
    }

    return (
      <>
        {this.state.showAddCustomItemModal == true ? (
          <AddCustomeItem
            myClientIpAddress={this.props.myClientIpAddress}
            onHide={this.HideAddCustomItemModal}
          ></AddCustomeItem>
        ) : (
          ""
        )}

        {window.innerWidth > 1100 ? (
          <>
            <div className="w-100 m-0 p-0 ">
              <Table
                border
                className="w-100 border m-0 p-0 bg-light text-white"
                responsive="md"
                style={{ fontSize: "10pt" }}
              >
                <thead className="text-center m-0 p-0 ">
                  <tr
                    className="text-center m-0 p-0 verticalaligncentertd"
                    style={{
                      backgroundColor: "#D3E2AF",
                      color: "black",
                    }}
                  >
                    <td
                      width="40%"
                      className=" text-center verticalaligncentertd"
                    >
                      <Row className="verticalaligncentertd ">
                        {/* <Stack direction="horizontal"> */}
                        <Col className="verticalaligncentertd ">
                          <h5 className=" verticalaligncentertd my-2  ">
                            <i class="bi bi-cart-check"></i>
                            (Ctrl+`)
                          </h5>
                        </Col>
                        <Col>
                          {/* &nbsp;&nbsp; */}
                          <Button
                            onClick={this.OnAddCustomItem}
                            className="p-2 CornersRounded"
                            size="sm"
                            variant="success"
                          >
                            +Custom(F4)
                          </Button>
                        </Col>
                        {/* </Stack> */}
                      </Row>
                    </td>
                    <td width="13%" className=" border">
                      Price
                    </td>
                    <td width="8%" className=" border">
                      Disc
                    </td>
                    <td width="15%" className=" border">
                      Qty
                    </td>
                    <td width="15%" className="border">
                      S.Total
                    </td>
                    <td width="5%" className=""></td>
                  </tr>
                </thead>
              </Table>
              <div className="w-100 m-0 p-0 " style={{ fontSize: "14px" }}>
                {/* Show Cart Items */}
                <div className="p-0 ">
                  <Table borderless>
                    {this.context
                      .getPOSCartByName(this.context.selectedPOS)
                      // .filter((item) => item.prod.id.split("-")[0] != "Custom")
                      .map((item) => item)
                      .reverse()
                      // .toReversed()
                      .map((cartitem, i) => (
                        <>
                          <div className="my-2 ">
                            <tr
                              className="text-start alignverticalmiddle text-black "
                              style={{ backgroundColor: "#F0F8FB" }}
                            >
                              <td
                                width="40%"
                                className=" text-black text-start "
                              >
                                {i + 1} &nbsp;
                                {cartitem.prod.imgFileName == undefined ||
                                cartitem.prod.imgFileName == null ||
                                cartitem.prod.imgFileName == "" ? (
                                  <></>
                                ) : (
                                  <>
                                    <img
                                      src={
                                        this.context.store.storageBlobUrl +
                                        this.context.store
                                          .storageBlobContainerName +
                                        "/images/" +
                                        cartitem.prod.imgFileName +
                                        this.context.store.storageSasToken
                                      }
                                      className="rounded-circle"
                                      alt="..."
                                      style={{
                                        cursor: "pointer",
                                        width: "1cm",
                                      }}
                                    />
                                  </>
                                )}
                                &nbsp;&nbsp;{" "}
                                <strong>{cartitem.prod.name}</strong>
                                {cartitem.prod.sku ? (
                                  <>
                                    <span
                                      className="text-primary"
                                      style={{ fontSize: "12px" }}
                                    >
                                      <small>
                                        &nbsp; SKU: {cartitem.prod.sku}
                                      </small>
                                    </span>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td width="13%" className=" order text-center">
                                <h5>
                                  {Number(cartitem.prod.mrp).toLocaleString(
                                    this.context.storeSettings == ""
                                      ? "en-IN"
                                      : this.context.storeSettings
                                          .defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings == ""
                                          ? "INR"
                                          : this.context.storeSettings
                                              .defaultCurrency,
                                    }
                                  )}
                                </h5>
                              </td>
                              <td width="8%" className="text-center ">
                                {cartitem.prod.discount}%
                              </td>
                              <td width="15%" className=" text-center ">
                                {/* <IncDecCounter
                                disabled={false}
                                intvalue={cartitem.qty}
                                valueChangeCallback={(e) => {
                                  cartitem.qty = e;
                                  this.context.SetStateOfMyCart(
                                    this.context.getPOSCartByName(
                                      this.context.selectedPOS
                                    )
                                  );
                                  this.context.forceUpdateCartView();
                                  this.forceUpdate();
                                }}
                              ></IncDecCounter> */}
                                <Form.Control
                                  type="number"
                                  id="carttable"
                                  size="sm"
                                  name="qty"
                                  value={
                                    cartitem.qty % 1 == 0
                                      ? cartitem.qty
                                      : Number(cartitem.qty)
                                  }
                                  required
                                  placeholder=""
                                  className="text-center"
                                  autoComplete="new-password"
                                  onChange={(e) => {
                                    if (e.target.value != cartitem.qty) {
                                      cartitem.qty = e.target.value;
                                      this.context.SetStateOfMyCart(
                                        this.context.getPOSCartByName(
                                          this.context.selectedPOS
                                        )
                                      );
                                      this.context.forceUpdateCartView();
                                      // this.forceUpdate();
                                    }
                                  }}
                                  style={{ fontSize: "18px" }}
                                ></Form.Control>
                              </td>
                              <td
                                width="15%"
                                className=" text-black text-end  mx-1"
                              >
                                <h5>
                                  <b>
                                    {Number(
                                      cartitem.qty *
                                        (cartitem.prod.mrp -
                                          (cartitem.prod.mrp *
                                            cartitem.prod.discount) /
                                            100)
                                    ).toLocaleString(
                                      this.context.storeSettings == ""
                                        ? "en-IN"
                                        : this.context.storeSettings
                                            .defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings == ""
                                            ? "INR"
                                            : this.context.storeSettings
                                                .defaultCurrency,
                                      }
                                    )}
                                  </b>
                                </h5>
                              </td>
                              <td width="5%" className=" text-center border-0">
                                <OverlayTrigger
                                  overlay={<Tooltip>Delete!</Tooltip>}
                                  popperConfig={{
                                    modifiers: {
                                      preventOverflow: {
                                        enabled: false,
                                      },
                                    },
                                  }}
                                >
                                  <Button
                                    // variant="danger"
                                    size="sm"
                                    onClick={(e) => {
                                      this.context.deleteItemOnMyCart(
                                        cartitem.prod.id
                                      );
                                      this.forceUpdate();
                                      this.context.forceUpdateCartView();
                                    }}
                                    style={{
                                      backgroundColor: "white",
                                      color: "red",
                                      border: 0,
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      // fill="currentColor"
                                      color="white"
                                      class="bi bi-trash"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                      <path
                                        fill-rule="evenodd"
                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                      />
                                    </svg>
                                  </Button>
                                </OverlayTrigger>
                              </td>
                            </tr>
                          </div>
                        </>
                      ))}
                  </Table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div>
              <table
                className="w-100 border-0"
                style={{ tableLayout: "fixed" }}
              >
                <tr
                  className="text-start  border-0"
                  style={{ backgroundColor: "darkgreen", color: "white" }}
                >
                  <td className="col-md-3">
                    <div className="my-3">
                      <h5>
                        <i class="bi bi-cart-check"></i>
                        &nbsp;<b>ORDERS</b>
                      </h5>
                    </div>
                  </td>

                  <td className="col-md-3 text-end ">
                    <Button
                      className="mx-3 border "
                      onClick={this.OnAddCustomItem}
                      style={{ backgroundColor: "#026B54", color: "white" }}
                    >
                      + Custom Item
                    </Button>
                  </td>
                </tr>
              </table>
              <Table
                border
                className="w-100 border m-0 p-0 border-dark bg-light"
                responsive="md"
                style={{ fontSize: "12pt", tableLayout: "fixed" }}
              >
                <thead>
                  <tr className="text-center ">
                    <th width="1%"></th>
                    <th width="5%"></th>
                    <th width="35%"></th>
                    <th width="30%">Qty</th>
                    <th width="20%">S.Tot</th>
                    <th width="10%"></th>
                  </tr>
                </thead>
              </Table>
              <div className="w-100 border m-0 p-0 border-dark">
                {/* CART Items */}
                <div className="p-1">
                  {this.context
                    .getPOSCartByName(this.context.selectedPOS)
                    .filter((item) => item.prod.id.split("-")[0] != "Custom")
                    .map((item) => item)
                    .reverse()
                    // .toReversed()
                    .map((cartitem, i) => (
                      <>
                        <div className="my-2">
                          <tr className="text-center alignverticalmiddle text-black">
                            <td>{i + 1}</td>
                            {cartitem.prod.imgFileName == undefined ||
                            cartitem.prod.imgFileName == null ||
                            cartitem.prod.imgFileName == "" ? (
                              <></>
                            ) : (
                              <>
                                <td width="10%">
                                  <img
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      cartitem.prod.imgFileName +
                                      this.context.store.storageSasToken
                                    }
                                    className="cardprodimg-on-cartview mx-auto rounded-circle"
                                    alt="..."
                                    style={{
                                      cursor: "pointer",
                                      width: "1cm",
                                    }}
                                  />
                                </td>
                              </>
                            )}
                            <td className=" text-black text-start" width="35%">
                              {cartitem.prod.name}
                              <br></br>
                              <span
                                className="text-primary"
                                style={{ fontSize: "11px" }}
                              >
                                <small>SKU : {cartitem.prod.sku}</small>
                              </span>
                            </td>
                            <td className=" text-center" width="30%">
                              <IncDecCounter
                                disabled={false}
                                intvalue={cartitem.qty}
                                valueChangeCallback={(e) => {
                                  cartitem.qty = e;
                                  this.context.SetStateOfMyCart(
                                    this.context.getPOSCartByName(
                                      this.context.selectedPOS
                                    )
                                  );
                                  this.context.forceUpdateCartView();
                                  this.forceUpdate(); //to rerender this function component
                                }}
                              ></IncDecCounter>
                            </td>
                            <td className="text-black" width="20%">
                              <b>
                                {Number(
                                  cartitem.qty *
                                    (cartitem.prod.mrp -
                                      (cartitem.prod.mrp *
                                        cartitem.prod.discount) /
                                        100)
                                ).toLocaleString(
                                  this.context.storeSettings == ""
                                    ? "en-IN"
                                    : this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings == ""
                                        ? "INR"
                                        : this.context.storeSettings
                                            .defaultCurrency,
                                  }
                                )}
                              </b>
                            </td>
                            <td className="" width="10%">
                              <OverlayTrigger
                                overlay={<Tooltip>Delete!</Tooltip>}
                                popperConfig={{
                                  modifiers: {
                                    preventOverflow: {
                                      enabled: false,
                                    },
                                  },
                                }}
                              >
                                <Button
                                  // variant="danger"
                                  className="bg-white border-0"
                                  onClick={(e) => {
                                    this.context.deleteItemOnMyCart(
                                      cartitem.prod.id
                                    );
                                    this.forceUpdate();
                                    this.context.forceUpdateCartView();
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    color="red"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </div>
                      </>
                    ))}
                </div>

                {/* show custom items */}
                <div>
                  {this.context
                    .getPOSCartByName(this.context.selectedPOS)
                    .filter((item) => item.prod.id.split("-")[0] == "Custom")
                    .length > 0 ? (
                    <>
                      <h4 className="text-danger">
                        <b>
                          <strong>Custom Items</strong>
                        </b>
                      </h4>
                    </>
                  ) : (
                    ""
                  )}

                  {this.context
                    .getPOSCartByName(this.context.selectedPOS)
                    .filter((item) => item.prod.id.split("-")[0] == "Custom")
                    .map((cartitem, i) => (
                      <>
                        <tr className="text-center align-middle text-black">
                          <td>{i + 1}</td>
                          <td width="10%" className=""></td>
                          <td width="30%" className=" text-black text-start">
                            <strong>{cartitem.prod.name}</strong>
                            <br />[
                            {Number(cartitem.prod.mrp).toLocaleString(
                              this.context.storeSettings == ""
                                ? "en-IN"
                                : this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings == ""
                                    ? "INR"
                                    : this.context.storeSettings
                                        .defaultCurrency,
                              }
                            )}
                            ] - {cartitem.prod.discount}% Off
                          </td>
                          <td width="30%" className="">
                            {cartitem.qty}
                          </td>
                          <td width="20%" className="text-black">
                            <strong>
                              {Number(
                                cartitem.qty *
                                  (cartitem.prod.mrp -
                                    (cartitem.prod.mrp *
                                      cartitem.prod.discount) /
                                      100)
                              ).toLocaleString(
                                this.context.storeSettings == ""
                                  ? "en-IN"
                                  : this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings == ""
                                      ? "INR"
                                      : this.context.storeSettings
                                          .defaultCurrency,
                                }
                              )}
                            </strong>
                          </td>
                          <td width="10%" className="">
                            <OverlayTrigger
                              overlay={<Tooltip>Delete!</Tooltip>}
                              popperConfig={{
                                modifiers: {
                                  preventOverflow: {
                                    enabled: false,
                                  },
                                },
                              }}
                            >
                              <Button
                                className="bg-white border-0"
                                onClick={(e) => {
                                  this.context.deleteItemOnMyCart(
                                    cartitem.prod.id
                                  );
                                  this.forceUpdate();
                                  this.context.forceUpdateCartView();
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  color="red"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </Button>
                            </OverlayTrigger>
                          </td>
                        </tr>
                      </>
                    ))}
                </div>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
      </>
    );
  }
}

export class AddCustomeItem extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      price: "0",
      discount: "0",
      qty: "1",
      sellingPrice: "",
      prodsSelect: [],
      prodSelectRef: "",
      productName: [],
    };
  }
  LoadProducts = () => {
    {
      var p = [];

      this.context.prodsAll
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map(
          (r) =>
            p.push({
              value: r.name,
              label: r.name,
            })
          //p.push(r.name)
        );
      this.setState({ prodsSelect: p });
    }
  };

  OnProductSelectChange = (e) => {
    if (e.value) {
      this.setState({ productName: e.value });
    } else {
      if (e.target.defaultValue == "") return;
      this.state.prodsSelect.push({
        value: e.target.defaultValue,
        label: e.target.defaultValue,
      });
      this.setState({ prodsSelect: this.state.prodsSelect });
      this.setState({ productName: e.target.defaultValue });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "productName":
        this.setState({ productName: value });
        break;
      case "price":
        this.setState({ price: value });
        break;
      case "discount":
        this.setState({ discount: value });
        break;
      case "qty":
        if (Number(value) || value == "" || value == ".") {
          this.setState({ qty: value });
        }
        break;
    }
  };

  getWeight = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetWeightByIPAddress/" +
          this.props.myClientIpAddress
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data != null && response.data != "")
            this.setState({ qty: Number(response.data.scaleValue).toFixed(2) });
        }
      });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    this.context.addCustomItemToMyCart({
      prod: {
        id: "Custom-" + this.context.GetNewGuid(),
        name: this.state.productName,
        mrp: this.state.price,
        discount: this.state.discount,
      },
      qty: this.state.qty,
    });
    this.props.onHide();
  };
  componentDidMount = () => {
    this.LoadProducts();
    // var ctrl = document.getElementById("productName");
    // if (ctrl) ctrl.focus();

    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.CloseModal();
      }
    });

    if (this.state.prodSelectRef) {
      this.state.prodSelectRef.focus();
      // this.state.prodSelectRef.select();
    }

    // var ctrl = document.getElementById("productlist");
    // if (ctrl) {
    //   ctrl.focus();
    //   ctrl.select();
    // }
  };

  CloseModal = () => {
    this.props.onHide();
  };

  render() {
    const filterConfig = { matchFrom: "start" };
    const colourStyles = {
      menuPortal: (base) => ({
        ...base,
        fontSize: "14px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? "green" : null,
          color: isFocused ? "white" : "#333333",
        };
      },
    };
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
        >
          <Form
          // onSubmit={this.handleSubmit}
          >
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Add Custom Item
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row className="py-1">
                <Col className="col-md-12">
                  <h2>Product Name:</h2>

                  <Select
                    id="prodSelect"
                    menuPosition="fixed"
                    components={
                      this.state.prodsSelect.length > 1000 ? { MenuList } : ""
                    }
                    styles={colourStyles}
                    ref={(ref) => {
                      this.state.prodSelectRef = ref;
                    }}
                    value={{
                      value: this.state.productName,
                      label: this.state.productName,
                    }}
                    onChange={(e) => this.OnProductSelectChange(e)}
                    onBlur={(e) => this.OnProductSelectChange(e)}
                    options={this.state.prodsSelect}
                    // captureMenuScroll={false}
                    onKeyDown={(e) => {
                      //ENTER KEY
                      if (e.keyCode == 13) {
                        var ctrl = document.getElementById("price");
                        if (ctrl) {
                          ctrl.focus();
                          ctrl.select();
                        }
                      }
                    }}
                  ></Select>
                </Col>
              </Row>
              <Row className="py-1">
                <Col className="col-md-4">
                  <h2>Price:</h2>
                </Col>
                <Col className="col-md-8">
                  <Form.Control
                    type="number"
                    id="price"
                    size="lg"
                    name="price"
                    value={this.state.price}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                    onKeyDown={(e) => {
                      //ENTER KEY
                      if (e.keyCode == 13) {
                        var ctrl = document.getElementById("discount");
                        if (ctrl) {
                          ctrl.focus();
                          ctrl.select();
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className="py-1">
                <Col className="col-md-4">
                  <h2>Discount(%):</h2>
                </Col>
                <Col className="col-md-8">
                  <Form.Control
                    type="number"
                    size="lg"
                    id="discount"
                    name="discount"
                    value={this.state.discount}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                    onKeyDown={(e) => {
                      //ENTER KEY
                      if (e.keyCode == 13) {
                        var ctrl = document.getElementById("qty");
                        if (ctrl) {
                          ctrl.focus();
                          ctrl.select();
                        }
                      }
                    }}
                  />
                </Col>
              </Row>
              <Row className="py-1">
                <Col className="col-md-4">
                  <h2>Qty:</h2>
                </Col>

                <Col className="col-md-8">
                  <Stack direction="horizontal">
                    <Form.Control
                      type="text"
                      size="lg"
                      id="qty"
                      name="qty"
                      value={this.state.qty}
                      required
                      placeholder=""
                      autoComplete="new-password"
                      onChange={(e) => this.handleInputChange(e)}
                      style={{ fontSize: "26px" }}
                      onKeyDown={(e) => {
                        //ENTER KEY
                        if (e.keyCode == 13) {
                          this.handleSubmit(e);
                        }
                      }}
                    />
                  </Stack>
                </Col>
              </Row>
              <Row className="py-1">
                <Col className="col-md-4">
                  <h2>Selling Price:</h2>
                </Col>
                <Col className="col-md-8">
                  <h2>
                    {Math.round(
                      this.state.price * this.state.qty -
                        (this.state.price *
                          this.state.qty *
                          this.state.discount) /
                          100,
                      0
                    )}
                  </h2>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  // type="submit"
                  disabled={
                    !(
                      Number(this.state.qty) > 0 &&
                      Number(
                        this.state.price > 0 && this.state.productName != ""
                      )
                    )
                  }
                  onClick={this.handleSubmit}
                >
                  {/* <Button variant="primary" size="lg" onClick={this.handleSubmit}> */}
                  Add
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

class OrderType extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orderTypeOrderDelivered: true,
      orderTypeTakeAway: false,
      orderTypeHomeDelivery: false,
    };
  }
  handleChangeOrderType = (event) => {
    this.setState({ orderTypeOrderDelivered: false });
    this.setState({ orderTypeTakeAway: false });

    this.setState({ orderTypeHomeDelivery: false });
    switch (event.target.id) {
      case "InStore":
        this.setState({ orderTypeOrderDelivered: true });
        this.context.SetBaseMode(this.context.selectedPOS);
        break;
      case "TakeAway":
        this.setState({ orderTypeTakeAway: true });
        this.context.SetTakeAwayMode(this.context.selectedPOS);
        break;
      case "HomeDelivery":
        this.setState({ orderTypeHomeDelivery: true });
        this.context.SetHomeDeliveryMode(this.context.selectedPOS);
        break;
    }
    this.props.handleChangeOrderType(event.target.id);
  };
  componentDidMount = () => {
    if (
      this.context.GetSelectedPosOrderType(this.context.selectedPOS) ==
      "InStore"
    ) {
      this.setState({ orderTypeOrderDelivered: true });
      this.setState({ orderTypeTakeAway: false });
      this.setState({ orderTypeHomeDelivery: false });
      // this.props.handleChangeOrderType("InStore");
    } else if (
      this.context.GetSelectedPosOrderType(this.context.selectedPOS) ==
      "TakeAway"
    ) {
      this.setState({ orderTypeOrderDelivered: false });
      this.setState({ orderTypeTakeAway: true });
      this.setState({ orderTypeHomeDelivery: false });
      // this.props.handleChangeOrderType("TakeAway");
    } else if (
      this.context.GetSelectedPosOrderType(this.context.selectedPOS) ==
      "HomeDelivery"
    ) {
      this.setState({ orderTypeOrderDelivered: false });
      this.setState({ orderTypeTakeAway: false });
      this.setState({ orderTypeHomeDelivery: true });
      // this.props.handleChangeOrderType("HomeDelivery");
    }
  };

  render() {
    return (
      <>
        <div className="p-0 ">
          {/* <h4>
            <b>ORDER TYPE</b>
          </h4> */}
          {window.innerWidth > 1100 ? (
            <>
              <table className="w-100 border ">
                <tr
                  className="w-100 border-0  py-5"
                  // style={{ backgroundColor: "#ECC82B", color: "black" }}
                  //style={{ backgroundColor: "#6EC531", color: "white" }}
                  style={{ backgroundColor: "#1CA350", color: "white" }}
                  //style={{ backgroundColor: "black", color: "white" }}
                >
                  <td className="col-md-4 py-2">
                    <Form.Check
                      className=""
                      type="radio"
                      checked={this.state.orderTypeOrderDelivered}
                      name="deliverytype"
                      label={
                        this.context.store.businessTypeId == 3
                          ? "KOT / DineIn"
                          : "InStore"
                      }
                      id="InStore"
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                  <td className="col-md-4">
                    <Form.Check
                      className=""
                      type="radio"
                      checked={this.state.orderTypeTakeAway}
                      disabled={
                        this.context.storeSettings.hideTakeAwayOptionOnPos
                      }
                      name="deliverytype"
                      label="Take-Away"
                      id="TakeAway"
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                  <td className="col-md-4">
                    <Form.Check
                      className=""
                      type="radio"
                      checked={this.state.orderTypeHomeDelivery}
                      name="deliverytype"
                      disabled={
                        this.context.storeSettings.hideHomeDeliveryOptionOnPos
                      }
                      label={"Home Delivery"}
                      id="HomeDelivery"
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                </tr>
              </table>
            </>
          ) : (
            <>
              <table className="w-100 border ">
                <tr className="w-100 bg-dark text-white">
                  <td width="25%" className=" text-cente">
                    <Form.Check
                      className="justify-content-center"
                      type="radio"
                      checked={this.state.orderTypeOrderDelivered}
                      name="deliverytype"
                      label={
                        this.context.store.businessTypeId == 3
                          ? "KOT"
                          : "InStore"
                      }
                      id="InStore"
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                  <td width="33%" className=" justify-content-center">
                    <Form.Check
                      className=""
                      type="radio"
                      checked={this.state.orderTypeTakeAway}
                      name="deliverytype"
                      label="Take-Away"
                      id="TakeAway"
                      disabled={
                        this.context.storeSettings.hideTakeAwayOptionOnPos
                      }
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                  <td width="41%" className=" justify-content-center">
                    <Form.Check
                      className=""
                      type="radio"
                      checked={this.state.orderTypeHomeDelivery}
                      name="deliverytype"
                      label={"Home Delivery"}
                      disabled={
                        this.context.storeSettings.hideHomeDeliveryOptionOnPos
                      }
                      id="HomeDelivery"
                      onChange={this.handleChangeOrderType}
                      style={{ fontSize: "16px", fontWeight: "bold" }}
                    ></Form.Check>
                  </td>
                </tr>
              </table>
            </>
          )}
        </div>
      </>
    );
  }
}

class BottomSummary extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          className="fixed-bottom border-0 border-primary m-0 p-0"
          style={{
            backgroundColor: this.context.themeBorderColor,
          }}
        >
          {window.innerWidth > 1100 ? (
            <>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-0  border border-secondary"
                style={{ fontFamily: "Tahoma", color: "navy" }}
              >
                <Navbar.Collapse className="justify-content-start m-0 p-0 border-0 w-100">
                  <Row className="w-100">
                    <Col sm={8}>
                      <Nav className="border-0 m-0 p-0 w-100">
                        <OrderSummaryPOS
                          myCart={this.context.getPOSCartByName(
                            this.context.selectedPOS
                          )}
                          StoreCouponDetails={this.props.StoreCouponDetails}
                        ></OrderSummaryPOS>
                      </Nav>
                    </Col>
                    <Col sm={4}>
                      <Nav className="border-0 m-0 p-0 w-100">
                        <CheckoutPOS
                          StoreCouponDetails={this.props.StoreCouponDetails}
                          coupon={this.props.coupon}
                          quickPayEventKey={this.props.quickPayEventKey}
                          quickPayEventSent={this.props.quickPayEventSent}
                          SetQuickPayEventFalse={
                            this.props.SetQuickPayEventFalse
                          }
                          orderTypeOrderDelivered={
                            this.props.orderTypeOrderDelivered
                          }
                          orderTypeTakeAway={this.props.orderTypeTakeAway}
                          orderTypeHomeDelivery={
                            this.props.orderTypeHomeDelivery
                          }
                        ></CheckoutPOS>
                      </Nav>
                    </Col>
                  </Row>
                </Navbar.Collapse>
              </Navbar>
            </>
          ) : (
            <>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-0  border border-secondary"
                style={{ fontFamily: "Tahoma", color: "navy" }}
              >
                <Navbar.Collapse className="justify-content-start m-0 p-0 border-0">
                  <Nav className="border-0 m-0 p-0 w-100">
                    <OrderSummaryPOS
                      myCart={this.context.getPOSCartByName(
                        this.context.selectedPOS
                      )}
                      StoreCouponDetails={this.props.StoreCouponDetails}
                    ></OrderSummaryPOS>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <Navbar
                className="position-sticky fixed-bottom m-0 p-2 border border-secondary"
                style={{ fontFamily: "Tahoma", color: "white" }}
              >
                <Navbar.Collapse className="justify-content-start m-0 p-0 ">
                  <Nav className="border-0 m-0 p-0 w-100">
                    <CheckoutPOS
                      // StoreCouponDetails={this.StoreCouponDetails}
                      StoreCouponDetails={this.props.StoreCouponDetails}
                      coupon={this.props.coupon}
                      quickPayEventSent={this.props.quickPayEventSent}
                      SetQuickPayEventFalse={this.props.SetQuickPayEventFalse}
                      orderTypeOrderDelivered={
                        this.props.orderTypeOrderDelivered
                      }
                      orderTypeTakeAway={this.props.orderTypeTakeAway}
                      orderTypeHomeDelivery={this.props.orderTypeHomeDelivery}
                    ></CheckoutPOS>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </>
          )}
        </div>
      </>
    );
  }
}
