import React, { Component } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import { FiRefreshCcw } from "react-icons/fi";

import { Card, Button, Table, Form, Row, Col } from "react-bootstrap";
import {
  ControlledMenu,
  useHover,
  useMenuState,
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";

import Fuse from "fuse.js";
import { Stack, FormControl } from "react-bootstrap";
import { ProductsList } from "./ProductsList";

const WAIT_INTERVAL = 500;

export default class TableOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showProductListByCategory: false,
      updateCartView: "true",
      havePermissions: false,
      ordersDashboard: [],
      selectedTableId: "",
      orderStatusFilterId: "",
      filteredTableName: "",
    };
  }

  GetRestaurantTableOrderedItemsDashboard = (tableId) => {
    this.setState({ ordersDashboard: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "Consumers/GetRestaurantTableOrderedItemsDashboard/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ ordersDashboard: data });
          }
        });
    });
  };

  LoadTableData = (e, tableData) => {
    this.context.SetSelectedTable(tableData);
    this.setState({ selectedTableId: tableData.id });
  };

  componentDidMount = () => {
    if (this.context.storeId != "") {
      this.context.SetModeOfSale("TableOrder");
      this.context.GetRestaturantTables();
      this.GetRestaurantTableOrderedItemsDashboard();
    }
  };

  OnOrderStatusFilterChanged = (e) => {
    this.setState({ orderStatusFilterId: e.target.value });
  };
  OnfilteredTableNameChanged = (e) => {
    this.setState({ filteredTableName: e.target.value });
  };

  componentDidUpdate = () => {};

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.selectedTableId != "") return <Navigate to="/TableOrder" />;
    return (
      <>
        {window.innerWidth > 1100 ? (
          <>
            <div className="Container-fluid m-0 p-0 ">
              <div className="m-1 p-1">
                <div
                  className=" m-0 p-0 text-center"
                  style={{ width: "100%", fontSize: "16pt" }}
                >
                  <div className="row mt-1 m-0 p-0 border-0 border-dark">
                    <div
                      className="border border-dark m-0 p-0"
                      style={{ width: "30%", fontSize: "12px" }}
                    >
                      <div
                        className="m-0 p-0 border"
                        style={{
                          backgroundColor: this.context.themeBorderColor,
                          color: "white",
                        }}
                      >
                        <h4>
                          <b>Table Orders - Priority Queue</b>
                        </h4>
                      </div>
                      <div className="p-2">
                        <Row>
                          <Col className="text-center">
                            Filter by Table
                            <Form.Select
                              value={this.state.filteredTableName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredTableNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.returantTables.map((sta) => (
                                <option value={sta.name}> {sta.name}</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col>
                            Filter by Status
                            <Form.Select
                              value={this.state.orderStatusFilterId}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusFilterChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="fixedTableHightOrderQuque"
                        style={{ height: window.innerHeight - 150 + "px" }}
                      >
                        <Table className="border ">
                          <thead>
                            <th width="10%"></th>
                            <th width="10%">Table</th>
                            <th width="40%">Prod.Name</th>
                            <th width="20%">Qty</th>
                            <th width="20%">Status</th>
                          </thead>
                          <tbody>
                            {this.state.ordersDashboard
                              .filter((f) => {
                                if (
                                  this.state.filteredTableName != "" &&
                                  f.name == this.state.filteredTableName
                                )
                                  return true;
                                if (this.state.filteredTableName == "")
                                  return true;
                              })
                              .filter((f) => {
                                if (
                                  this.state.orderStatusFilterId != "" &&
                                  f.orderStatusId ==
                                    this.state.orderStatusFilterId
                                ) {
                                  return true;
                                }
                                if (this.state.orderStatusFilterId == "")
                                  return true;
                              })
                              .sort((a, b) =>
                                new Date(a.created) > new Date(b.created)
                                  ? 1
                                  : -1
                              )
                              .map((order, i) => (
                                <>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{order.name}</td>
                                    <td>{order.productName}</td>
                                    <td>{order.qty}</td>
                                    <td>
                                      {
                                        this.context.GetRestaurantTableOrdereStatusById(
                                          order.orderStatusId
                                        ).orderStatus
                                      }
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    <TableDashboard
                      LoadTableData={this.LoadTableData}
                    ></TableDashboard>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="Container-fluid m-0 p-0 ">
              <div className="m-1 p-0">
                <div
                  className=" m-0 p-0 text-center"
                  style={{ width: "100%", fontSize: "16pt" }}
                >
                  <div className="text-center">
                    <div
                      className="row  text-center justify-content-center align-content-start m-0 p-0"
                      style={{ width: "100%", backgroundColor: "#1799E7" }}
                    >
                      <Row>
                        <Col xs={10}>
                          <h2
                            className="text-center border-0 m-0 p-0 text-white my-2"
                            style={{ height: "25pt" }}
                          >
                            <b>Tables - Dashboard</b>
                          </h2>
                        </Col>
                        <Col xs={2}>
                          <Button
                            className="CornersRounded"
                            onClick={(e) => this.context.GetRestaturantTables()}
                          >
                            <FiRefreshCcw size={22}></FiRefreshCcw>{" "}
                          </Button>
                        </Col>
                      </Row>
                      {this.context.returantTables
                        .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                        .map((rtable) => (
                          <>
                            <Card
                              style={{
                                width: "8.5rem",
                                height: "7.5rem",
                                cursor: "pointer",
                              }}
                              className={
                                rtable.totalOrderedItems == 0
                                  ? "bg-white text-black mx-1 p-0 mb-2 text-center "
                                  : "bg-warning text-black mx-1 p-0 mb-2 text-center"
                              }
                            >
                              <Card.Body
                                className="m-0 p-0 text-center"
                                onClick={(e) => this.LoadTableData(e, rtable)}
                              >
                                <Card.Title className="m-0 p-0 ">
                                  <h4>
                                    <b>{rtable.name}</b>{" "}
                                    <span className="text-muted">
                                      <small>[{rtable.noSeats} Seat ]</small>
                                    </span>
                                  </h4>
                                </Card.Title>
                                <Card.Subtitle className="my-1">
                                  Waiter: <b>{rtable.waiterName}</b>
                                </Card.Subtitle>
                                <Card.Text
                                  className="text-start"
                                  style={{ fontSize: "12px" }}
                                >
                                  <table
                                    className="border w-100 text-center"
                                    size="sm"
                                  >
                                    <tbody className="border">
                                      <tr>
                                        <td className="border">O.Items</td>
                                        <td className="border">
                                          {rtable.totalOrderedItems}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td className="border">T.Sale</td>
                                        <td className="border">
                                          ₹ {Math.round(rtable.totalSales, 0)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </>
                        ))}
                    </div>
                    <br></br>
                    <div
                      className="row border border-dark m-0 p-0 "
                      style={{ width: "100%", fontSize: "12px" }}
                    >
                      <div
                        className=" m-0 p-0"
                        style={{ backgroundColor: "yellow", color: "black" }}
                      >
                        <h4>
                          <b>Table Orders - Priority Queue</b>
                        </h4>
                      </div>
                      <div className="p-2">
                        <Row>
                          <Col className="text-center">
                            Filter by Table
                            <Form.Select
                              value={this.state.filteredTableName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredTableNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.returantTables.map((sta) => (
                                <option value={sta.name}> {sta.name}</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col>
                            Filter by Status
                            <Form.Select
                              value={this.state.orderStatusFilterId}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusFilterChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )}
                            </Form.Select>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className="fixedTableHightOrderQuque"
                        style={{ height: window.innerHeight - 150 + "px" }}
                      >
                        <Table className="border ">
                          <thead>
                            <th width="10%"></th>
                            <th width="10%">Table</th>
                            <th width="40%">Prod.Name</th>
                            <th width="20%">Qty</th>
                            <th width="20%">Status</th>
                          </thead>
                          <tbody>
                            {this.state.ordersDashboard
                              .filter((f) => {
                                if (
                                  this.state.filteredTableName != "" &&
                                  f.name == this.state.filteredTableName
                                )
                                  return true;
                                if (this.state.filteredTableName == "")
                                  return true;
                              })
                              .filter((f) => {
                                if (
                                  this.state.orderStatusFilterId != "" &&
                                  f.orderStatusId ==
                                    this.state.orderStatusFilterId
                                ) {
                                  return true;
                                }
                                if (this.state.orderStatusFilterId == "")
                                  return true;
                              })
                              .sort((a, b) =>
                                new Date(a.created) > new Date(b.created)
                                  ? 1
                                  : -1
                              )
                              .map((order, i) => (
                                <>
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{order.name}</td>
                                    <td>{order.productName}</td>
                                    <td>{order.qty}</td>
                                    <td>
                                      {
                                        this.context.GetRestaurantTableOrdereStatusById(
                                          order.orderStatusId
                                        ).orderStatus
                                      }
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}

export class TableDashboard extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div
          className="row text-start justify-content-center align-content-start m-0 p-0 "
          style={{
            width: "70%",
            backgroundColor: this.context.themeBorderColor,
            color: "white",
          }}
        >
          <Row>
            <Col sm={10}>
              <h2
                className="text-center border-0 m-0 p-0 text-white my-2"
                style={{ height: "25pt" }}
              >
                <b>Tables - Dashboard</b>
              </h2>
            </Col>
            <Col sm={2}>
              <Button
                size="sm"
                variant="success"
                className="CornersRounded"
                onClick={
                  (e) => this.context.GetRestaturantTables()
                  // this.GetRestaurantTableOrderedItemsDashboard(
                  //   this.state.selectedTableId
                  // )
                }
              >
                &nbsp;&nbsp;<FiRefreshCcw></FiRefreshCcw> &nbsp;&nbsp;
                Refresh&nbsp;&nbsp;
              </Button>
            </Col>
          </Row>
          {this.context.returantTables
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((rtable) => (
              <>
                <Card
                  style={{
                    width: "14rem",
                    height: "10rem",
                    cursor: "pointer",
                    backgroundColor:
                      rtable.totalOrderedItems == 0 ? "white" : "yellow ",
                  }}
                  className="text-black  mx-3 p-0 mb-5 text-center CornersRounded btn3d-restauranttables"
                >
                  <Card.Body
                    onClick={(e) => this.props.LoadTableData(e, rtable)}
                  >
                    <Card.Title className="m-0 p-0 ">
                      <h4>
                        <b>{rtable.name}</b>{" "}
                        <span className="text-muted">
                          <small>[{rtable.noSeats} Seater ]</small>
                        </span>
                      </h4>
                    </Card.Title>
                    <Card.Subtitle className="my-1">
                      Waiter: <b>{rtable.waiterName}</b>
                    </Card.Subtitle>
                    <Card.Text
                      className="text-start"
                      style={{ fontSize: "12px" }}
                    >
                      <table className="border w-100 text-center" size="sm">
                        <tbody className="border">
                          <tr>
                            <td className="border">Ord.Items</td>
                            <td className="border">
                              {rtable.totalOrderedItems}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Tot.Sale</td>
                            <td className="border">
                              ₹ {Math.round(rtable.totalSales, 0)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
            ))}
        </div>
      </>
    );
  }
}

export class TableDashboard1 extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <>
        <div
          className="row text-start justify-content-center align-content-start m-0 p-0 "
          style={{
            width: "100%",
            backgroundColor: this.context.themeBorderColor,
            color: "white",
          }}
        >
          {this.context.returantTables
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((rtable) => (
              <>
                <Card
                  style={{
                    width: "4rem",
                    height: "4rem",
                    cursor: "pointer",
                    backgroundColor:
                      rtable.totalOrderedItems == 0 ? "white" : "yellow ",
                  }}
                  className="text-black  mx-1 p-0 mb-2 text-center "
                >
                  <Card.Body
                    onClick={(e) => this.props.LoadTableData(e, rtable)}
                  >
                    <Card.Title className="m-0 p-0 ">
                      <h6>
                        <b>{rtable.name}</b>{" "}
                        <span className="text-muted">
                          <small>[{rtable.noSeats}]</small>
                        </span>
                      </h6>
                    </Card.Title>
                  </Card.Body>
                </Card>
              </>
            ))}
        </div>
      </>
    );
  }
}
