import React, { useState, useContext, Component, createRef } from "react";
import { Container, Row, Button, Col, Nav, Table, Form } from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";

export default class CompleteOrderViewTable extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      invoiceHtml: "",
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.context.SetHideMainNavBar(true);
    this.GetPosInvoiceHtml();
  }
  componentWillUnmount() {
    this.context.DeleteRestaurantTableOrder(this.context.selectedTable.id);
    this.context.SetHideMainNavBar(false);
  }

  onclickCheckCheckoutLink = () => {
    this.context.UpdateTableOrderStatus("");
  };
  print = () => {
    window.print();
  };

  GetPosInvoiceHtml = () => {
    var res = axios
      .post(
        process.env.REACT_APP_API + "Consumers/GetPosInvoiceHtml/",
        this.context.tableOrderData
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          this.setState({ invoiceHtml: response.data });
        } else {
        }
      })
      .catch((error) => {
        console.error("GetInvoiceHtml - There was an error! ", error);
      });
  };

  PrintTable = () => {
    var printWindow = window.open("", "", "height=500,width=1000");
    printWindow.document.write(this.state.invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  onPrintFinished = (status, printWindow) => {
    //printWindow.close();
  };

  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.context.tableOrderStatus === "Dismissed") {
      return (
        <>
          <div className="text-start mx-5">
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Home"
              onClick={(e) => this.onclickCheckCheckoutLink()}
            >
              <h5>
                &#60;&#60; Order dismissed. Go back to CheckOut page and try
                again!
              </h5>
            </Button>
            <br></br>
            {/* <h2 className="text-danger">
              <span className="blink_me">
                Payment dismissed. Please try again &nbsp;&nbsp;
              </span>
              <Button size="lg" onClick={(e) => this.CallRazorPay()}>
                <b>&nbsp;&nbsp;Pay&nbsp;&nbsp;</b>
              </Button>
            </h2> */}
          </div>
        </>
      );
    }
    if (this.context.tableOrderStatus === "Failed") {
      return (
        <>
          <div className="text-start mx-5">
            <br></br>
            <Button
              as={Link}
              className="text-primary bg-white text-center border-0"
              to="/Home"
            >
              <h5>&#60;&#60; Go back to CheckOut page</h5>
            </Button>
            <br></br>
            <h2 className="text-danger">
              <span className="blink_me">
                Order Failed. Please try again! &nbsp;&nbsp;
              </span>
            </h2>
          </div>
        </>
      );
    }

    if (this.context.tableOrderStatus === "Success") {
      return (
        <>
          <Button
            as={Link}
            className="text-white bg-Primary text-center border-0"
            to="/TableOrders"
          >
            <h5>&#60;&#60; Home</h5>
          </Button>
          <br></br>
          <Row className="border bg-light text-center">
            <Col className="border bg-secondary text-center text-white">
              <span>
                <h1>
                  <b>BILL</b>
                </h1>
              </span>
            </Col>
          </Row>
          <Row className="text-center">
            <div>
              {this.context.storeSettings.storeName},{" "}
              {this.context.shippingDetails.Address},{" "}
              {this.context.shippingDetails.city},
              {this.context.shippingDetails.state},
              {this.context.shippingDetails.country},
              {this.context.shippingDetails.Pincode}
            </div>
            <div>GSTIN: {this.context.storeSettings.gstNumber}</div>
          </Row>
          <Row className="border bg-light ">
            <Col className="text-center text-success">
              <h3>
                Order No: <b>{this.context.tableOrderData.orderId}</b>
              </h3>
            </Col>
            <Col className="text-center text-dark">
              {/* <h3>{this.context.storeSettings.storeName}</h3> */}
              <h5>
                {" "}
                Date:
                {new Date().toLocaleString(
                  this.context.storeSettings.defaultLocale
                )}
              </h5>
            </Col>
          </Row>

          <Row className="my-2">
            <Col className="text-center w-100">
              <Button onClick={(e) => this.PrintTable()}>
                <h5>Print</h5>
              </Button>
            </Col>
          </Row>

          <div className="m-0 p-0 text-start">
            <br></br>
            <CustomerOrderSummary
              order={this.context.tableOrderData}
            ></CustomerOrderSummary>
            <br></br>
            <h2 className="text-dark text-center ">
              <b>Ordered Items</b>
            </h2>
            <CustomerOrderedItems
              orderedItems={this.context.tableOrderData.customerOrderedItems}
            ></CustomerOrderedItems>

            <CustomerDetails></CustomerDetails>
          </div>
          <div className="text-center">
            <b>Thank You. Visit Again!</b>
          </div>
        </>
      );
    } else if (this.state.tableOrderStatus === "Failed") {
      return (
        <>
          <h1> Payment Failed. Please try again</h1>
        </>
      );
    }
  }
}

class CustomerOrderSummary extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  GetTotalSavings = () => {
    var totsav = 0;
    this.props.order.customerOrderedItems.map((item) => {
      totsav =
        totsav +
        Math.round(
          (Number(item.mrp) * Number(item.qty) * Number(item.discount)) / 100,
          0
        );
    });
    return totsav;
  };

  componentDidMount() {}

  render() {
    return (
      <>
        {/* Order Summary */}

        <Row border-dark>
          <h2 className=" text-center text-dark">
            <b>Order Summary</b>
          </h2>
        </Row>
        <div className="m-0 p-0 border ">
          <Table className="m-0 p-0 bg-white w-100 border-0">
            <tbody>
              <Row className="py-1 w-100">
                <Col className=" col-md-6 text-center">
                  <b>
                    Subtotal [
                    {this.context.getTotalQtyInCart(
                      this.props.order.customerOrderedItems
                    )}{" "}
                    Items]:
                  </b>
                </Col>
                <Col className="col-md-6 text-center ">
                  <h5>
                    {this.props.order.subTotalAmt.toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </h5>
                </Col>
              </Row>
              {this.context.storeSettings.hideCouponCodeField == true ? (
                ""
              ) : (
                <>
                  {this.props.order.couponValue > 0 ? (
                    <>
                      <Row className="py-1 w-100">
                        <Col xs={6} className="text-center">
                          <Form.Label size="" className="">
                            <b>Coupon Code:</b>
                          </Form.Label>
                        </Col>
                        <Col xs={6} className="text-center">
                          {this.props.order.couponCode}
                        </Col>
                      </Row>
                      <Row className="py-1 w-100">
                        <Col xs={6} className=" text-center">
                          <Form.Label size="" className="">
                            <b>Coupon Code value :</b>
                          </Form.Label>
                        </Col>
                        <Col xs={6} className="text-center">
                          <Form.Label size="" className="">
                            <h5>
                              {this.props.order.couponValue.toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </h5>
                          </Form.Label>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
              {this.context.taxDetails.inclusiveOfTax == true ? (
                ""
              ) : (
                <>
                  <Row className="py-1 w-100">
                    <Col xs={6} className=" text-center">
                      <Form.Label size="" className="">
                        <b>
                          Tax&nbsp; ({this.context.taxDetails.taxPercentage}
                          %) :
                        </b>
                      </Form.Label>
                    </Col>
                    <Col xs={6} className="text-center">
                      <Form.Label size="" className="">
                        <h5>
                          {this.props.order.taxAmount.toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </h5>
                      </Form.Label>
                    </Col>
                  </Row>
                </>
              )}
              <Row className="border m-0 p-0 w-100">
                <Col xs={6} className=" text-center">
                  <h3 className="my-3"> Total :</h3>
                </Col>
                <Col xs={6} className="text-center">
                  <h3>
                    <b>
                      {Math.round(this.props.order.totalAmt, 0).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </b>
                  </h3>
                  Savings:
                  {this.props.order.totalSavings.toLocaleString(
                    this.context.storeSettings.defaultLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    }
                  )}
                </Col>
              </Row>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
class CustomerOrderedItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="mx-3 p-3 bg-light border ">
          <div className="mx-0 px-0">
            <div className="row mx-0 px-0 ">
              <div className="px-3 ">
                <Table
                  border
                  className="cartviewtableOnCheckout px-5"
                  responsive="md"
                >
                  <thead>
                    <tr className="bg-light text-center">
                      <th className=""></th>
                      <th className=""></th>
                      <th>Price</th>
                      {/* <th>Discount</th>
                      <th>Qty</th> */}
                      <th>Sub.Total</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {this.props.orderedItems.map((ordereditem) => (
                      <>
                        <tr className="text-center align-middle">
                          <td className="col-md-1">
                            <img
                              src={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/images/" +
                                ordereditem.imgFileName +
                                this.context.store.storageSasToken
                              }
                              className="cardprodimg-on-cartview mx-auto"
                              alt="..."
                            />
                          </td>
                          <td className="col-md-2 ">
                            <strong>{ordereditem.productName}</strong>
                          </td>
                          <td className="col-md-2 ">
                            Price: ₹<b>{ordereditem.mrp}</b>
                            <br />
                            Discount: <b>{ordereditem.discount}%</b>
                            <br />
                            Qty: <b>{ordereditem.qty}</b>
                          </td>
                          {/* <td className="col-md-2 ">{ordereditem.discount}</td>
                          <td className="col-md-2 ">{ordereditem.qty}</td> */}
                          <td className="col-md-2">
                            <b>
                              {Math.round(
                                ordereditem.qty *
                                  (ordereditem.mrp -
                                    (ordereditem.mrp * ordereditem.discount) /
                                      100),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                            <br />
                            Savings
                            {Math.round(
                              ordereditem.qty *
                                ((ordereditem.mrp * ordereditem.discount) /
                                  100),
                              0
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
class CustomerDetails extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div className="mx-3 px-1 border ">
          <div className="row mt-1 mx-1 px-0">
            <Container className="bg-white ">
              <Row border-dark>
                <h4 className=" text-start text-secondary">
                  <strong>
                    <u>Customer Information</u>
                  </strong>
                </h4>
                <br></br>
              </Row>
              <Row>
                <div className="col-md-6 ">
                  {/* <h5 className=" text-start text-dark">
                    <strong>Delivery address</strong>
                  </h5> */}
                  <h5>
                    {this.context.tableOrderData
                      .customerOrderDeliveryAddresses[0].firstName == ""
                      ? "point of sale"
                      : "Name: " +
                        this.context.tableOrderData
                          .customerOrderDeliveryAddresses[0].firstName}
                    {/* &nbsp;{" "}
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .lastName
                    } */}
                    &nbsp;{" "}
                    {this.context.tableOrderData
                      .customerOrderDeliveryAddresses[0].mobileNo == ""
                      ? ""
                      : "Contact No :" +
                        this.context.tableOrderData
                          .customerOrderDeliveryAddresses[0].mobileNo}
                    {/* <br></br>
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .address
                    }
                    <br></br>
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .city
                    }
                    ,{" "}
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .state
                    }
                    ,{" "}
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .country
                    }
                    {" - "}
                    {
                      this.context.tableOrderData.customerOrderDeliveryAddresses[0]
                        .pinCode
                    } */}
                  </h5>
                </div>
                {/* {this.context.userData.customerUserTypeId == 1 ? (
                  <>
                    <div className="col-md-6 ">
                      <h5 className=" text-start text-dark">
                        <strong>Billing address</strong>
                      </h5>
                      <h5>
                        {this.context.userData.firstName}
                        &nbsp; {this.context.userData.lastName}
                        <br></br>
                        PhoneNo : {this.context.userData.mobileNo}
                        <br></br>
                        {this.context.userData.address}
                        <br></br>
                        {this.context.userData.city},{" "}
                        {this.context.userData.state},{" "}
                        {this.context.userData.country}
                        {" - "}
                        {this.context.userData.pinCode}{" "}
                      </h5>
                    </div>
                  </>
                ) : (
                  ""
                )} */}
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}
