import { render } from "@testing-library/react";
import React, { Component, useContext, useState } from "react";
import { BsFillHeartFill, BsHeart, BsCart2, BsBag } from "react-icons/bs";
// import { ProductSingleProductView } from "./ProductSingleProductView";
import axios from "axios";

import {
  Form,
  Button,
  Modal,
  ListGroup,
  Badge,
  FloatingLabel,
  Row,
  Col,
  Table,
  InputGroup,
  Container as div,
} from "react-bootstrap";

import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import CartContext from "./CartContext";

export class ProductSingleProductView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      currentImageFileName: "",
      qty: 1,
      prod: "",
    };
  }

  OnImageChange = (e, i) => {
    this.setState({
      currentImageFileName: this.state.prod.productImages[i].fileName,
    });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    // this.context.addToPOSCart({
    //   prod: this.props.prod,
    //   qty: this.state.qty,
    // });
    // this.context.forceUpdateCartView();
    // this.props.onHide();

    if (this.context.modeOfSale == "TableOrder") {
      this.props.AddRestaurantTableOrderItem({
        prod: this.props.prod,
        qty: this.state.qty,
      });
      this.props.onHide();
      return;
    }
    this.context.addToPOSCart(this.context.selectedPOS, {
      prod: this.props.prod,
      qty: this.state.qty,
    });
    this.context.forceUpdateCartView();
    this.props.onHide();
  };

  handleQtyChange = (value) => {
    this.setState({ qty: value });
  };

  GetProductById = (productId) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetProductById/" +
          this.props.prod.id
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ prod: response.data });
          this.setState({
            currentImageFileName:
              response.data.productImages.length > 0
                ? response.data.productImages.filter((p) => p.seqNo === 1)[0]
                    .fileName
                : "",
          });
        }
      })
      .catch((error) => {
        console.error("GetProductById - There was an error! ", error);
      });
  };

  componentDidMount() {
    this.GetProductById(this.props.prod.id);
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xxl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          dialogClassName="xxlmodal"
        >
          <Form onSubmit={this.handleSubmit} className="">
            <Modal.Body
              className="show-grid productmodal-body"
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
                color: this.context.theme.productCardTextColor,
              }}
            >
              <Row className="m-0 p-0">
                {/* <Col xs={12} md={1} className="">
                  <ListGroup className=" p-1 w-100">
                    {this.state.prod != ""
                      ? this.state.prod.productImages
                          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                          .map((image, i) => (
                            <>
                              <ListGroup.Item>
                                <img
                                  className="card-img-top border-0 text-center align-top align-content-start "
                                  // width="100%"
                                  height="60px"
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    image.fileName +
                                    this.context.store.storageSasToken
                                  }
                                  onMouseOver={(e) => this.OnImageChange(e, i)}
                                />
                              </ListGroup.Item>
                            </>
                          ))
                      : ""}
                  </ListGroup>
                </Col> */}
                <Col xs={12} md={5} className="text-center m-0 p-0 border">
                  <div style={{}}>
                    <Form.Group controlId="Image" className="text-center ">
                      <div class="item  m-0 py-0 imgcenter">
                        {this.state.prod.discount > 0 ? (
                          <>
                            <span class="notify-badge-left">
                              {this.state.prod.discount}% off
                            </span>
                          </>
                        ) : (
                          ""
                        )}

                        {/* <span
                          class="notify-badge-right"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "black",
                          }}
                          onClick={(e) => {
                            this.context.AddToWishList(this.props.prod);
                            this.setState({ msgboxOpen: true });
                            this.setState({
                              msgboxTitle: "Information",
                            });
                            this.setState({
                              msgboxMessage: "Added to wishlist",
                            });
                          }}
                        >
                          <BsFillHeartFill
                            size="20"
                            style={{
                              backgroundColor: "transparent",
                              color:
                                this.context.theme.mainNavBarBackgroundColor,
                            }}
                          ></BsFillHeartFill>
                        </span> */}
                        <img
                          className="card-img-top border-0 text-center align-middle "
                          style={{
                            width: "100%",
                            height:
                              this.context.theme
                                .productSingleViewProductCardImageHeight + "cm",
                          }}
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.state.currentImageFileName +
                            this.context.store.storageSasToken
                          }
                        />
                      </div>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} md={6} className="p-3">
                  <div>
                    <h4 className="">
                      <b>{this.props.prod.name}</b>
                    </h4>
                    <Form.Label
                      className="text-start  "
                      style={{ width: "100%" }}
                    >
                      {this.state.prod.discount > 0 ? (
                        <>
                          <div
                            style={{
                              fontSize:
                                this.context.theme.priceFontSizeOnProductCard +
                                4 +
                                "px",
                            }}
                          >
                            <span className="">
                              {Math.round(
                                Number(
                                  this.state.prod.mrp -
                                    (this.state.prod.mrp *
                                      this.state.prod.discount) /
                                      100
                                ),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                              &nbsp;&nbsp;
                              <strike>
                                {Math.round(
                                  this.state.prod.mrp,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </strike>
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div>
                            <span className="costfont ">
                              <small
                                className=" "
                                style={{
                                  fontSize:
                                    this.context.theme
                                      .priceFontSizeOnProductCard + "px",
                                }}
                              >
                                {/* M.R.P */}
                              </small>
                              <b>
                                {Math.round(
                                  this.state.prod.mrp,
                                  0
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )}
                              </b>
                            </span>
                          </div>
                          <small>&nbsp;</small>
                        </>
                      )}
                    </Form.Label>
                  </div>

                  <div
                    className="py-3"
                    dangerouslySetInnerHTML={{
                      __html: this.state.prod.description,
                    }}
                  />
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer
              className=""
              style={{
                backgroundColor: this.context.theme.productCardBackgroundColor,
              }}
            >
              <Form.Group>
                {this.context.myCart.filter(
                  (f) => f.prod.id == this.props.prod.id
                ).length == 1 ? (
                  <>
                    <Button
                      type="submit"
                      onClick={(e) =>
                        this.context.deleteItemOnMyCart(this.props.prod.id)
                      }
                      style={{
                        backgroundColor: "#004225",
                        color: "yellow",
                      }}
                    >
                      ADDED
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      variant="primary"
                      type="submit"
                      style={{
                        backgroundColor:
                          this.context.theme.mainNavBarBackgroundColor,
                        color: this.context.theme.mainNavBarTextColor,
                      }}
                    >
                      Add to &nbsp;
                      {this.context.theme.cartIcon == "Cart" ? (
                        <>
                          <BsCart2
                            size={"20px"}
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsCart2>
                        </>
                      ) : (
                        <>
                          <BsBag
                            size={"20px"}
                            style={{
                              backgroundColor:
                                this.context.theme.mainNavBarBackgroundColor,
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsBag>
                        </>
                      )}
                    </Button>
                  </>
                )}
              </Form.Group>
              <Button variant="danger" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
