import React, { Component } from "react";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import ProductCard from "./ProductCard";
import { BsFillHeartFill } from "react-icons/bs";
import RangeSlider from "react-bootstrap-range-slider";
import { MessageBox } from "./MessageBox";
import { Button, SplitButton, Badge } from "react-bootstrap";
import { Table, Form, Row, Col } from "react-bootstrap";
import CartContext from "./CartContext";
import { useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Navigate } from "react-router-dom";
import { Stack } from "react-bootstrap";

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export class ProductsList extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      priceRangeCurrentValue: 200,
      priceRangeMinValue: 0,
      priceRangeMaxValue: 100,
      discountFilter: 0,
      title: "",
      selectedMainCategoryId: 0, //ALL
      selectedDiscount: 0,
      subCategoriesState: [],
      brandsState: [],
      prods: [],
      sorting: 1,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loading: true,
    };
    this.addToCart = this.addToCart.bind(this);
  }

  handleSortingChange = (e) => {
    this.setState({ sorting: e.target.value });
    if (e.target.value == 1) {
    } else if (e.target.value == 2) {
      this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 > b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 3) {
      this.state.prods.sort((a, b) =>
        a.mrp - (a.mrp * a.discount) / 100 < b.mrp - (b.mrp * b.discount) / 100
          ? 1
          : -1
      );
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 4) {
      this.state.prods.sort((a, b) => (a.discount > b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    } else if (e.target.value == 5) {
      this.state.prods.sort((a, b) => (a.discount < b.discount ? 1 : -1));
      this.setState({ prods: this.state.prods });
    }
  };

  setRangeValue = (value) => {
    this.setState({ priceRangeCurrentValue: value });
  };
  addToCart = (cartItem) => {
    this.props.addToCartProp(cartItem);
  };

  SetPriceRange = () => {
    var i = Math.max(...this.context.prods.map((p) => p.mrp)) + 20;
    this.setState({ priceRangeMaxValue: i });
    this.setState({ priceRangeCurrentValue: i });
  };

  ApplyFilter = () => {
    //1. Copy context prods to tempprods
    var tempprods = this.context.prods;

    //2. Main Category
    if (this.state.selectedMainCategoryId == 0) {
    } else {
      tempprods = tempprods.filter(
        (e) => e.subCategory.mainCategoryId == this.state.selectedMainCategoryId
      );
    }

    //3. Subcategory checked?

    var prodssubcategoryFiltered = [];
    var filteredSubcat = this.state.subCategoriesState.filter(
      (f) => f.checked === true
    );
    if (filteredSubcat.length != 0) {
      filteredSubcat.map((e) => {
        var filtered = tempprods.filter((tf) => tf.subCategoryId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodssubcategoryFiltered.push(fil));
        }
      });
      tempprods = prodssubcategoryFiltered;
    }

    //3. brand Filtered
    var prodbrandFiltered = [];
    var filteredBrand = this.state.brandsState.filter(
      (f) => f.checked === true
    );
    if (filteredBrand.length != 0) {
      filteredBrand.map((e) => {
        var filtered = tempprods.filter((tf) => tf.brandId == e.id);
        if (filtered.length > 0) {
          filtered.map((fil) => prodbrandFiltered.push(fil));
        }
      });
      tempprods = prodbrandFiltered;
    }

    //4. PriceRange
    tempprods = tempprods.filter(
      (e) => e.mrp <= this.state.priceRangeCurrentValue
    );

    //5. PriceDiscount
    tempprods = tempprods.filter(
      (e) => e.discount >= Number(this.state.selectedDiscount)
    );

    //final. copy tempprods to this.state.prods
    this.setState({ prods: tempprods });
  };

  onPriceRangeChange = (e) => {
    this.setState({ priceRangeCurrentValue: e.target.value }, () =>
      this.ApplyFilter()
    );
  };
  onMainCategorySelectChangeEvent = (e) => {
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      var result = [];

      //ALL
      if (this.state.selectedMainCategoryId == 0) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
            this.setState({
              subCategoriesState: this.state.subCategoriesState,
            });
          });
        });
      } else {
        this.setState({ subCategoriesState: [] }, () => {
          result = this.context.subCategories.filter(
            (f) => f.mainCategoryId == e.target.value
          );

          result.map((sub) => {
            this.state.subCategoriesState.push({
              name: sub.name,
              id: sub.id,
              checked: false,
            });
          });

          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
      this.ApplyFilter();
    });
  };

  onSubCategorySelectChangeEvent = (e, row) => {
    //update
    this.state.subCategoriesState.map((subc) =>
      subc.name === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ subCategoriesState: this.state.subCategoriesState }, () =>
      this.ApplyFilter()
    );
  };

  onBrandChange = (e, row) => {
    this.state.brandsState.map((subc) =>
      subc.brandName === e.target.name ? (subc.checked = e.target.checked) : ""
    );
    this.setState({ brandsState: this.state.brandsState }, () =>
      this.ApplyFilter()
    );
  };

  onChangeDiscount = (e) => {
    this.setState({ selectedDiscount: Number(e.target.value) }, () =>
      this.ApplyFilter()
    );
  };

  //this validation is required when same maincategory menu item is clicked sebsequently
  //TODO to be deleted: unused
  ValidateAndReloadSubcategory() {
    if (this.context.productListViewType === "MainCategory") {
      //Valiate
      var isCorrect = false;
      if (this.state.subCategoriesState.length > 0) {
        var filteredSubCat = this.context.subCategories.filter(
          (f) => f.mainCategoryId === this.context.productListViewMainCategoryId
        );

        filteredSubCat.map((e1) => {
          this.state.subCategoriesState.map((e) => {
            if (e1.name === e.name) {
              isCorrect = true;
            }
          });
        });
      }

      if (isCorrect != true) {
        this.setState({ subCategoriesState: [] }, () => {
          this.context.subCategories
            .filter(
              (f) =>
                f.mainCategoryId === this.context.productListViewMainCategoryId
            )
            .map((sub) => {
              if (
                this.state.subCategoriesState.filter((f) => f.name === sub.name)
                  .length == 0
              ) {
                this.state.subCategoriesState.push({
                  name: sub.name,
                  id: sub.id,
                  checked: false,
                });
              }
            });
          this.setState({ subCategoriesState: this.state.subCategoriesState });
        });
      }
    }
  }
  ResetFilterClick = () => {
    this.InitializeComponent();
    // if (this.context.productListViewType === "ProductGroup") {
    //   this.setState({ prods: this.context.prods }, () => {
    //     this.setState({ selectedMainCategoryId: 0 }, () => {
    //       this.setState({ subCategoriesState: [] }, () => {
    //         this.context.subCategories.map((sub) => {
    //           this.state.subCategoriesState.push({
    //             name: sub.name,
    //             id: sub.id,
    //             checked: false,
    //           });
    //           this.setState({
    //             subCategoriesState: this.state.subCategoriesState,
    //           });
    //         });
    //       });
    //     });
    //   });
    // } else if (this.context.productListViewType === "MainCategory") {
    //   this.setState({ prods: this.context.prods }, () => {
    //     this.setState({ selectedMainCategoryId: 0 }, () => {
    //       this.setState({ subCategoriesState: [] }, () => {
    //         this.context.subCategories
    //           .filter(
    //             (f) =>
    //               f.mainCategoryId ===
    //               this.context.productListViewMainCategoryId
    //           )
    //           .map((sub) => {
    //             this.state.subCategoriesState.push({
    //               name: sub.name,
    //               id: sub.id,
    //               checked: false,
    //             });
    //             this.setState({
    //               subCategoriesState: this.state.subCategoriesState,
    //             });
    //           });
    //       });
    //     });
    //   });
    // }

    // // this.state.subCategoriesState.map((e) => (e.checked = "false"));
    // // this.setState({ subCategoriesState: this.state.subCategoriesState });
    // this.SetPriceRange();
    // this.setState({ selectedDiscount: 0 });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  };

  GetBrands = () => {
    var brands = [];
    this.context.prods.map((p) => {
      if (
        brands.filter((f) => f.id == p.brandId).length == 0 &&
        p.brandId != null
      ) {
        if (this.context.brands.filter((f) => f.id == p.brandId).length == 1) {
          brands.push({
            id: p.brandId,
            brandName: this.context.brands.filter((f) => f.id == p.brandId)[0]
              .brandName,
            checked: false,
          });
        }
      }
    });
    if (brands.length > 0) {
      this.setState({ brandsState: brands });
    }
  };

  componentDidMount() {
    if (this.context.prods.length < 100) {
      this.setState({ loading: false }); // showing the app
    } else {
      this.fakeRequest().then(() => {
        this.setState({ loading: false }); // showing the app
      });
    }

    this.InitializeComponent();
  }
  componentDidUpdate() {
    if (this.context.reloadProductListView === "true") {
      this.InitializeComponent();
    }
  }

  InitializeComponent() {
    window.scrollTo(0, 0);
    this.setState({ prods: this.context.prods }, () => this.SetPriceRange());
    this.GetBrands();
    this.setState({ selectedDiscount: 0 });

    if (this.context.productListViewType === "ProductGroup") {
      this.context.subCategories.map((sub) => {
        if (
          this.state.subCategoriesState.filter((f) => f.name === sub.name)
            .length == 0
        ) {
          this.state.subCategoriesState.push({
            name: sub.name,
            id: sub.id,
            checked: false,
          });
        }
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    } else if (this.context.productListViewType === "MainCategory") {
      this.setState({ subCategoriesState: [] }, () => {
        this.context.subCategories
          .filter(
            (f) =>
              f.mainCategoryId === this.context.productListViewMainCategoryId
          )
          .map((sub) => {
            if (
              this.state.subCategoriesState.filter((f) => f.name === sub.name)
                .length == 0
            ) {
              this.state.subCategoriesState.push({
                name: sub.name,
                id: sub.id,
                checked: false,
              });
            }
          });
        this.setState({ subCategoriesState: this.state.subCategoriesState });
      });
    }
    this.context.UpdateReloadProductListView("false");
  }

  render() {
    const { loading } = this.state;

    if (this.context.storeId == "") return <Navigate to="/home" />;

    if (loading) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    return (
      <>
        <div
          className="p-0 m-0 w-100 border"
          // style={{
          //   backgroundColor: "lightblue",
          // }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}

          <Row className="m-0 p-2 w-100 bg-light">
            <Col xs={12} className=" p-0">
              <Row>
                <Col
                  className="text-center"
                  xs={6}
                  // style={{ color: this.context.theme.homepageTitlesTextColor }}
                >
                  <h5 className="text-start mx-2 p-0">
                    <b>{this.context.prodListTitle}</b>

                    {this.context.theme.hideProductCount == "false" ? (
                      <>
                        <span
                          style={{
                            textTransform: "lowercase",
                          }}
                        >
                          {" "}
                          - [{this.state.prods.length} Products]
                        </span>
                      </>
                    ) : (
                      ""
                    )}
                  </h5>
                </Col>
                <Col className="text-success text-center " xs={6}>
                  <Stack direction="horizontal">
                    <b>SortBy:</b>
                    <Form.Select
                      value={this.state.sorting}
                      size="sm"
                      className="fw-bold"
                      onChange={this.handleSortingChange}
                    >
                      <option value={1}>Popularity</option>
                      <option value={2}>Price - Low To High</option>
                      <option value={3}>Price - High to Low</option>
                      <option value={4}>Discounts - Low to High</option>
                      <option value={5}>Discounts - High to Low</option>
                    </Form.Select>
                  </Stack>
                </Col>
              </Row>
              <div className="row mt-2 mx-1 px-0 ">
                <div className="col-md-12 ">
                  <div className="row justify-content-around py-2">
                    {this.state.prods.map((prod) => (
                      <>
                        <div
                          className="card p-0 mb-2 m-0 "
                          style={{
                            width:
                              this.props.productView == "grid"
                                ? "3.5cm"
                                : "100%",
                          }}
                        >
                          <div className="p-0 m-0">
                            <ProductCard
                              prod={prod}
                              AddRestaurantTableOrderItem={
                                this.props.AddRestaurantTableOrderItem
                              }
                              productView={this.props.productView}
                            ></ProductCard>
                          </div>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export default function HoverControlledSplitButton(props) {
  const { theme } = useContext(CartContext);
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  return (
    <SplitButton
      {...props}
      style={{
        backgroundColor: theme.mainNavBarBackgroundColor,
        color: theme.mainNavBarTextColor,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      // onTouchStart={() => setIsHovered(true)}
      // onTouchEnd={() => setIsHovered(false)}
      onToggle={() => setIsClicked(!isClicked)}
      show={isClicked || isHovered}
      // className=" fw-bold text-start justify-content-start"
      // style={{
      //   justifyContent: "flex-start",
      //   textAlign: "left",
      //   float: "left",
      //   width: "200pt",
      // }}
    />
  );
}

export class ProductListViewByProductGroup extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsList></ProductsList>
      </>
    );
  }
}
export class ProductListViewByMainCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsList></ProductsList>
      </>
    );
  }
}
export class ProductListViewBySubCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ProductsList></ProductsList>
      </>
    );
  }
}
