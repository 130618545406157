import { Component, useContext, useState } from "react";
import {
  Button,
  Table,
  Tooltip,
  OverlayTrigger,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import CartContext from "./CartContext";
import IncDecCounter from "./IncDecCounterControl";
import CartView from "./CartView";

export default class CartOffCanvas extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { value: "", show: true };
  }
  forceUpdate = () => {
    this.setState({ value: 0 });
    return () => this.setState({ value: this.state.value + 1 }); // update the state to force render
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.props.onCloseCartOffCanvas();
  };
  handleMenuOffCanvasShow = (e) => {
    this.setState({ show: true });
  };

  render() {
    return (
      <>
        <Offcanvas
          show={this.state.show}
          onHide={this.handleMenuOffCanvasClose}
          placement="end"
          className="h-auto w-75"
        >
          <Offcanvas.Header
            closeButton
            // className="bg-white text-black"
            style={{
              backgroundColor: this.context.theme.mainNavBarBackgroundColor,
              color: this.context.theme.mainNavBarTextColor,
            }}
          >
            <Offcanvas.Title>
              <h4 className="mx-5 producttitlefontandsizeDesktop ">
                <b>My Cart</b>
                <img
                  src="./images/smiley1.png"
                  className="mx-2"
                  alt="..."
                  width="30"
                  height="30"
                />{" "}
              </h4>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body className="bg-light text-dark">
            <CartView
              CloseCartOffCanvas={this.handleMenuOffCanvasClose}
            ></CartView>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
