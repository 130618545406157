import React, {
  Component,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import Select from "react-select";
import { MenuList } from "./SearchViewPOS.js";
import CartContext from "./CartContext";
import BarcodeReader from "react-barcode-reader";
import ProductCard from "./ProductCard.js";
import "../CSS/3d.css";
import { WeightVariantProducModal } from "./SearchViewPOS.js";
import Swal from "sweetalert2";
import { FaRegStar } from "react-icons/fa6";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import { Button, Table } from "react-bootstrap";
import axios from "axios";
import { ProductsListMobile } from "./ProductsListMobile.js";

import {
  ControlledMenu,
  useHover,
  MenuItem,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import Fuse from "fuse.js";
import { Stack, Row, Col, Modal, Form, FormControl } from "react-bootstrap";
import { ProductsList } from "./ProductsList";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

const WAIT_INTERVAL = 500;

export default class SearchViewTO extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      weightdefault: "",
      show: false,
      searchData: [],
      searchquery: "",
      searchsku: "",
      searchbuttoncolor: "white",
      loadSpinnerOpen: false,
      showProductListByCategory: false,
      voicetxt: "",
      prodsSelect: [],
      prodSelectRef: "",
      autoScan: true,
      showNumberKeys: true,
      productView: "list",
    };
  }

  handleAutoScan = (event) => {
    this.setState({ autoScan: !this.state.autoScan });
    localStorage.setItem("autoScan", !this.state.autoScan);
  };
  handleShowNumberKeys = (event) => {
    this.setState({ showNumberKeys: !this.state.showNumberKeys });
    localStorage.setItem("showNumberKeys", !this.state.showNumberKeys);
  };
  handleProductView = (event) => {
    this.setState({ productView: event.target.value });
    localStorage.setItem("productView", event.target.value);
  };

  LoadProducts = () => {
    {
      var p = [];

      this.context.prodsAll
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((r) =>
          p.push({
            value: r.id,
            label: r.name,
          })
        );
      this.setState({ prodsSelect: p });
    }
  };
  OnProductSelectChange = (e, i) => {
    var result = this.context.prodsAll.filter((m) => m.id == e.value);

    if (result[0].weightVariant == true) {
      this.setState({ selectedWeightVariantProduct: result[0] });
      this.setState({ showWeightVariantProductModal: true });
    } else {
      this.props.AddRestaurantTableOrderItem({
        prod: result[0],
        qty: 1,
      });
      // var result = this.context.addToPOSCart(this.context.selectedPOS, {
      //   prod: result[0],
      //   qty: 1,
      // });
      if (e.keyCode == 4) {
        var ctrl = document.getElementById("prodSelect");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }
    }
  };

  GetBarCode = (value) => {
    this.setState({ searchsku: value }, () => {
      this.ShowSearchResultsSku("");
    });
    // this.state.prod.sku = value;
    // this.setState({ prod: this.state.prod });
    if (value != "") this.setState({ onscan: false });
  };
  OnScanner = () => {
    this.setState({ onscan: true });
  };
  OnScannerClose = () => {
    this.setState({ onscan: false });
  };

  readTextFromMicroPhone = (voicetxt) => {
    // this.setState({ searchquery: voicetxt });
    this.searchItem(voicetxt);
  };
  readBarCode = (txt) => {
    this.setState({ isBarCodeSearch: true }, () => {
      this.searchItem(txt);
    });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleMenuOffCanvasClose = () => {
    this.setState({ show: false });
    this.setState({ searchData: [] });
  };

  searchBySku = (sku) => {
    this.setState({ searchData: [] });
    this.setState({ searchquery: "" });
    this.setState({ showProductListByCategory: false });

    this.setState({ searchsku: sku }, (r) => {
      if (this.state.searchsku.length > 0) {
        clearTimeout(this.timer);
        // this.ShowLoadSpinner();
        if (this.state.autoScan == true)
          this.timer = setTimeout(this.triggerChangeSku, WAIT_INTERVAL);
      }
    });
    if (!sku) {
      this.setState({ searchData: [] });
      return;
    }
  };
  triggerChangeSku = () => {
    this.ShowSearchResultsSku("");
  };
  ShowSearchResultsSku = (searchFrom) => {
    var result = this.context.prodsAll.filter(
      (m) => m.sku == this.state.searchsku
    );
    if (result.length == 1) {
      if (result[0].weightVariant == true) {
        this.setState({ selectedWeightVariantProduct: result[0] });
        this.setState({ showWeightVariantProductModal: true });
      } else {
        // var result = this.context.addToPOSCart(this.context.selectedPOS, {
        //   prod: result[0],
        //   qty: 1,
        // });
        this.props.AddRestaurantTableOrderItem({
          prod: result[0],
          qty: 1,
        });
        if (result == false)
          Swal.fire({
            title: "Information",
            text: "This item is already in Cart!",
            timer: 2000,
          }).then((result) => {
            this.setState({ searchsku: "" });
          });
        else
          Swal.fire({
            title: "Added Successfully",
            // confirmButtonText: "yes",
            timer: 500,
          }).then((result) => {
            this.setState({ searchsku: "" });
          });
      }
    }
  };

  searchItem = (query) => {
    this.setState({ showProductListByCategory: false });
    this.setState({ searchquery: query }, (r) => {
      if (this.state.searchquery.length > 2) {
        clearTimeout(this.timer);
        this.ShowLoadSpinner();
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
      }
    });
    if (!query) {
      this.setState({ searchData: [] });
      return;
    }
  };

  triggerChange = () => {
    this.ShowSearchResults("");
  };

  ShowSearchResults = (searchFrom) => {
    const fuse = new Fuse(this.context.prodsAll, {
      keys: ["name", "sku"],

      //option-1
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      distance: 120,

      //option-2
      // findAllMatches: true,
      // threshold: 0.3, //number of words
      // location: 0,
      // distance: 120,
    });
    const result = fuse.search(this.state.searchquery);
    const finalResult = [];

    if (result.length) {
      result.forEach((item) => {
        if (result.length == 1 && item.item.sku == this.state.searchquery) {
          // this.context.addToPOSCart(this.context.selectedPOS, {
          //   prod: item.item,
          //   qty: 1,
          // });
          this.props.addToCart({
            prod: {
              id: item.item.id,
              imgFileName: item.item.imgFileName,
              name: item.item.name,
              mrp: item.item.mrp,
              discount: item.item.discount,
            },
            qty: 1,
          });

          Swal.fire({
            title: "Added Successfully",
            // confirmButtonText: "yes",
            timer: 500,
          }).then((result) => {
            this.setState({ searchquery: "" });
          });

          return;
        }
        finalResult.push(item.item);
      });
      this.setState({ searchData: finalResult.slice(0, 25) }, () => {
        this.HideLoadSpinner();
      });
    } else {
      this.setState({ searchData: [] }, () => {
        this.HideLoadSpinner();
      });
    }
  };

  isNumeric = (value) => {
    return /^-?\d+$/.test(value);
  };

  hasSearchResults = () => {
    if (this.state.searchData.length > 0) return true;

    return false;
  };
  onClear = () => {
    this.setState({ searchquery: "" });
    this.setState({ searchData: [] });
    this.setState({ showProductListByCategory: false });
  };

  SubCategoryClicked = () => {
    this.setState({ searchquery: "" }, () => {
      this.setState({ searchData: [] });
    });
    this.setState({ showProductListByCategory: true });
  };

  componentDidMount() {
    var autoscan = localStorage.getItem("autoScan");
    if (autoscan == "true") this.setState({ autoScan: true });
    else if (autoscan == "false") {
      this.setState({ autoScan: false });
    }

    var showNumberKeys = localStorage.getItem("showNumberKeys");
    if (showNumberKeys == "true") this.setState({ showNumberKeys: true });
    else if (showNumberKeys == "false") {
      this.setState({ showNumberKeys: false });
    }

    var productView = localStorage.getItem("productView");
    this.setState({ productView: productView });

    this.LoadProducts();

    document.getElementById("search").focus();

    document.addEventListener("keydown", (e) => {
      //CTRL+1
      if (e.ctrlKey && e.keyCode == 112) {
        var ctrl = document.getElementById("scanSku");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }

      //CTRL + 2
      if (e.ctrlKey && e.keyCode == 113) {
        if (this.state.prodSelectRef) {
          this.state.prodSelectRef.focus();
          return;
        }
      }

      //F2 to focus on prodSelect drop down
      if (e.key == "F2" && e.keyCode == 113) {
        var ctrl = document.getElementById("search");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      }
    });
  }

  HideWeightVariantProducModal = () => {
    this.setState({ showWeightVariantProductModal: false });
    this.setState({ searchsku: "" });
    var ctrl = document.getElementById("scanSku");
    if (ctrl) ctrl.focus();
  };

  render() {
    const colourStyles = {
      menuPortal: (base) => ({
        ...base,
        fontSize: "14px",
      }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
          ...styles,
          backgroundColor: isFocused ? "green" : null,
          color: isFocused ? "white" : "#333333",
        };
      },
    };
    return (
      <>
        <div className="mx-0 p-0 ">
          {this.state.showWeightVariantProductModal == true ? (
            <WeightVariantProducModalTable
              prod={this.state.selectedWeightVariantProduct}
              onHide={this.HideWeightVariantProducModal}
              myClientIpAddress={this.state.myClientIpAddress}
              addToCart={this.props.addToCart}
            ></WeightVariantProducModalTable>
          ) : (
            ""
          )}
          {window.innerWidth > 1100 ? (
            <>
              <div className="m-0 p-0 bg-light">
                <table className="w-100 m-0 p-0 ">
                  <tbody className="">
                    <tr className="w-100 ">
                      <td
                        className="col-md-2  m-0 p-0  "
                        style={{
                          verticalAlign: "top",
                          alignItems: "center",
                          backgroundColor: "#006F86",
                        }}
                      >
                        <div className="text-center text-white w-100">
                          <h5>Categories</h5>
                        </div>
                        <MainCategoryStrip
                          SubCategoryClicked={this.SubCategoryClicked}
                        ></MainCategoryStrip>
                      </td>
                      <td
                        className="col-md-10 m-0 p-0"
                        style={{ verticalAlign: "top", alignItems: "center" }}
                      >
                        <div className="w-100 p-0 m-0">
                          <div
                            className="text-center  w-100 my-0 p-1 "
                            style={{
                              backgroundColor: "#006F86",
                              color: "white",
                            }}
                          >
                            <table className="w-100">
                              <tr className="">
                                <td width="10%" className="text-start">
                                  <h5>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="currentColor"
                                      class="bi bi-search"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>{" "}
                                    {/* &nbsp;
                                <b>Search</b> */}
                                  </h5>
                                </td>
                                <td width="20%" className="text-end">
                                  <Stack direction="horizontal">
                                    <Form.Check
                                      className=""
                                      type="checkbox"
                                      checked={this.state.autoScan}
                                      name="autoScan"
                                      label={""}
                                      onChange={this.handleAutoScan}
                                      className="text-start"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    ></Form.Check>
                                    Auto Scan
                                  </Stack>
                                </td>
                                <td width="20%" className="text-end">
                                  <Stack direction="horizontal">
                                    <Form.Check
                                      className=""
                                      type="checkbox"
                                      checked={this.state.showNumberKeys}
                                      name="showNumberKeys"
                                      label={""}
                                      onChange={this.handleShowNumberKeys}
                                      className="text-start"
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    ></Form.Check>
                                    N-Keys
                                  </Stack>
                                </td>
                                <td width="20%" className="text-end">
                                  <Stack direction="horizontal">
                                    <Form.Select
                                      className=""
                                      type="text"
                                      value={this.state.productView}
                                      name="productView"
                                      label={""}
                                      onChange={this.handleProductView}
                                    >
                                      <option value="grid">Grid</option>
                                      <option value="list">List</option>
                                    </Form.Select>
                                  </Stack>
                                </td>
                                {this.context.hideFav == true ? (
                                  <>
                                    <td width="20%">
                                      <Button
                                        variant="warning"
                                        className=" border-0 CornersRounded text-center"
                                        onClick={(e) =>
                                          this.context.SetHideFav(
                                            !this.context.hideFav
                                          )
                                        }
                                      >
                                        &nbsp;&nbsp;
                                        {this.context.hideFav == true ? (
                                          <>
                                            <FaRegStar size={20}> </FaRegStar>
                                            &nbsp;Favorites
                                          </>
                                        ) : (
                                          <>&#8592;Home</>
                                        )}
                                        &nbsp;&nbsp;
                                      </Button>
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <td width="10%" className="text-end">
                                  <Dictaphone1
                                    sendVoiceTxt={this.readTextFromMicroPhone}
                                  ></Dictaphone1>
                                </td>
                                {/* <td width="40%" className="text-start">
                                  <h5>
                                    &nbsp;&nbsp;<b>Search</b>
                                  </h5>
                                </td>
                                {this.context.hideFav == true ? (
                                  <>
                                    <td>
                                      <Button
                                        variant="warning"
                                        size="sm"
                                        className=" border-0"
                                        onClick={(e) =>
                                          this.context.SetHideFav(
                                            !this.context.hideFav
                                          )
                                        }
                                      >
                                        {this.context.hideFav == true ? (
                                          <>Show Fav</>
                                        ) : (
                                          <>HideMe</>
                                        )}
                                      </Button>
                                    </td>
                                  </>
                                ) : (
                                  <></>
                                )}
                                <td width="40%" className="text-end">
                                  <Dictaphone1
                                    sendVoiceTxt={this.readTextFromMicroPhone}
                                  ></Dictaphone1>
                                </td> */}
                              </tr>
                            </table>
                          </div>
                          <Table className="w-100 my-1 ">
                            <tr className="w-100 m-0 p-0 ">
                              <td
                                className="col-md-12  m-0 p-0 "
                                style={{
                                  verticalAlign: "top",
                                  alignItems: "center",
                                }}
                              >
                                <tr>
                                  <td width="44%">
                                    <FormControl
                                      id="scanSku"
                                      // type="search"
                                      type="number"
                                      // size="lg"
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: "20px",
                                      }}
                                      placeholder="SKU (Ctrl+F1)"
                                      className="text-dark"
                                      width="100%"
                                      aria-label="Search"
                                      autoComplete="new-password"
                                      value={this.state.searchsku}
                                      onChange={(e) =>
                                        this.searchBySku(e.target.value)
                                      }
                                      onKeyDown={(e) => {
                                        if (e.keyCode == 39) {
                                          if (this.state.prodSelectRef) {
                                            this.state.prodSelectRef.focus();
                                          }
                                        }
                                        //ENTER KEY
                                        if (e.keyCode == 13) {
                                          e.stopPropagation();
                                          this.ShowSearchResultsSku("");
                                        }
                                      }}
                                    />
                                  </td>
                                  <td width="56%">
                                    <Select
                                      id="prodSelect"
                                      menuPosition="fixed"
                                      maxMenuHeight={400}
                                      components={
                                        this.state.prodsSelect.length > 1000
                                          ? { MenuList }
                                          : ""
                                      }
                                      styles={colourStyles}
                                      ref={(ref) => {
                                        this.state.prodSelectRef = ref;
                                      }}
                                      value={{
                                        value: "",
                                        label: "Select Product (Ctrl+F2)",
                                      }}
                                      onChange={(e) =>
                                        this.OnProductSelectChange(e)
                                      }
                                      options={this.state.prodsSelect}
                                      onKeyDown={(e) => {
                                        if (
                                          e.keyCode == 37 &&
                                          e.target.selectionStart == 0
                                        ) {
                                          var ctrl =
                                            document.getElementById("scanSku");
                                          if (ctrl) {
                                            ctrl.focus();
                                            ctrl.select();
                                          }
                                        }
                                      }}
                                    ></Select>
                                  </td>
                                  <td
                                    width="10%"
                                    className="verticalaligncentertd "
                                  >
                                    <Button
                                      className="rounded-circle"
                                      variant="danger"
                                      size="lg"
                                      onClick={(e) => this.onClear()}
                                    >
                                      X
                                    </Button>
                                  </td>
                                </tr>
                                {this.state.showNumberKeys == true ? (
                                  <>
                                    <Table className="p-5 ">
                                      <tr>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",
                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "1",
                                              });
                                            }}
                                          >
                                            1
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            s
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "2",
                                              });
                                            }}
                                          >
                                            2
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "3",
                                              });
                                            }}
                                          >
                                            3
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "4",
                                              });
                                            }}
                                          >
                                            4
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "5",
                                              });
                                            }}
                                          >
                                            5
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "6",
                                              });
                                            }}
                                          >
                                            6
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "7",
                                              });
                                            }}
                                          >
                                            7
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "8",
                                              });
                                            }}
                                          >
                                            8
                                          </Button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",
                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "9",
                                              });
                                            }}
                                          >
                                            9
                                          </Button>
                                        </td>
                                        <td>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku + "0",
                                              });
                                            }}
                                          >
                                            0
                                          </Button>
                                        </td>
                                        <td colSpan={2}>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",

                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku:
                                                  this.state.searchsku.slice(
                                                    0,
                                                    -1
                                                  ),
                                              });
                                            }}
                                          >
                                            <i class="material-icons">
                                              &#xe14a;
                                            </i>
                                          </Button>
                                        </td>
                                        <td colSpan={2}>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",
                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.setState({
                                                searchsku: "",
                                              });
                                            }}
                                          >
                                            Clear
                                          </Button>
                                        </td>
                                        <td colSpan={2}>
                                          <Button
                                            className="w-100"
                                            style={{
                                              backgroundColor: "#00FFFF",
                                              color: "black",
                                              height: "50px",
                                              fontSize: "26px",
                                            }}
                                            onClick={(e) => {
                                              this.ShowSearchResultsSku("");
                                            }}
                                          >
                                            Enter
                                          </Button>
                                        </td>
                                      </tr>
                                    </Table>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                            </tr>
                          </Table>
                          <Stack
                            direction="horizontal"
                            gap={3}
                            className="w-100 py-2"
                          >
                            <b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </b>
                            <FormControl
                              id="search"
                              type="search"
                              placeholder="Search by Product Name, SKU"
                              className=" text-dark  bg-light text-black"
                              width="100%"
                              // size="sm"
                              aria-label="Search"
                              value={this.state.searchquery}
                              onChange={(e) => this.searchItem(e.target.value)}
                            />
                            <BarCodeReader
                              sendBarCode={this.readBarCode}
                            ></BarCodeReader>
                          </Stack>

                          {this.state.loadSpinnerOpen === true ? (
                            <h4 className="text-center w-100 text-danger">
                              <b>Loading...pls wait</b>
                            </h4>
                          ) : (
                            ""
                          )}

                          <Table>
                            <tr>
                              <td width="100%">
                                <div
                                  className="m-0 p-1  fixedTableHightOrderQuque"
                                  style={{
                                    minHeight: window.innerHeight - 200 + "px",
                                    maxHeight: window.innerHeight - 200 + "px",
                                  }}
                                >
                                  {this.state.showProductListByCategory ==
                                  true ? (
                                    <>
                                      <table className="w-100 m-0 p-0 ">
                                        <tbody>
                                          <tr className="w-100">
                                            <td className="text-center"></td>
                                            <td className="text-end ">
                                              <Button
                                                variant="danger"
                                                onClick={(e) => this.onClear()}
                                              >
                                                Clear
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <ProductsList
                                        productView={this.state.productView}
                                        AddRestaurantTableOrderItem={
                                          this.props.AddRestaurantTableOrderItem
                                        }
                                      ></ProductsList>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {this.hasSearchResults() ? (
                                    <>
                                      <table className="w-100 m-0 p-0">
                                        <tbody>
                                          <tr className="w-100">
                                            <td className="text-center">
                                              <h4 className="text-danger text-center">
                                                <b>
                                                  Search results of "
                                                  {this.state.searchquery}"
                                                </b>
                                              </h4>
                                            </td>
                                            <td className="text-end">
                                              <Button
                                                variant="danger"
                                                onClick={(e) => this.onClear()}
                                              >
                                                Clear
                                              </Button>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div className="row justify-content-around py-2">
                                        {this.state.searchData.map((prod) => (
                                          <div
                                            className="card mb-2 m-0 p-0"
                                            style={{
                                              width:
                                                this.state.productView == "grid"
                                                  ? "3.5cm"
                                                  : "100%",
                                            }}
                                          >
                                            <div className="m-0 p-0">
                                              <ProductCard
                                                prod={prod}
                                                productView={
                                                  this.state.productView
                                                }
                                                AddRestaurantTableOrderItem={
                                                  this.props
                                                    .AddRestaurantTableOrderItem
                                                }
                                              ></ProductCard>
                                            </div>
                                          </div>
                                        ))}
                                      </div>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                  <br></br>
                                </div>
                              </td>
                            </tr>
                          </Table>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
            </>
          ) : (
            <>
              {this.props.onOffCanvas == true ? (
                <>
                  <div className="m-0 p-0 bg-light">
                    <table className="w-100 m-0 p-0 border">
                      <tbody className="m-0 p-0 ">
                        <tr className="w-100 ">
                          <td
                            width="20%"
                            className="  m-0 p-0  "
                            style={{
                              verticalAlign: "top",
                              alignItems: "center",
                              backgroundColor: "#006F86",
                            }}
                          >
                            <MainCategoryStrip
                              SubCategoryClicked={this.SubCategoryClicked}
                            ></MainCategoryStrip>
                          </td>
                          <td
                            className=" m-0 p-0"
                            style={{
                              verticalAlign: "top",
                              alignItems: "center",
                            }}
                          >
                            <div className="w-100 p-0 m-0 border">
                              <Table className="w-100 p-0 m-0">
                                <tr>
                                  <td width="100%">
                                    <div className="m-0 p-1 ">
                                      {this.state.showProductListByCategory ==
                                      true ? (
                                        <>
                                          <ProductsListMobile
                                            productView="grid"
                                            AddRestaurantTableOrderItem={
                                              this.props
                                                .AddRestaurantTableOrderItem
                                            }
                                          ></ProductsListMobile>
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </Table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <br />
                    <br />
                    <br />
                  </div>
                </>
              ) : (
                <>
                  <div>
                    <table className="w-100 p-0 m-0 bg-light">
                      <tbody className="p-0 m-0">
                        <tr className="w-100 p-0 m-0 ">
                          <td width="10%">
                            <ShopByCategoryPOS
                              SubCategoryClicked={this.SubCategoryClicked}
                              AddRestaurantTableOrderItem={
                                this.props.AddRestaurantTableOrderItem
                              }
                            ></ShopByCategoryPOS>
                          </td>
                          <td width="25%">
                            <FormControl
                              type="number"
                              id="scanSku"
                              size="lg"
                              placeholder="SKU"
                              className="text-dark fw-bold"
                              autoComplete="new-password"
                              value={this.state.searchsku}
                              onChange={(e) => this.searchBySku(e.target.value)}
                            />
                          </td>
                          <td width="15%" className=" text-center ">
                            <div className="px-3">
                              <Form.Check
                                className="xx"
                                type="checkbox"
                                checked={this.state.showNumberKeys}
                                name="showNumberKeys"
                                label={"N"}
                                onChange={this.handleShowNumberKeys}
                                style={{ width: "30px" }}
                              ></Form.Check>
                            </div>
                          </td>
                          <td width="50%">
                            <Stack
                              direction="horizontal"
                              gap={3}
                              className="w-100"
                            >
                              <FormControl
                                id="search"
                                type="CartViewPOSsearch"
                                placeholder="Search by Product Name, SKU"
                                className=" text-dark fw-bold bg-light text-black"
                                aria-label="Search"
                                value={this.state.searchquery}
                                onChange={(e) =>
                                  this.searchItem(e.target.value)
                                }
                              />
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </b>
                              {/* <Dictaphone1
                              sendVoiceTxt={this.readTextFromMicroPhone}
                            ></Dictaphone1>
                            <BarCodeReader
                              sendBarCode={this.readBarCode}
                            ></BarCodeReader> */}
                            </Stack>
                          </td>
                        </tr>
                        <tr className="p-0 m-0">
                          <td colSpan={4}>
                            {this.state.showNumberKeys == true ? (
                              <>
                                <Table className="p-5">
                                  <tr>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",
                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "1",
                                          });
                                        }}
                                      >
                                        1
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        s
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "2",
                                          });
                                        }}
                                      >
                                        2
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "3",
                                          });
                                        }}
                                      >
                                        3
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "4",
                                          });
                                        }}
                                      >
                                        4
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "5",
                                          });
                                        }}
                                      >
                                        5
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "6",
                                          });
                                        }}
                                      >
                                        6
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "7",
                                          });
                                        }}
                                      >
                                        7
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "8",
                                          });
                                        }}
                                      >
                                        8
                                      </Button>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",
                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "9",
                                          });
                                        }}
                                      >
                                        9
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku + "0",
                                          });
                                        }}
                                      >
                                        0
                                      </Button>
                                    </td>
                                    <td colSpan={2}>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",

                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku:
                                              this.state.searchsku.slice(0, -1),
                                          });
                                        }}
                                      >
                                        <i class="material-icons">&#xe14a;</i>
                                      </Button>
                                    </td>
                                    <td colSpan={2}>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",
                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.setState({
                                            searchsku: "",
                                          });
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    </td>
                                    <td colSpan={2}>
                                      <Button
                                        className="w-100"
                                        style={{
                                          backgroundColor: "#00FFFF",
                                          color: "black",
                                          height: "50px",
                                          fontSize: "26px",
                                        }}
                                        onClick={(e) => {
                                          this.ShowSearchResultsSku("");
                                        }}
                                      >
                                        Enter
                                      </Button>
                                    </td>
                                  </tr>
                                </Table>
                              </>
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>

                    {this.state.loadSpinnerOpen === true ? (
                      <h4 className="text-center w-100 text-danger">
                        <b>Loading...pls wait</b>
                      </h4>
                    ) : (
                      ""
                    )}

                    {this.state.showProductListByCategory == true ? (
                      <>
                        <div
                          className="fixedTableHightOrderQuque"
                          style={{
                            // maxHeight: window.innerHeight / 2 + "px",
                            maxHeight: window.innerHeight - 250 + "px",
                          }}
                        >
                          <>
                            <table className="w-100 m-0 p-0 ">
                              <tbody>
                                <tr className="w-100">
                                  <td className="text-center"></td>
                                  <td className="text-end ">
                                    <Button
                                      variant="danger"
                                      onClick={(e) => this.onClear()}
                                    >
                                      Clear
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            </table>

                            <ProductsList
                              AddRestaurantTableOrderItem={
                                this.props.AddRestaurantTableOrderItem
                              }
                            ></ProductsList>
                          </>
                        </div>
                      </>
                    ) : (
                      ""
                    )}

                    {this.hasSearchResults() ? (
                      <table className="w-100 m-0 p-0">
                        <tbody>
                          <tr className="w-100">
                            <td className="text-center">
                              <h4 className="text-danger text-center">
                                <b>
                                  Search results of "{this.state.searchquery}"
                                </b>
                              </h4>
                            </td>
                            <td className="text-end">
                              <Button
                                variant="danger"
                                onClick={(e) => this.onClear()}
                              >
                                Clear
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    ) : null}

                    <div
                      className="col-md-12 fixedTableHightOrderQuque m-0 p-0"
                      style={{
                        // maxHeight: window.innerHeight / 2 + "px",
                        maxHeight: window.innerHeight - 250 + "px",
                      }}
                    >
                      <Table
                        className="border m-0 p-0"
                        style={{ tableLayout: "fixed" }}
                      >
                        <tbody>
                          {this.state.searchData.map((prod) => (
                            <tr>
                              <td className="m-0 p-0 ">
                                <div
                                  className="card mb-0 m-0 p-0 "
                                  style={{
                                    width: "100%",
                                  }}
                                >
                                  <ProductCard
                                    prod={prod}
                                    AddRestaurantTableOrderItem={
                                      this.props.AddRestaurantTableOrderItem
                                    }
                                  ></ProductCard>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

class BarCodeReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: "barcode",
    };

    this.handleScan = this.handleScan.bind(this);
  }
  handleScan(data) {
    this.setState({
      result: data,
    });
    this.props.sendBarCode(data);
  }
  handleError(err) {
    console.error(err);
  }
  render() {
    return (
      <div>
        <BarcodeReader onError={this.handleError} onScan={this.handleScan} />
        {/* <p>{this.state.result}</p> */}
      </div>
    );
  }
}
const Dictaphone = (props) => {
  const [message, setMessage] = useState("");
  const commands = [
    {
      command: "*",
      callback: (txt) => {
        console.log(txt);
        props.sendVoiceTxt(txt);
      },
      matchInterim: true,
    },
    {
      command: "clear",
      callback: (txt) => {
        props.sendVoiceTxt("");
      },
      matchInterim: true,
    },
  ];
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
  }
  SpeechRecognition = function () {
    console.info("voice recognition ended, restarting...");
    SpeechRecognition.start();
  };

  return (
    <div>
      <p>Microphone: {listening ? "on" : "off"}</p>
      <button onClick={SpeechRecognition.startListening}>Start</button>
      <button onClick={SpeechRecognition.stopListening}>Stop</button>
      {/* <p>{transcript}</p> */}
    </div>
  );
};
const Dictaphone1 = (props) => {
  const [message, setMessage] = useState("");
  const commands = [
    {
      command: "*",
      callback: (txt) => {
        console.log(txt);
        props.sendVoiceTxt(txt);
      },
      matchInterim: true,
    },
    {
      command: "clear",
      callback: (txt) => {
        props.sendVoiceTxt("");
      },
      matchInterim: true,
    },
  ];
  const {
    transcript,
    interimTranscript,
    finalTranscript,
    resetTranscript,
    listening,
  } = useSpeechRecognition({ commands });

  useEffect(() => {
    if (finalTranscript !== "") {
      console.log("Got final result:", finalTranscript);
    }
  }, [interimTranscript, finalTranscript]);
  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return null;
  }

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    console.log(
      "Your browser does not support speech recognition software! Try Chrome desktop, maybe?"
    );
  }
  const listenContinuously = () => {
    SpeechRecognition.startListening({
      continuous: true,
      language: "en-GB",
    });
  };
  return (
    <>
      {listening ? (
        <>
          <Button
            variant="warning"
            size="sm"
            onClick={SpeechRecognition.stopListening}
          >
            <BsFillMicFill size={18}></BsFillMicFill>
          </Button>
        </>
      ) : (
        <>
          <Button variant="warning" size="sm" onClick={listenContinuously}>
            <BsFillMicMuteFill size={18}></BsFillMicMuteFill>
            {/* <i class="bi bi-mic-mute-fill"></i> */}
            {/* Listen */}
          </Button>
        </>
      )}
    </>
  );
};

export const ShopByCategoryPOS = (props) => {
  const {
    theme,
    mainCategories,
    subCategories,
    GetProductsByMainCategoryId,
    GetProductsBySubCategoryId,
    UpdateReloadProductListView,
  } = useContext(CartContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const onMainCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsByMainCategoryId(event.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };

  const OnSubCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsBySubCategoryId(
      event.syntheticEvent.currentTarget.id,
      null,
      null
    );
    props.SubCategoryClicked();
  };
  return (
    <>
      <div ref={ref} {...anchorProps}>
        <Button
          className={"dropdown-toggle my-3 border-0"}
          style={{
            backgroundColor: "white",
            color: "black",
          }}
        >
          <img
            src={"/images/menu1.png"}
            className=" mx-auto"
            alt="..."
            style={{ width: ".5cm" }}
          />
        </Button>
      </div>

      <ControlledMenu
        {...hoverProps}
        state={isOpen ? "open" : "closed"}
        anchorRef={ref}
        onClose={() => setOpen(false)}
      >
        {mainCategories
          .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
          .map((maincategory) => (
            <>
              <div
                id={maincategory.id}
                onClick={(e) => onMainCategoryClickEvent(e)}
              >
                <SubMenu
                  label={maincategory.name}
                  className="my-2 fw-bold"
                  type="action"
                >
                  {subCategories
                    .filter(
                      (subcategory) =>
                        subcategory.mainCategoryId == maincategory.id
                    )
                    .map((subcategory) => (
                      <>
                        <MenuItem
                          id={subcategory.id}
                          onClick={(e) => {
                            OnSubCategoryClickEvent(e);
                          }}
                          style={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {subcategory.name}
                        </MenuItem>
                      </>
                    ))}
                </SubMenu>
              </div>
            </>
          ))}
      </ControlledMenu>
    </>
  );
};
class MainCategoryStrip extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  onMainCategoryClickEvent = (id) => {
    this.context.UpdateReloadProductListView("true");
    this.context.GetProductsByMainCategoryId(id, null, null);
    this.props.SubCategoryClicked();
  };
  render() {
    return (
      <>
        <div className="">
          {this.context.mainCategories
            .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
            .map((maincategory) => (
              <>
                <div className="m-2">
                  <Button
                    variant="light"
                    // size="lg"
                    className="my-1  w-100 text-center  card-round fw-bold"
                    onClick={(e) =>
                      this.onMainCategoryClickEvent(maincategory.id)
                    }
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "bolder",
                      fontSize: window.innerWidth > 1200 ? "14px" : "12px",
                    }}
                  >
                    {maincategory.name}
                  </Button>
                </div>
              </>
            ))}
        </div>
      </>
    );
  }
}

export class WeightVariantProducModalTable extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productName: "",
      price: "",
      discount: "",
      qty: "0",
      sellingPrice: "",
      firstload: false,
      intervalId: "",
    };
  }
  handleInputChange = (e) => {
    const { name, value } = e.target;
    //validate for minus values not to allow
    switch (name.trim()) {
      case "productName":
        this.setState({ productName: value });
        break;
      case "price":
        this.setState({ price: value });
        break;
      case "discount":
        this.setState({ discount: value });
        break;
      case "qty":
        this.setState({ qty: value });
        break;
    }
  };
  getWeight = () => {
    if (this.props.myClientIpAddress == "") return;
    console.log("start");
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetWeightByIPAddress/" +
          this.props.myClientIpAddress
        //"122.167.146.141"
      )
      .then((response) => {
        if (response.status == 200) {
          if (response.data != null && response.data != "") {
            this.setState({ qty: Number(response.data.scaleValue).toFixed(3) });
            console.log(response.data.scaleValue);
          }
        }
      });
  };
  setWeightToZero = () => {
    if (this.props.myClientIpAddress == "") return;
    var res = axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/SetWeightOfIPAddressToZero/" +
          this.props.myClientIpAddress
        //"122.167.146.141"
      )
      .then((response) => {
        if (response.status == 200) {
        }
      });
  };
  handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      return;
    }

    var result = this.context.prodsAll.filter(
      (m) => m.name == this.state.productName
    );
    if (result.length > 0) {
      this.props.addToCart({
        prod: result[0],
        qty: this.state.qty,
      });
    }

    this.setWeightToZero();

    clearInterval(this.state.intervalId);
    this.props.onHide();
  };

  RefreshWeigh = () => {
    this.state.intervalId = setInterval(() => {
      this.getWeight();
    }, 1000); //refresh once in 1mins
  };

  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      //this.setWeightToZero();
      if (this.context.isOfflineMode == false) this.RefreshWeigh();
      this.setState({ productName: this.props.prod.name });
      this.setState({ price: this.props.prod.mrp });
      this.setState({ discount: this.props.prod.discount });
      this.setState({ qty: 1 }, () => {
        var ctrl = document.getElementById("qty");
        if (ctrl) {
          ctrl.focus();
          ctrl.select();
        }
      });
    }

    //close the modal when ESC Press
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.CloseModal();
      }
    });
  };
  CloseModal = () => {
    clearInterval(this.state.intervalId);
    this.props.onHide();
  };

  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
          onHide={this.CloseModal}
        >
          <Form
          // onSubmit={this.handleSubmit}
          >
            <Modal.Header className="bg-warning" closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Product Name:</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="text"
                    size="lg"
                    name="productName"
                    id="productName"
                    value={this.state.productName}
                    required
                    disabled={true}
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Price:</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="price"
                    value={this.state.price}
                    required
                    disabled={true}
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Discount(%):</h2>
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="discount"
                    disabled={true}
                    value={this.state.discount}
                    required
                    placeholder=""
                    autoComplete="new-password"
                    onChange={(e) => this.handleInputChange(e)}
                    style={{ fontSize: "26px" }}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Quantity:</h2>
                </Form.Label>
                <Col sm="6">
                  <Stack direction="horizontal">
                    <Form.Control
                      type="number"
                      size="lg"
                      name="qty"
                      id="qty"
                      value={this.state.qty}
                      required
                      placeholder=""
                      autoComplete="new-password"
                      onChange={(e) => this.handleInputChange(e)}
                      style={{ fontSize: "26px" }}
                      onKeyDown={(e) => {
                        //ENTER KEY
                        if (e.keyCode == 13) {
                          this.handleSubmit(e);
                        }
                      }}
                    />
                  </Stack>
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="6">
                  <h2>Selling Price:</h2>
                </Form.Label>
                <Col sm="6">
                  <h2>
                    {Math.round(
                      this.state.price * this.state.qty -
                        (this.state.price *
                          this.state.qty *
                          this.state.discount) /
                          100,
                      0
                    )}
                  </h2>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  disabled={this.state.qty == 0}
                  // type="submit"
                  onClick={this.handleSubmit}
                >
                  Add
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.CloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
