import React, { Component, useContext, useState } from "react";
import { Stack, Row, Col, Button, Form } from "react-bootstrap";
import CartContext from "./CartContext";

//https://codingstatus.com/increment-decrement-counter-in-react-hooks/
export class IncDecCounter extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      num: this.props.intvalue,
    };
  }

  incNum = () => {
    if (this.state.num < this.context.storeSettings.productQtyLimitToCart) {
      var newnum = Number(this.state.num) + 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  decNum = () => {
    if (Number(this.state.num) > 1) {
      var newnum = this.state.num - 1;
      //setNum(newnum);
      this.setState({ num: newnum });

      this.props.valueChangeCallback(newnum);
    }
  };
  handleChange = (e) => {
    if (e.target.value === "") {
      this.setState({ num: 1 });
    } else if (Number(e.target.value) === 0) {
      this.setState({ num: Number(e.target.value) });
      this.props.valueChangeCallback(Number(e.target.value));
    } else {
      if (Number(e.target.value) > 0) {
        this.setState({ num: Number(e.target.value) });
        this.props.valueChangeCallback(Number(e.target.value));
      }
    }
  };

  render() {
    return (
      <>
        <div className="mx-0">
          <Stack direction="horizontal" className={"mx-0"}>
            {/* <Button
              className=""
              type="button"
              onClick={this.decNum}
              style={{
                backgroundColor: "#4ad168",
                color: "white",
                fontSize: "16px",
              }}
            >
              <b>&nbsp;-&nbsp;</b>
            </Button> */}
            <Button
              className="btn  "
              type="button"
              onClick={this.decNum}
              style={{
                backgroundColor: "#4ad168",
                color: "white",
                fontSize: "8px",
              }}
            >
              <b>&nbsp;&minus;&nbsp;</b>
            </Button>
            &nbsp;
            <Form.Control
              type="text"
              className="text-center fw-bold px-0 "
              value={this.state.num}
              disabled="true"
              onChange={this.handleChange}
              style={{
                // backgroundColor: "white",
                // color: "black",
                fontSize: "12px",
              }}
            ></Form.Control>
            &nbsp;
            <Button
              className="btn  plus"
              type="button"
              onClick={this.incNum}
              style={{
                backgroundColor: "#4ad168",
                color: "white",
                fontSize: "12px",
              }}
            >
              <b>&nbsp;+&nbsp;</b>
            </Button>
          </Stack>
        </div>
      </>
    );
  }
}

function IncDecCounterold(props) {
  const { store, storeSettings, theme } = useContext(CartContext);
  let [num, setNum] = useState(props.intvalue);
  let incNum = () => {
    if (num < storeSettings.productQtyLimitToCart) {
      var newnum = Number(num) + 1;
      setNum(newnum);

      props.valueChangeCallback(newnum);
    }
  };
  let decNum = () => {
    if (num > 0) {
      var newnum = num - 1;
      setNum(newnum);

      props.valueChangeCallback(newnum);
    }
  };
  let handleChange = (e) => {
    setNum(e.target.value);
  };

  return (
    <table className="incdectable ">
      <tr className="text-center align-middle w-100">
        <td className="mx-0 " width="30%">
          <div
          // style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              // className="btn  mx-0 "
              // type="button"
              size="sm"
              disabled={props.disabled}
              // variant="danger"
              onClick={decNum}
            >
              <b>&nbsp;-&nbsp;</b>
            </Button>
          </div>
        </td>
        <td className="" width="40%">
          <Form.Control
            type="text"
            className="text-center  px-0 "
            value={num}
            size="sm"
            disabled="true"
            onChange={handleChange}
          ></Form.Control>
        </td>
        <td width="30%">
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              // className="btn "
              // type="button"
              size="sm"
              onClick={incNum}
              // variant="danger"
              disabled={props.disabled}
            >
              <b>&nbsp;+&nbsp;</b>
            </Button>
          </div>
        </td>
      </tr>
    </table>
  );
}

export default IncDecCounter;
