import React, { useState, useContext, Component } from "react";
import CartContext from "./CartContext";

import { Row, Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

export default class LicensePage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { firstLoad: false, lkey: "", pcCode: "1" };
  }

  componentDidMount() {
    if (this.context.licenseKey != "null") {
      this.setState({ lkey: this.context.licenseKey });
    }
    this.setState({ pcCode: this.context.pcCode });
  }

  handleInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      //Store
      case "lkey":
        this.setState({ lkey: value });
        break;
      case "pcCode":
        this.setState({ pcCode: value });
        break;
    }
  };

  ApplyLicense = () => {
    this.context.SetLicenseKey(this.state.lkey);
    this.context.SetPcCode(this.state.pcCode);
  };

  render() {
    return (
      <>
        <div className="p-5 mx-5" style={{ padding: "200px" }}>
          <Row className="p-5 mx-5">
            <Col xs={2}></Col>
            <Col xs={2} className="text-center">
              Enter License Key:
            </Col>
            <Col xs={6} className="text-start ">
              <Form.Control
                type="password"
                name="lkey"
                value={this.state.lkey}
                placeholder=""
                onChange={(e) => this.handleInputChange(e)}
                autoComplete="new-password"
              ></Form.Control>
            </Col>

            <Col xs={2}></Col>
          </Row>
          <Row className="p-5 mx-5">
            <Col xs={2}></Col>
            <Col xs={2} className="text-center">
              Enter PC Code:
            </Col>
            <Col xs={6} className="text-start ">
              <Form.Control
                type="text"
                name="pcCode"
                value={this.state.pcCode}
                placeholder=""
                onChange={(e) => this.handleInputChange(e)}
              ></Form.Control>
            </Col>
            <Col xs={2}></Col>
          </Row>
          <Row>
            <Col xs={2}></Col>
            <Col xs={2} className="text-center"></Col>
            <Col xs={6} className="text-center">
              <Button onClick={(e) => this.ApplyLicense()}>Apply</Button>
            </Col>

            <Col xs={2}></Col>
          </Row>
        </div>
      </>
    );
  }
}
